.o-cta.o-cta-bottom{
  margin-bottom: $space_desktop;
  @media (max-width: 991px) {
    margin-bottom: $space_tablet;
  }
  @media (max-width: 767px) {
    margin-bottom: $space_mobile;
  }
}
.o-cta {
  margin-bottom: $space_desktop;
  @media (max-width: 991px) {
    margin-bottom: $space_tablet;
  }
  @media (max-width: 767px) {
    margin-bottom: $space_mobile;
  }

  &__inner {
    padding: 46px 20px;
    border-radius: 24px;
    text-align: center;
    overflow: hidden;

    //@media (max-width: 991px) {
    //  padding: 50px 20px;
    //}


    @media (max-width: 767px) {
      padding: 30px 20px;
    }
  }
  &__title {
    color: $c_white;
    margin-bottom: 24px;

    @media (max-width: 767px) {
      margin-bottom: 16px;
    }
  }
  &__button {
    width: auto !important;
  }
}
.cta-banner{
  background: #F8F8F8;
  border-radius: 24px;
  overflow: hidden;
  min-height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  text-align: center;
  padding: 10px 15px;

  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: $c_text_100;
  @media (max-width: 767px) {
    font-size: 16px;
  }
  &:not(.banner-leaves){
    a{
      text-decoration: none!important;
    }
  }
  .search{
    width: 130px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 35px 35px 0 / 0 100px 100px 0;
    @media (max-width: 767px) {
      width: 120px;
    }
    @media (max-width: 479px) {
      width: 80px;
    }
    &:after{
      content: '';
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-image: url("../img/banner-search.svg");
      background-size: 50%;
      background-position: center center;
      background-repeat: no-repeat;
      position: absolute;
    }
  }
  &.cta-leaves{
    background-image: url('../img/leaves.png');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  &.cta-colored{
    .search{
      background: $c_orange;
    }
  }
  &.cta-gray{
    .search{
      background: #EFEFEF;
    }
  }
  &.cta-colored,
  &.cta-gray{
    padding-left: 175px;
    @media (max-width: 767px) {
      padding-left: 125px;
    }
    @media (max-width: 479px) {
      padding-left: 85px;
    }
  }
}

.cta-dark{
  margin-bottom: $space_desktop;
  @media screen and (max-width: 991px) {
    margin-bottom: $space_tablet;
  }
  &.bg{
    background: #FBFBFB;
    padding: $space_desktop 0;
    @media screen and (max-width: 991px) {
      padding: $space_tablet 0;
    }
  }
  &.new{
    .cta-box{
      //padding: 60px 100px;
      //@media screen and (max-width: 991px) {
      //  padding: 30px;
      //}
      &:before{
        background-image: url("../img/cta-dark-background-new.svg");
        width: 100%;
        background-position: center right;
      }
      .o-btn-dark{
        color: #fff;
        background: $c_yellow_medium;
      }
    }
  }
  .cta-box{
    background: $c_text_100;
    border-radius: 18px;
    padding: 30px 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 0;
    overflow: hidden;
    gap: 30px;
    @media screen and (max-width: 991px) {
      padding: 30px;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    &:before{
      content:'';
      position:absolute;
      top: -3px;
      right: -3px;
      bottom: -3px;
      width: 39%;
      background-image: url("../img/cta-yellow-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      z-index: -1;
      @media screen and (max-width: 991px) {
        width: 42%;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        background-image: url('../img/cta-yellow-bg-mobile.png');
      }
    }
    .cta-title{
      margin-right: 15px;
      color: #fff;
      font-size: 20px;
      font-weight: 700;
      line-height: 150%;
      flex: 1;
      @media screen and (max-width: 767px) {
        font-size: 18px;
        text-align: center;
      }
    }
    p{
      margin: 0;
      font-size: 14px;
      color: #fff;
    }
    .o-btn-dark{
      border-radius: 8px;
      background: #363646;
      padding: 12px 16px;

      color: #FFD36A;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      transition: all 0.4s ease;
      white-space: nowrap;
      &:hover{
        background: $c_orange;
        color: #fff;
      }
    }
    //For subscribe form
    .tnp{
      position: relative;
      margin: 0!important;
    }
    .tnp label{
      color: #fff;
      display: none;
    }
    .tnp-email,
    input:not([type="submit"]) {
      width: 100%;
      height: 52px;
      color: $c_text_100;
      background-color: $c_white!important;
      border-radius: 12px;
      border: none!important;
      outline: none;
      padding: 18px 172px 18px 32px !important;

      @media (max-width: 767px) {
        padding-right: 60px;
      }

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $c_text_80;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $c_text_80;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        color: $c_text_80;
      }
    }
    .tnp-subscription .tnp-field .tnp-submit,
    button {
      @extend .o-btn-dark;
      position: absolute;
      top: 0;
      right: 0;
      height: 52px;
      @media screen and (max-width: 479px) {
        position: static;
        margin-top: 15px;
      }
    }
    .tnp-subscription .tnp-field .tnp-submit{
      bottom: 0;
      top: auto;
    }
    .tnp-field{
      margin-bottom: 0!important;
    }
  }
  &.subscribe{
    .cta-box{
      flex-wrap: wrap;
      gap: 20px;
      justify-content: center;
      &:before{
        background-position: inherit;
      }
    }
    .cta-title{
      flex-basis: 100%;
      text-align: center;
    }
    .subscribe-info{
      flex-basis: 100%;
      gap: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
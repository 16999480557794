.o-services-s {
  margin-bottom: $space_desktop;
  @media (max-width: 991px) {
    margin-bottom: $space_tablet;
  }
  @media (max-width: 767px) {
    margin-bottom: $space_mobile;
  }
  &__title {
    margin-bottom: $title_s_desktop;
    text-align: center;
  }
  &__description {
    margin-bottom: $title_s_desktop;
  }
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: $space_tablet;
    @media screen and (max-width: 767px) {
      margin-bottom: $space_mobile;
    }
    @media (max-width: 575px) {
      flex-direction: column;
    }
  }
  &__item-info {}
  &__item-icon {
    max-width: 100px;
    margin-right: 24px;
    @media (max-width: 575px) {
      margin-right: 0;
      margin-bottom: 24px;
    }
  }
  &__item-title {
    margin-bottom: 24px;
    font-size: 20px;
  }
  &__item-description {
    font-size: 14px;
  }
}
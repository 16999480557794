.o-ww {
  margin-bottom: $space_desktop;
  @media (max-width: 991px) {
    margin-bottom: $space_tablet;
  }
  @media (max-width: 767px) {
    margin-bottom: $space_mobile;
  }

  &__title {
    font-size: 32px;
    margin-bottom: $title_s_desktop;
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: $space_tablet;
    @media screen and (max-width: 767px) {
      margin-bottom: $space_mobile;
    }
    @media (max-width: 575px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__item-number {
    font-size: 60px;
    font-weight: 700;
    color: #FFF2CA;
    margin-right: 24px;
  }

  &__item-info {}

  &__item-title {
    font-size: 20px;
    margin-bottom: 12px;
  }

  &__item-description {
    font-size: 14px;
  }
}
.o-btn {
  position: relative;
  &--outline {
    font-weight: 700;
    color: $c_text_100;
    background-color: transparent;
    border-radius: 8px;
    border: 2px solid $c_orange;
    padding: 14px 32px;
    transition: 0.3s all;
    font-size: 18px;
    line-height: 24px;
    @media screen and (max-width: 1024px) {
      font-size: 16px;
      line-height: 20px;
      padding: 12px;
    }
    &:hover{
      background-color: $c_orange;
      color: #fff;
    }
  }
  &--cta {
    font-weight: 700;
    color: $c_white;
    background: linear-gradient(90deg, #FFB248 0%, #FFE22A 100%);
    border-radius: 30px;
    padding: 10px 24px;
    overflow: hidden;
    z-index: 1;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, #FFE22A 0%, #FFB248 100%);
      z-index: -1;
      opacity: 0;
      transition: 0.3s all;
    }

    &:hover {
      color: $c_white;

      &::after {
        opacity: 1;
      }
    }
  }

  &--action {
    display: inline-flex;
    align-items: center;
    font-size: 24px;
    font-weight: 700;
    color: $c_yellow_medium;
    column-gap: 16px;
    @media screen and (max-width: 767px) {
      font-size: 20px;
    }
    &::after {
      content: url('../img/fe-right-white.svg');
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 24px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: $c_yellow_medium;
      padding-left: 2px;
      padding-top: 4px;
      transition: 0.3s all;
    }

    &:hover {
      color: $c_yellow_medium;

      &::after {
        transform: translateX(32px);
      }
    }
  }

  &--primary {
    font-weight: 700;
    color: $c_white;
    background-color: $c_yellow_medium;
    border-radius: 8px;
    padding: 10px 16px;
    transition: 0.3s all;
    line-height: 24px;
    font-size: 16px;
    border: none;
    &:hover {
      color: $c_white;
      background-color: $c_orange;
    }
    &.download{
      position: relative;
      padding-right: 48px;
      &:after{
        content: url("../img/download-icon.png");
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        top: calc(50% - 12px);
        right: 14px;
      }
    }
  }

  &--white {
    font-weight: 700;
    color: $c_text_100;
    background-color: $c_white;
    border-radius: 24px;
    border: 2px solid $c_white;
    padding: 8px 22px;
    transition: 0.3s all;

    &:hover {
      color: $c_white;
      background-color: transparent;
      border-color: $c_white;
    }

    @media (max-width: 575px) {
      width: 100%;
      text-align: center;
    }
  }

  &--archive {
    font-weight: 500;
    color: $c_text_100;
    background-color: transparent;
    border-radius: 28px;
    border: 1px solid $c_yellow_medium;
    padding: 9px 50px 9px 24px;

    &:hover {
      color: $c_text_100;

      &::after {
        right: 12px;
      }
    }

    &::after {
      content: url('../img/fe-right.svg');
      position: absolute;
      top: 12px;
      right: 24px;
      transition: 0.3s all;
    }

    @media (max-width: 575px) {
      width: 100%;
      text-align: center;
    }
  }
}
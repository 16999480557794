.p-404 {
  min-height: 85vh;
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 40px;

  &__title {
    font-size: 96px;
    margin-bottom: 8px;
  }
  &__description {
    margin-bottom: 24px;
  }
}
.alc{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.flex{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.tac{
  text-align: center;
}
.expertise-service-p{
  h1,h2,h3,h4,h5,h6{
    mark{
      background-color: transparent;
      color: #ffb248;
    }
  }
  img{
    height: auto;
  }
  .breadcrumbs{
    margin-top: 90px;
  }
  .expertise-service-top-block{
    padding: $space_desktop 0 $space_desktop;
    @media (max-width: 767px) {
      padding: $space_tablet 0 $space_tablet;
    }
    .left{
      flex-basis: 50%;
      @media (max-width: 767px) {
        flex-basis: 100%;
        margin-bottom: 30px;
      }
      h1{
        text-transform: uppercase;
        margin-bottom: 30px;
      }
      .expertise-top-text{
        margin-bottom: 30px;
      }
    }
  }
  .expertise-service-top-block{
    .right{
      flex-basis: 43%;
      @media (max-width: 767px) {
        flex-basis: 100%;
      }
      .o-banner__awards{
        margin: 0;
        flex-wrap: wrap;
        justify-content: flex-start;
        @media (max-width: 767px) {
          justify-content: center;
        }
        .o-banner__awards-item{
          margin-bottom: $space_tablet;
          text-align: center;
          @media screen and (max-width: 991px) {
            margin-bottom: $space_mobile;
          }
        }
        img{
          margin: 0 auto;
        }
      }
    }
  }
  .industries-block.odd-start{
    .industry-block:nth-child(odd){
      flex-direction: row-reverse;
      @media (max-width: 767px) {
        flex-direction: row;
      }
    }
  }
  .industries-block.even-start{
    .industry-block:nth-child(odd){
      flex-direction: row-reverse;
      @media (max-width: 767px) {
        flex-direction: row;
      }
    }

  }
  .industries-block{
    margin-bottom: $space_desktop;
    @media (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    >h2{
      margin-bottom: $title_s_desktop;
    }
    .industry-block{
      margin-bottom: $space_tablet;
      &:last-child{
        margin-bottom: 0;
      }
      @media (max-width: 767px) {
        margin-bottom: 0;
      }
    }
    .industrie-content{
      flex-basis: 38%;
      @media (max-width: 767px) {
        flex-basis: 100%;
      }
      h2{
        margin-bottom: 24px;
        a{
          font-weight: 700;
          line-height: 1.4;
          color: #363645;
          transition: all 0.4s ease;
          &:hover{
            color: $c_orange;
          }
        }
      }
      .industrie-text-wrap{
        margin-bottom: 40px;
      }
    }
    .industrie-img{
      flex-basis: 50%;
      @media (max-width: 767px) {
        flex-basis: 100%;
      }
    }
  }
  .technologies-block{
    margin-bottom: $space_desktop;
    @media screen and (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: $space_mobile;
    }
  }
}
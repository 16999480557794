.o-social-sharing {
  &__list {
    display: flex;

    @media (min-width: 1366px) {
      flex-direction: column;
    }

    @media (max-width: 1365px) {
      margin: 0 -6px;
    }
  }
  &__item {
    &:hover {
      rect {
        fill: $c_orange;
        stroke: $c_orange;
      }
      path {
        fill: $c_white;
        opacity: 1;
      }
    }
    @media (max-width: 1365px) {
      padding: 0 6px;
    }

    &:not(:last-of-type) {
      @media (min-width: 1366px) {
        margin-bottom: 12px;
      }
    }
  }
}
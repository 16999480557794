.o-about-us {
  .breadcrumbs{
    margin-top: 90px;
  }
  mark{
    background-color: transparent;
    color: $c_orange;
  }
  .banner-awards-list{
    padding-top: 0;
  }
  .about-top-section{
    padding: $space_desktop 0;
    @media (max-width: 991px) {
      padding: $space_tablet 0;
    }
    @media (max-width: 767px) {
      padding: $space_mobile 0;
    }
    .container.flex{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 991px) {
        flex-direction: column-reverse;
      }
    }
    .about-top-text{
      flex-basis: 47.5%;
      @media (max-width: 991px) {
        flex-basis: 100%;
      }
      h1,h2{
        margin-bottom: $title_s_desktop;
      }
      p{
        margin-bottom: $title_s_desktop;
      }
    }
    .about-top-image{
      flex-basis: 47.5%;
      @media (max-width: 991px) {
        flex-basis: 100%;
      }
    }
  }
  .about-description{
    h1,h2{
      text-align: center;
      margin-bottom: $title_s_desktop;
    }
    .about-description-columns{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    p{
      margin-bottom: $space_desktop;
      flex-basis: 27%;
      @media (max-width: 991px) {
        margin-bottom: $space_tablet;
        flex-basis: 48%;
      }
      @media (max-width: 767px) {
        margin-bottom: $space_mobile;
        flex-basis: 100%;
      }
    }
  }
  .about-us-services{
    h2{
      text-align: center;
      margin-bottom: $title_s_desktop;
    }
    .about-us-services-items{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 40px;
      .about-us-services-item{
        flex-basis: 40.1%;
        margin-bottom: $space_desktop;
        @media (max-width: 991px) {
          margin-bottom: $space_tablet;
          flex-basis: 48%;
        }
        @media (max-width: 767px) {
          margin-bottom: $space_mobile;
          flex-basis: 100%;
        }
        h3{
          font-size: 32px;
          margin-bottom: 16px;
          @media (max-width: 991px) {
            min-height: 90px;
          }
          @media (max-width: 767px) {
            min-height: auto;
            font-size: 22px;
          }
        }
        p{
          margin-bottom: 32px;
        }
        a{
          color: $c_orange;
          border-bottom: 1px solid #ffb248;
          font-weight: 600;
        }
      }
    }
  }
  .about-quick-numbers{
    h2{
      text-align: center;
      margin-bottom: $title_s_desktop;
    }
    .numbers-items{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .numbers-item{
        text-align: center;
        margin-bottom: $space_desktop;
        @media (max-width: 991px) {
          margin-bottom: 40px;
          flex-basis: 48%;
        }
        @media (max-width: 767px) {
          margin-bottom: 30px;
        }
        @media (max-width: 480px) {
          flex-basis: 100%;
        }
        .number{
          font-style: normal;
          font-weight: 900;
          font-size: 80px;
          line-height: 1;
          color: #363645;
          display: block;
          margin-bottom: 6px;
        }
      }
    }
  }
  .about-work-section{
    margin-bottom: $space_tablet;
    text-align: center;
    h2{
      margin-bottom: $title_s_desktop;
    }
    .work-environment{
      display: block;
      max-width: 730px;
      margin: 0 auto;
    }
  }
  .about-gallery{
    margin-bottom: $space_desktop;
    @media (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    @media (max-width: 767px) {
      margin-bottom: $space_mobile;
    }
    h3{
      margin-bottom: $title_s_desktop;
      text-align: center;
    }
    .about-us-gallery-row{
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      margin-bottom: 15px;
      &:last-child{
        margin-bottom: 0;
      }
      &.first-row{
        .about-us-gallery-item{
          height: 316px;
          flex-basis: calc(33.33% - 10px);
          @media (max-width: 767px) {
            flex-basis: 100%;
          }
        }
      }
      &.second-row{
        .about-us-gallery-item{
          height: 402px;
          @media (max-width: 767px) {
            height: auto;
          }
          &:nth-child(1){
            flex-basis: calc(70% - 10px);
            @media (max-width: 767px) {
              flex-basis: 100%;
            }
          }
          &:nth-child(2){
            flex-basis: calc(30% - 10px);
            @media (max-width: 767px) {
              flex-basis: 100%;
            }
          }
        }
      }
      &.third-row{
        .about-us-gallery-item{
          height: 316px;
          @media (max-width: 767px) {
            height: auto;
          }
          &:nth-child(1){
            flex-basis: calc(42% - 10px);
            @media (max-width: 767px) {
              flex-basis: 100%;
            }
          }
          &:nth-child(2){
            flex-basis: calc(25% - 10px);
            @media (max-width: 767px) {
              flex-basis: 100%;
            }
          }
          &:nth-child(3){
            flex-basis: calc(33% - 10px);
            @media (max-width: 767px) {
              flex-basis: 100%;
            }
          }
        }
      }
    }
    .about-us-gallery-item{
      border-radius: 12px;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .about-us-feedback{
    margin-bottom: $space_desktop;
    @media (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    @media (max-width: 767px) {
      margin-bottom: $space_mobile;
    }
    h2{
      text-align: center;
    }
    .swiper-container{
      margin: 0 -10px;
      padding: 0 10px;
    }
    .swiper-slide{
      background: #FFFFFF;
      box-shadow: 0px 4px 40px rgba(202, 205, 210, 0.4), 4px 4px 12px rgba(208, 218, 226, 0.2), 0px 4px 8px rgba(193, 196, 213, 0.4);
      border-radius: 12px;
      margin: 44px 0 100px;
      padding: 34px 28px;
      position: relative;
      z-index: 0;
      &:nth-child(odd){
        &:after{
          content:url("../img/blockquote.png");
          position:absolute;
          bottom: 21px;
          left:28px;
          z-index: -1;
        }
      }
      &:nth-child(even){
        &:after{
          content:url("../img/blockquote.png");
          position:absolute;
          top: 34px;
          right:28px;
          z-index: -1;
          transform: rotate(180deg);
        }
      }
      .slide-top{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        .person-image{
          width: 66px;
          height: 66px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 15px;
          @media (max-width: 991px) {
            margin-bottom: 15px;
            margin-right: 0;
          }
        }
        .text-wrap{
          @media (max-width: 991px) {
            flex-basis: 100%;
          }
        }
        .person-name{
          font-weight: 700;
          font-size: 22px;
          line-height: 1.4;
          color: #363645;
          margin-bottom: 6px;
          display: block;
        }
        .person-position{
          font-weight: 700;
          font-size: 16px;
          line-height: 1.5;
          color: #363645;
        }
      }
    }
    .swiper-pagination{
      text-align: left;
      bottom: 32px;
      padding: 0 10px;
      .swiper-pagination-bullet{
        width: 15px;
        height: 15px;
        &.swiper-pagination-bullet-active{
          background: $c_orange;
        }
      }
    }
    .swiper-nav{
      position: absolute;
      bottom: 8px;
      right: 0;
      display: flex;
      flex-direction: row-reverse;
    }
    .swiper-button-next,
    .swiper-button-prev {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      width: unset;
      height: unset;
      margin-top: unset;
      padding: 0 12px;
      background-image: none;

      &::after {
        display: none;
      }

      svg {
        @media (max-width: 991px) {
          width: 40px;
        }
      }

      svg,
      path {
        transition: 0.3s all;
      }

      &:hover {
        svg {
          fill: $c_orange;
        }

        path {
          fill: $c_white;
        }
      }

      &.swiper-button-disabled {
        opacity: 1;
      }
    }

    .swiper-button-next {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  .about-recognition{
    h2{
      text-align: center;
      margin-bottom: $title_s_desktop;
    }
    .companies{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .company-link{
        background: #FFFFFF;
        box-shadow: 0px 4px 40px rgba(202, 205, 210, 0.4), 4px 4px 12px rgba(208, 218, 226, 0.2), 0px 4px 8px rgba(193, 196, 213, 0.4);
        border-radius: 12px;
        filter: grayscale(1);
        transition: all 0.4s ease;
        min-height: 320px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-basis: 30.5%;
        box-sizing: border-box;
        padding: 0 20px;
        margin-bottom: $space_desktop;

        font-size: 16px;
        line-height: 150%;
        color: #363645;
        &:hover{
          filter: grayscale(0);
        }
        img{
          margin-bottom: 15px;
        }
        @media (max-width: 991px) {
          flex-basis: 48%;
          margin-bottom: $space_tablet;
        }
        @media (max-width: 767px) {
          margin-bottom: $space_mobile;
          flex-basis: 100%;
        }
      }
    }
  }
  .about-values{
    margin-bottom: $space_desktop;
    @media (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    @media (max-width: 767px) {
      margin-bottom: $space_mobile;
    }
    h2{
      text-align: center;
      margin-bottom: $title_s_desktop;
    }
    .values-wrap{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .value-item{
        flex-basis: 47.5%;
        margin-bottom: 24px;
        @media (max-width: 767px) {
          flex-basis: 100%;
        }
      }
    }
  }
  .about-cta{
    margin-bottom: $space_desktop;
    @media (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    @media (max-width: 767px) {
      margin-bottom: $space_mobile;
    }
    .o-cta__inner{
      padding: 46px 20px;
    }
  }
  .about-testimonials{
    margin-bottom: $space_desktop;
    @media (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    @media (max-width: 767px) {
      margin-bottom: $space_mobile;
    }
    .o-testimonials__item{
      padding: 0;
    }
    .o-testimonials__item-feedback{
      font-size: 16px;
    }
    .o-testimonials__control{
      margin-top: 44px;
    }
  }




  &__banner {
    position: relative;
    padding: 270px 0 290px;

    @media (min-width: 1200px) {
      padding: 200px 0 220px;
      min-height: 90vh;
      display: flex;
      align-items: center;
    }

    @media (max-width: 1199px) {
      padding-bottom: 160px;
    }

    .el1,
    .el2,
    .ill {
      position: absolute;
    }

    .el1 {
      max-width: 133px;
      top: 120px;
      left: 50%;
      transform: translateX(-50%);
    }
    .el2 {
      top: 210px;
      left: 33%;
      max-width: 75px;
    }
    .ill {
      top: 0;
      right: 0;
      width: 32%;
    }
  }
  &__banner-illustration {}
  &__sliders {
    .swiper-pagination {
      position: absolute;
      top: 70%;
      left: 0;
      text-align: left;
      margin: 0 -3px;
      transform: none;

      @media (max-width: 1199px) {
        top: 78%;
      }

      @media (max-width: 991px) {
        top: 76%;
      }

      @media (max-width: 767px) {
        display: none;
      }
    }
    .swiper-pagination-bullet {
      width: 15px;
      height: 15px;
      margin: 0 7px;
      opacity: 1;
      background: #DDE0E4;
    }
    .swiper-pagination-bullet-active {
      background: $c_orange;
    }

    .swiper-nav {
      display: flex;
      flex-direction: row-reverse;
      position: absolute;
      top: 66%;
      right: 0;
      margin: 0 -12px;

      @media (max-width: 1199px) {
        top: 72%;
      }

      @media (max-width: 991px) {
        top: 70%;
      }

      @media (max-width: 767px) {
        position: static;
        margin-bottom: 24px;
        justify-content: flex-end;
      }

      .swiper-button-next,
      .swiper-button-prev {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        width: unset;
        height: unset;
        margin-top: unset;
        padding: 0 12px;
        background-image: none;

        &::after {
          display: none;
        }

        svg {
          @media (max-width: 991px) {
            width: 40px;
          }
        }

        svg,
        path {
          transition: 0.3s all;
        }

        &:hover {
          svg {
            fill: $c_orange;
          }

          path {
            fill: $c_white;
          }
        }

        &.swiper-button-disabled {
          opacity: 1;
        }
      }

      .swiper-button-next {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
  &__sliders-left {
    @media (max-width: 767px) {
      margin-bottom: 72px;
    }
  }
  &__sliders-item {}
  &__sliders-image {
    padding-bottom: 56.2%;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 180px;

    @media (max-width: 1199px) {
      margin-bottom: 120px;
    }

    @media (max-width: 991px) {
      margin-bottom: 80px;
    }

    @media (max-width: 767px) {
      margin-bottom: 24px;
    }
  }
  &__sliders-title {
    margin-bottom: 16px;
  }
  &__sliders-description {}
  &__sliders-divider {
    margin: 100px 0;
  }
  &__services {
    position: relative;

    .el {
      position: absolute;
      top: -8%;
      left: 0;
      max-width: 12%;
    }
  }
  &__services-divider {
    margin-bottom: 130px;

    @media (max-width: 991px) {
      margin-bottom: 100px;
    }

    @media (max-width: 767px) {
      margin-bottom: 72px;
    }
  }

  &__values {}
  &__values-title {
    text-align: center;
    margin-bottom: 24px;
  }
  &__values-description {
    text-align: center;
    margin-bottom: 170px;

    @media (max-width: 991px) {
      margin-bottom: 100px;
    }
  }
  &__values-item {
    align-items: center;
    margin-bottom: 120px;

    &.reverse-on-mobile {
      @media (max-width: 767px) {
        flex-direction: column-reverse;
      }
    }

    @media (max-width: 991px) {
      margin-bottom: 80px;
    }
  }
  &__values-item-title {
    margin-bottom: 24px;
  }
  &__values-item-description {}
  &__values-item-image {
    display: flex;

    img {
      max-width: 400px;
      width: 100%;

      @media (max-width: 767px) {
        max-width: 240px;
        margin-bottom: 24px;
      }
    }

    .img-left {}
    .img-right {
      @media (min-width: 768px) {
        margin-left: auto;
      }
    }
  }

  &__values-divider {
    margin-bottom: 120px;
  }

  &__team {
    margin-bottom: 100px;
  }
  &__team-title {
    text-align: center;
    margin-bottom: 24px;

    @media (max-width: 991px) {
      margin-bottom: 12px;
    }
  }
  &__team-description {
    text-align: center;
    margin-bottom: 100px;

    @media (max-width: 991px) {
      margin-bottom: 48px;
    }
  }
  &__team-credo-title {
    margin-bottom: 24px;

    @media (max-width: 991px) {
      margin-bottom: 12px;
    }
  }
  &__team-credo-item {
    position: relative;
    padding-left: 18px;
    margin-bottom: 60px;

    &::before {
      content: '';
      position: absolute;
      top: 6px;
      left: 0;
      width: 10px;
      height: 10px;
      background-color: $c_orange;
      border-radius: 50%;
    }
  }
  &__team-department-title {
    margin-bottom: 32px;

    @media (max-width: 991px) {
      margin-bottom: 12px;
    }
  }
  &__team-department-list {
    padding-left: 24px;
    border-left: 1px solid #F8F8F8;

    @media (max-width: 991px) {
      padding: 0;
      border: 0;
      display: flex;
      flex-wrap: wrap;
      margin: 0 -12px;
      margin-bottom: 24px;
    }

    li {
      position: relative;

      @media (max-width: 991px) {
        padding: 0 12px;
        margin-bottom: 12px;
      }

      &.active {
        &::before {
          position: absolute;
          top: 0;
          left: -25px;
          content: '';
          width: 1px;
          height: 100%;
          background-color: $c_orange;

          @media (max-width: 991px) {
            display: none;
          }
        }
        a {
          color: $c_text_80;
        }
      }

      &:not(:last-of-type) {
        @media (min-width: 992px) {
          margin-bottom: 24px;
        }
      }
    }
    a {
      font-weight: 700;
      color: rgba($c_text_80, 0.4);
      transition: 0.3s all;

      &:hover {
        color: $c_text_80;
      }
    }
  }
  &__team-list {
    position: relative;
    @media (min-width: 992px) {
      min-height: 640px;
    }

    .slick-slide {
      & > div {
        display: flex;
      }
    }

    .slick-dots {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 14px;

      .slick-active {
        button {
          background-color: $c_orange;
        }
      }

      button {
        outline: none;
        border: 0;
        font-size: 0;
        width: 10px;
        height: 10px;
        margin: 0 4px;
        padding: 0;
        border-radius: 50%;
        background-color: $c_yellow_light;
      }
    }
  }
  &__team-overlay {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $c_white;
    pointer-events: none;
    z-index: 1;
  }
  &__team-item {
    display: flex;
    justify-content: center;
    // align-items: flex-end;
    position: relative;
    width: 170px;
    height: 170px;
    margin: 14px;
    box-shadow: 0px 1.61887px 5.66606px rgba(0, 0, 0, 0.118471);
    border-radius: 100%;
    overflow: hidden;

    @media (max-width: 767px) {
      width: 160px;
      height: 160px;
      margin: 4px;

      .o-about-us__team-item-overlay {
        opacity: 0;
      }
      .o-about-us__team-item-info {
        p,
        small {
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        }
        p {
          font-size: 12px;
        }
        small {
          font-size: 10px;
        }
      }
    }

    &:hover {
      .o-about-us__team-item-overlay {
        opacity: 0;
      }
      .o-about-us__team-item-info {
        p,
        small {
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        }
      }
    }
  }
  &__team-item-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background-color: $c_white;
    border-radius: 100%;
    overflow: hidden;
    transform: translate(-50%, -50%);
    transition: 0.3s ease-in-out;

    img {
      max-height: 100%;
    }
  }
  &__team-item-info {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;
    font-weight: 700;
    color: $c_white;
    padding: 14px 30px 20px;
    // background: linear-gradient(180deg, rgba(54, 54, 70, 0.0001) 0%, rgba(54, 54, 70, 0.693948) 100%);

    &::before {
      content: '';
      position: absolute;
      top: 30%;
      bottom: 0;
      left: 0;
      width: 100%;
      background: linear-gradient(180deg, rgba(54, 54, 70, 0.0001) 0%, rgba(54, 54, 70, 0.693948) 100%);
    }

    p,
    small {
      display: block;
      opacity: 0;
      visibility: hidden;
      transform: translateY(100%);
      transition: 0.4s ease-in-out;
    }
  }

  .o-cta {
    margin-bottom: 140px;
  }

  .o-banner__awards {
    margin-top: 0;
    margin-bottom: 140px;

    @media (min-width: 768px) {
      justify-content: center;
    }

    @media (max-width: 991px) {
      margin-bottom: 100px;
    }
  }

  .mod-desktop {
    @media (max-width: 767px) {
      display: none;
    }
  }

  .mod-mobile {
    @media (min-width: 768px) {
      display: none;
    }
  }



  .video-banner{
    padding: $space_desktop 0;
    @media screen and (max-width: 991px) {
      padding: $space_tablet 0;
    }
    @media screen and (max-width: 767px) {
      padding: $space_mobile 0;
    }
  }
  .paragraph-section.first{
    margin-bottom: 0;
  }
  .paragraph-section:not(.first){
    background: #fff;
  }
  .our-clients{
    background: #fbfbfb;
    text-align: center;
    padding: $space_desktop 0;
    @media screen and (max-width: 991px) {
      padding: $space_tablet 0;
    }
    @media screen and (max-width: 767px) {
      padding: $space_mobile 0;
    }
    h2{
      margin-bottom: $title_s_desktop;
      @media screen and (max-width: 991px) {
        margin-bottom: $title_s_tablet;
      }
    }
    .our-clients-logos{
      display: flex;
      flex-wrap: wrap;
      gap: 40px;
      .client-logo{
        flex-basis: calc(25% - 30px);
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 991px) {
          flex-basis: calc(33.33% - 40px);
        }
        @media screen and (max-width: 767px) {
          flex-basis: calc(50% - 20px);
        }
        img{
          object-fit: contain;
          max-width: 50%;
          height: 74px;
          @media screen and (max-width: 991px) {
            max-width: 65%;
          }
        }
      }
    }
  }
}
.management-team{
  text-align: center;
  h2{
    margin-bottom: $title_s_desktop;
    @media screen and (max-width: 991px) {
      margin-bottom: $title_s_tablet;
    }
  }
  .team-list{
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    @media screen and (max-width: 991px) {
      gap: 30px;
    }
    .team-item{
      flex-basis: calc(33.33% - 26.66px);
      @media screen and (max-width: 991px) {
        flex-basis: calc(50% - 15px);
      }
      @media screen and (max-width: 767px) {
        flex-basis: 100%;
      }
      .person-image{
        width: 200px;
        height: 200px;
        border-radius: 15px;
        overflow: hidden;
        margin: 0 auto 10px;
        img{
          width: 100%;
          height: 100%;
          overflow: hidden;
        }
      }
      h5{
        margin-bottom: 10px;
      }
    }
  }
}
.logos-swiper-section{
  padding-top: $space_desktop;
  @media screen and (max-width: 991px) {
    padding-top: $space_tablet;
  }
  h2{
    text-align: center;
    margin-bottom: $title_s_desktop!important;
    @media screen and (max-width: 991px) {
      margin-bottom: $title_s_tablet!important;
    }
  }
  .swiper-slide{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
  }
}
.two-columns-gallery{
  padding: $space_desktop 0;
  margin-bottom: $space_desktop;
  background: #fbfbfb;
  @media screen and (max-width: 991px) {
    padding: $space_tablet 0;
    margin-bottom: $space_tablet;
  }
  h2{
    text-align: center;
    margin-bottom: $title_s_desktop!important;
    @media screen and (max-width: 991px) {
      margin-bottom: $title_s_tablet!important;
    }
  }
  .swiper-wrap{
    padding: 0 80px;
    @media screen and (max-width: 767px) {
      padding: 0 0 80px;
    }
    .swiper-button-prev,
    .swiper-button-next{
      top: 128px;
      @media screen and (max-width: 767px) {
        top: auto;
      }
    }
  }
  .swiper-container{
    a{
      margin-bottom: 40px;
      @media screen and (max-width: 991px) {
        margin-bottom: 30px;
      }
    }
    img{
      min-height: 256px;
      object-fit: cover;
    }
    h3{
      margin-bottom: 5px;
    }
    .testimonial-subtitle{
      color: #363645;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 162.5%;
      display: block;
      margin-bottom: 16px;
    }
  }
}
.slider-projects-list{
  padding-bottom: $space_desktop;
  @media screen and (max-width: 991px) {
    padding-bottom: $space_tablet;
  }
  @media screen and (max-width: 767px) {
    padding-bottom: $space_mobile;
  }
  h2{
    text-align: center;
    margin-bottom: 30px;
  }
  .slider-projects-list-top{
    p{
      display: block;
      max-width: 924px;
      margin: 0 auto 30px;
    }
  }
  .projects-swiper{
    .swiper-slide{
      gap: 40px;
      @media screen and (max-width: 767px) {
        flex-direction: column-reverse;
        gap: 20px;
      }
    }
    .project-content{
      flex: 1;
      h3{
        margin-bottom: $title_s_tablet;
      }
      p{
        margin-bottom: $title_s_tablet;
      }
    }
    .project-image{
      flex-basis: 42%;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        height: 400px;
        object-fit: contain;
        @media screen and (max-width: 767px) {
          height: auto;
        }
      }
    }
  }
}
.swiper-wrap{
  position: relative;
  padding: 0 100px;
  @media screen and (max-width: 767px) {
    padding: 0 0 80px;
  }
  .swiper-slide{
    gap: 30px;
  }
  .swiper-button-next,
  .swiper-button-prev{
    width: 50px;
    height: 50px;
    border-radius: 12px;
    border: 2px solid $c_yellow_medium;
    background: $c_yellow_medium;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease;
    &:hover{
      background: $c_orange;
      border-color: $c_orange;
    }
    svg{
      path{
        fill: #fff;
      }
    }
    &.swiper-button-disabled{
      background: #fff;
      svg{
        path{
          fill: #FFD36A;
        }
      }
    }
  }
  .swiper-button-prev{
    left: 0;
    @media screen and (max-width: 767px) {
      top: auto;
      bottom: 0;
      left: calc(50% - 75px);
    }
  }
  .swiper-button-next{
    right: 0;
    @media screen and (max-width: 767px) {
      top: auto;
      bottom: 0;
      right: calc(50% - 75px);
    }
    svg{
      transform: rotate(180deg);
    }
  }
}

.projects-swiper-nav{
  margin-top: 30px;
  .swiper-pagination{
    position: static;
    display: flex;
    gap: 10px;
    .swiper-pagination-bullet{
      width: 15px;
      height: 15px;
      background: #DDE0E4;
      &.swiper-pagination-bullet-active{
        background: $c_orange;
      }
      @media screen and (max-width: 767px) {
        width: 10px;
        height: 10px;
      }
    }
  }
  .butons-wrap{
    @extend .alc;
    gap: 24px;
    @media screen and (max-width: 767px) {
      gap: 15px;
    }
    .swiper-button-next,
    .swiper-button-prev{
      position: static;
      width: 50px;
      height: 50px;
      border-radius: 12px;
      border: 2px solid $c_yellow_medium;
      background: $c_yellow_medium;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.4s ease;
      &:hover{
        background: $c_orange;
        border-color: $c_orange;
      }
      svg{
        path{
          fill: #fff;
        }
      }
      &.swiper-button-disabled{
        background: #fff;
        svg{
          path{
            fill: #FFD36A;
          }
        }
      }
    }
    .swiper-button-next{
      svg{
        transform: rotate(180deg);
      }
    }
  }
}
.services-tpl{
  padding-top: 150px;
  @media screen and (max-width: 991px) {
    padding-top: 80px;
  }
  .numbers-list{
    margin-bottom: 0;
  }
}
.single-portfolio{
  .paragraph-section{
    text-align: left;
    h2{
      text-align: center;
    }
    ul{
      li{
        position: relative;
        padding-left: 15px;
        margin-bottom: 10px;
        &:before{
          content: '';
          position: absolute;
          width: 6px;
          height: 6px;
          background: $c_yellow_medium;
          border-radius: 50%;
          top: 9px;
          left: 0;
        }
      }
    }
  }
}
.paragraph-section{
  background: #FBFBFB;
  padding: $space_desktop 0;
  text-align: center;
  margin-bottom: $space_desktop;
  @media screen and (max-width: 991px) {
    padding: $space_tablet 0;
    margin-bottom: $space_tablet;
  }
  @media screen and (max-width: 991px) {
    padding: $space_mobile 0;
    margin-bottom: $space_mobile;
  }
  &.white{
    background: #fff;
    margin-bottom: 0;
  }
  h2{
    font-size: 28px;
    margin-bottom: 20px;
    @media screen and (max-width: 991px) {
      font-size: 24px;
    }
  }
  p{
    max-width: 806px;
    margin: 0 auto;
    font-size: 14px;
  }
  img{
    width: 600px;
    margin: 15px auto 0;
  }
}


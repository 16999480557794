.o-simple-page {
  padding-top: 120px;
  padding-bottom: 60px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:not(:first-of-type) {
      margin-top: 48px;
    }
    margin-bottom: 24px;
  }

  p {
    margin-bottom: 24px;
  }

  a {
    color: $c_orange;
    text-decoration: underline;

    &:hover {
      color: $c_orange;
      text-decoration: underline;
    }
  }

  ol,
  ul {
    padding-left: 18px;

    li {
      position: relative;

      &:not(:last-of-type) {
        margin-bottom: 16px;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 7px;
        left: -18px;
        width: 10px;
        height: 10px;
        background-color: $c_orange;
        border-radius: 50%;
      }
    }
  }

  img {
    height: auto;
  }

  figure {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .wp-block-quote {
    font-style: italic;
    padding-left: 24px;
    border-left: 3px solid $c_orange;
  }

  .wp-block-code {
    padding: 24px 32px;
    color: #676875;
    background-color: #F8F8F8;
    border: 1px solid rgba(54, 54, 69, 0.1);
    border-radius: 12px;
    margin-top: 48px;
    margin-bottom: 48px;
  }
}
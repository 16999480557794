//NEW AUTHOR TEMPLATE
.author-page{
  padding: $space_desktop 0;
  @media (max-width: 991px) {
    padding: $space_tablet 0;
  }
  @media (max-width: 767px) {
    padding: $space_mobile 0;
  }
  .breadcrumbs{
    margin-bottom: 55px;
    @media (max-width: 991px) {
      margin-bottom: 30px;
    }
  }
  .page-subtitle{
    font-weight: 700;
    font-size: 36px;
    line-height: 1.5;
    color: $c_text_100;
    display: block;
    margin-bottom: 55px;
    @media (max-width: 767px) {
      margin-bottom: 30px;
      font-size: 28px;
    }
  }
  .author-top-info{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $space_desktop;
    @media screen and (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    @media (max-width: 767px) {
      margin-bottom: $space_mobile;
    }
    h1{
      font-size: 28px;
      @media (max-width: 767px) {
        font-size: 20px;
      }
    }
    .position{
      display: block;
      margin-bottom: 12px;

      font-weight: 700;
      font-size: 20px;
      line-height: 1.4;
      color: $c_text_100;
    }
    .avatar-wrap{
      width: 100px;
      height: 100px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 30px;
      @media (max-width: 479px) {
        margin-right: 0;
        margin-bottom: 30px;
      }
      img{
        width: 100%;
        object-fit: cover;
        height: 100%;
      }
    }
    .author-top-info-left{
      flex: 1;
      display: flex;
      @media (max-width: 991px) {
        margin-bottom: 30px;
      }
      @media (max-width: 479px) {
        flex-direction: column;
        align-items: center;
      }
      .info-wrap{
        flex: 1;
      }
      .short-description{
        margin-bottom: 20px;
      }
      .linkedin{
        margin-bottom: 12px;
        display: inline-block;
        a{
          font-weight: 700;
          font-size: 16px;
          line-height: 1.5;
          color: $c_orange;
        }
      }
      .email{
        display: block;
        a{
          border: 1px solid $c_orange;
          border-radius: 28px;
          display: inline-flex;
          gap: 13px;
          align-items: center;
          justify-content: center;
          padding: 15px 30px;
          transition: all 0.4s ease;

          font-weight: 600;
          font-size: 16px;
          line-height: 1;

          color: #363645;
          path{
            transition: all 0.4s ease;
          }
          &:hover{
            background: $c_orange;
            color: #fff;
            path{
              fill: #fff;
            }
          }
        }
      }
    }
    .author-top-info-right{
      flex-basis: 256px;
      margin-left: 85px;
      @media (max-width: 1200px) {
        margin-left: 45px;
      }
      @media (max-width: 991px) {
        flex-basis: 100%;
        margin-left: 0;
      }
      .expertise-title{
        display: block;
        margin-bottom: 12px;

        font-weight: 700;
        font-size: 20px;
        line-height: 1.5;
        color: $c_text_100;
      }
    }
  }
  .posts-by-author{
    margin-bottom: $space_desktop;
    @media (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    @media (max-width: 7697px) {
      margin-bottom: $space_mobile;
    }
    h2{
      text-align: center;
      font-size: 32px;
      margin-bottom: 75px;
      @media (max-width: 991px) {
        margin-bottom: 30px;
        font-size: 28px;
      }
    }
  }
  .projects-by-author{
    h2{
      text-align: center;
      font-size: 32px;
      margin-bottom: 44px;
      @media (max-width: 991px) {
        margin-bottom: 30px;
        font-size: 28px;
      }
    }
    .col-mobile-url{
      @media (max-width: 1024px) {
        margin-bottom: 30px;
        text-align: center;
      }
    }
  }
}
.admin-bar{
  .o-podcast{
    padding-top: 39px;
    @media screen and (max-width: 991px) {
      padding-top: 10px;
    }
  }
}
.o-podcast{
  padding-top: 70px;
  @media screen and (max-width: 991px) {
    padding-top: 57px;
  }
  .video-box{
    position: relative;
    svg{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      @media screen and (max-width: 767px) {
        scale: 0.7;
      }
    }
  }
  .o-btn--primary{
    font-size: 18px;
    line-height: 133.3%;
    padding: 16px;
    @media screen and (max-width: 991px) {
      font-size: 16px;
      padding: 12px;
    }
  }
  .podcast-hero{
    padding: 111px 0 140px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: $space_desktop;
    position: relative;
    z-index: 0;
    &:after{
      content:'';
      position:absolute;
      top:0;
      left:0;
      bottom: 0;
      right: 0;
      background: #000;
      opacity: 0.3;
      display: none;
      z-index: -1;
      @media screen and (max-width: 767px) {
        display: block;
      }
    }
    @media screen and (max-width: 991px) {
      padding: 80px 0;
      margin-bottom: $space_tablet;
      background-position: center right;
    }
    .podcast-hero-top{
      max-width: 600px;
      margin-bottom: 75px;
    }
    h1{
      font-size: 44px;
      line-height: 1.2;
      font-weight: 700;
      color: #fff;
      margin-bottom: 24px;
      @media screen and (max-width: 991px) {
        font-size: 28px!important;
      }
      @media screen and (max-width: 767px) {
        font-size: 24px!important;
      }
    }
    .podcast-subtitle{
      font-size: 16px;
      color: #fff;
      display: block;
      max-width: 340px;
    }
    .podcast-hero-bottom{
      max-width: 600px;
      .hero-youtube{
        margin-bottom: 16px;
      }
      span{
        font-size: 18px;
        line-height: 1.2;
        color: #fff;
        font-weight: 700;
        display: block;
        max-width: 380px;
        @media screen and (max-width: 991px) {
          font-size: 16px;
        }
      }
    }
  }
  .podcast-main{
    margin-bottom: $space_desktop;
    text-align: center;
    @media screen and (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    h2{
      margin-bottom: 6px;
      font-size: 32px;
      @media screen and (max-width: 991px) {
        font-size: 28px;
      }
      @media screen and (max-width: 767px) {
        font-size: 24px;
      }
    }
    p{
      max-width: 600px;
      margin: 0 auto 40px;
      @media screen and (max-width: 991px) {
        margin: 0 auto 30px;
      }
    }
    .video-box{
      display: inline-block;
      margin-bottom: 24px;
    }
  }
  .podcast-why{
    margin-bottom: $space_tablet;
    @media screen and (max-width: 767px) {
      margin-bottom: $space_mobile;
    }
    .alc{
      @media screen and (max-width: 767px) {
        flex-direction: column;
        gap: 20px;
      }
    }
    .content-wrap{
      flex-basis: 47%;
      @media screen and (max-width: 767px) {
        flex-basis: 100%;
      }
      h2{
        font-size: 44px;
        margin-bottom: 24px;
        @media screen and (max-width: 991px) {
          font-size: 28px;
        }
        @media screen and (max-width: 767px) {
          font-size: 24px;
        }
      }
      p:not(:last-child){
        margin-bottom: 20px;
      }
    }
    .image-wrap{
      border-radius: 16px;
      width: 330px;
      height: 330px;
    }
  }
  .podcast-highlights{
    margin-bottom: $space_desktop;
    @media screen and (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    h2{
      font-size: 32px;
      margin-bottom: 32px;
      @media screen and (max-width: 991px) {
        font-size: 28px;
      }
      @media screen and (max-width: 767px) {
        font-size: 24px;
      }
    }
    .alc{
      gap: 32px;
      justify-content: space-between;
    }
    .highlights-item{
      flex-basis: 25.7%;
      text-align: center;
      @media screen and (max-width: 767px) {
        flex-basis: calc(50% - 16px);
      }
      @media screen and (max-width: 479px) {
        flex-basis: 100%;
      }
      img{
        display: block;
        margin: 0 auto 24px;
      }
    }
  }
  .podcast-host{
    padding: $space_desktop 0;
    border-top: 1px solid #EDEDED;
    background-color: #FBFBFB;
    margin-bottom: $space_desktop;
    @media screen and (max-width: 991px) {
      margin-bottom: $space_tablet;
      padding: $space_tablet 0;
    }
    h2{
      font-size: 44px;
      margin-bottom: 24px;
      text-align: center;
      @media screen and (max-width: 991px) {
        font-size: 28px;
      }
    }
    .alc{
      @media screen and (max-width: 991px) {
        gap: 20px;
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
      &.hosts:nth-child(odd){
        flex-direction: row-reverse;
      }
      &:not(:last-child){
        margin-bottom: 24px;
      }
    }
    .host-info{
      flex-basis: 47%;
      @media screen and (max-width: 991px) {
        flex-basis: 100%;
      }
      p{
        margin-bottom: 48px;
        @media screen and (max-width: 991px) {
          margin-bottom: 30px;
        }
      }
    }
    .host-image{
      flex-basis: 47%;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 991px) {
        flex-basis: 100%;
      }
      img{
        max-width: 410px;
      }
    }
  }
  .podcast-results{
    padding: $space_desktop 0;
    @media screen and (max-width: 991px) {
      padding: $space_tablet 0;
    }
    h2{
      font-size: 44px;
      max-width: 780px;
      margin-bottom: 48px;
      @media screen and (max-width: 991px) {
        font-size: 28px;
        margin-bottom: 20px;
      }
      @media screen and (max-width: 767px) {
        font-size: 24px;
      }
    }
    .alc{
      gap: 54px;
      @media screen and (max-width: 991px) {
        gap: 30px;
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }
    .results-content{
      flex: 1;
      p{
        max-width: 600px;
        font-size: 18px;
        line-height: 144%;
        margin-bottom: 48px;
        @media screen and (max-width: 991px) {
          font-size: 16px;
          margin-bottom: 20px;
        }
      }
    }
    .results-image{
      flex-basis: 40.625%;
    }
  }
  .cta-subscribe{
    background: #FBFBFB;
    padding: 40px 0;
    .alc{
      gap: 20px;
      @media screen and (max-width: 991px) {
        flex-direction: column;
      }
    }
    h6{
      font-size: 24px;
      line-height: 133%;
      @media screen and (max-width: 991px) {
        font-size: 18px;
      }
      @media screen and (max-width: 991px) {
        text-align: center;
      }
    }
  }
}
.home{
  .latest-episodes-podcast{
    margin-bottom: $space_desktop;
    @media screen and (max-width: 767px) {
      margin-bottom: $space_mobile;
    }
    h3{
      margin-bottom: 30px;
    }
  }
}
.latest-episodes-podcast{
  border-bottom: 1px solid #EDEDED;
  padding-bottom: $space_desktop;
  @media screen and (max-width: 991px) {
    padding-bottom: $space_desktop;
  }
  @media screen and (max-width: 767px) {
    padding: 0 15px 60px;
  }
  .video-box{
    position: relative;
    svg{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      @media screen and (max-width: 767px) {
        scale: 0.7;
      }
    }
  }
  h3{
    font-size: 32px;
    text-align: center;
    margin-bottom: 6px;
    @media screen and (max-width: 991px) {
      font-size: 28px;
    }
    @media screen and (max-width: 767px) {
      font-size: 24px;
    }
  }
  p{
    text-align: center;
    font-size: 16px;
    line-height: 162.5%;
    margin-bottom: 40px;
  }
  .episodes{
    .swiper-slide{
      max-width: 560px;

    }
  }
  a{
    text-align: left;
    display: block;
    .video-box{
      margin-bottom: 31px;
    }
    img{
      width: 100%;
      border-radius: 16px;
    }
    h3{
      text-align: left;
      margin-bottom: 6px;
      @media screen and (max-width: 991px) {
        font-size: 20px;
      }
    }
    p{
      font-size: 20px;
      color: #676875;
      font-weight: 400;
      text-align: left;
      margin-bottom: 60px;
      @media screen and (max-width: 991px) {
        margin-bottom: 30px;
        font-size: 16px;
      }
    }
  }
  .link-wrap{
    text-align: center;
    a{
      position: relative;
      display: flex;
      justify-content: center;
      img{
        margin-bottom: 0;
      }
    }
  }
}
.podcast-episodes-section{
  background: #fff;
  h2{
    font-size: 32px;
    text-align: center;
    margin-bottom: 6px;
    @media screen and (max-width: 991px) {
      font-size: 28px;
    }
    @media screen and (max-width: 767px) {
      font-size: 24px;
    }
  }
  .podcast-section-description{
    text-align: center;
    font-size: 16px;
    line-height: 162.5%;
    margin-bottom: 40px;
  }
  .link-wrap{
    text-align: center;
    margin-top: $space_desktop;
    @media screen and (max-width: 991px) {
      margin-top: $space_mobile;
    }
    a{
      position: relative;
      display: flex;
      justify-content: center;
      img{
        margin-bottom: 0;
      }
    }
  }
}
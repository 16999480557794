.centred-image-blocks-on-sides{
  margin-bottom: $space_desktop;
  @media (max-width: 991px) {
    margin-bottom: $space_tablet;
  }
  h2{
    text-align: center;
    margin-bottom: $space_desktop;
    @media screen and (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: $space_mobile;
    }
  }
  .alc{
    gap: 30px;
    @media screen and (max-width: 991px) {
      gap: 20px;
    }
  }
  .text-blocks{
    display: flex;
    flex-direction: column;
    gap: 49px;
    //flex-basis: calc(33.33% - 40px);
    flex: 1;
    @media screen and (max-width: 991px) {
      flex-direction: row;
      flex-basis: 100%;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      gap: 30px;
    }
    .text-block{
      flex: 1;
      .block-title{
        display: block;
        font-size: 22px;
        font-weight: 700;
        line-height: 1.5;
        margin-bottom: 10px;
        color: $c_text_100;
        @media screen and (max-width: 767px) {
          font-size: 18px;
        }
      }
    }
  }
  .image-block{
    flex-basis: calc(33.33% - 40px);
    text-align: center;
    @media screen and (max-width: 991px) {
      flex-basis: 100%;
    }
  }
}
.o-contact-us {
  .wpcf7-form {
    br{
      display: none;
    }
    .wpcf7-form-control-wrap{
      margin-bottom: 24px;
      display: block;
      position: relative;
    }
    input:not([type="submit"]):not([type="checkbox"]):not([type="file"]) {
      font-size: 16px;
      color: $c_text_100;
      background-color: #F8F8F8;
      padding: 18px 32px;
      border: 1px solid rgba(54, 54, 69, 0.1);
      border-radius: 12px;
      width: 100%;
      &::-webkit-input-placeholder { /* Edge */
        color: rgba($c_text_80, 0.5);
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: rgba($c_text_80, 0.5);
      }

      &::placeholder {
        color: rgba($c_text_80, 0.5);
      }

      &:focus {
        border-color: $c_orange;
      }
    }
    textarea {
      font-size: 16px;
      color: $c_text_100;
      background-color: #F8F8F8;
      padding: 24px 32px 56px;
      border: 1px solid rgba(54, 54, 69, 0.1);
      border-radius: 12px;
      height: 250px;
      width: 100%;
      resize: none;

      &::-webkit-input-placeholder { /* Edge */
        color: rgba($c_text_80, 0.5);
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: rgba($c_text_80, 0.5);
      }

      &::placeholder {
        color: rgba($c_text_80, 0.5);
      }

      &:focus {
        border-color: $c_orange;
      }
    }
    input[type="submit"] {
      font-weight: 700;
      color: $c_white;
      background-color: $c_yellow_medium;
      border-radius: 12px;
      padding: 10px 24px;
      border: 0;
      outline: none;

      &:hover {
        color: $c_white;
      }
    }
    .wpcf7-submit:disabled {
      opacity: 0.5;
    }
    .two-in-row {
      display: flex;
      margin: 0 -12px;

      & > div {
        padding: 0 12px;
      }
    }
    .attach-inside {
      position: relative;
      margin-bottom: 28px;
    }
    .attact-file {
      margin-bottom: 24px;
      position: relative;
      label {
        display: inline-flex;
        cursor: pointer;
        color: $c_text_100;
        &::before {
          content: url('../img/attach.svg');
          height: 24px;
          margin-right: 8px;
        }
      }
      .codedropz-upload-handler{
        display: none;
      }
      .dnd-upload-status{
        .dnd-upload-image{
          display: none;
        }
        .dnd-upload-details{
          padding-left: 0;
          width: 100%;
          .name{
            align-items: center;
            span{
              font-family: 'Mont';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 1;
              color: $c_text_100;
            }
            em{
              font-family: 'Mont';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 1;
              color: $c_orange;
            }
          }
          .remove-file{
            top: 8px;
            span{
              &:after{
                background-image: url('../img/close.png');
              }
            }
          }
          .dnd-progress-bar{
            span{
              background: $c_orange;
            }
          }
        }
      }
      input,
      br {
        display: none;
      }
    }
    .wpcf7-acceptance {
      input {
        display: none;

        &:checked {
          & + .wpcf7-list-item-label {
            &::before {
              animation: none;
            }
            &::after {
              opacity: 1;
            }
          }
        }
      }
      .wpcf7-list-item {
        margin: 0;
      }
      .wpcf7-list-item-label {
        max-width: 280px;
        display: block;
        position: relative;
        color: $c_text_100;
        padding-left: 30px;

        &::before {
          position: absolute;
          top: 14px;
          left: 0;
          content: '';
          display: block;
          width: 18px;
          height: 18px;
          border: 2px solid $c_orange;
          border-radius: 4px;
          box-shadow: 0 0 0 rgba($c_orange, 0.4);
          animation: pulse 2s infinite;
        }

        &::after {
          position: absolute;
          top: 10px;
          left: 4px;
          content: url('../img/acceptance-check.svg');
          opacity: 0;
        }
      }
      a {
        color: $c_orange;

        &:hover {
          color: $c_orange;
        }
      }
    }
    .bottom {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .accept {
        max-width: 260px;

        a {
          font-weight: 600;
          color: #ffb248;
          border-bottom: 1px solid #ffb248;
          transition: .3s all;

          &:hover {
            color: $c_yellow_medium;
            border-color: $c_yellow_medium;
          }
        }
      }

      br {
        display: none;
      }
      .submit-wrap{
        position: relative;
        @media screen and (max-width: 479px) {
          margin: 15px 0;
        }
        .wpcf7-spinner{
          position: absolute;
          top: calc(100% + 10px);
          left: calc(50% - 24px);
          @media screen and (max-width: 479px) {
            top: calc(50% - 12px);
            left: calc(100% + 12px);
          }
        }
      }
      .ajax-loader {
        display: none;
      }
    }
    .wpcf7-response-output {
      display: none !important;
    }

    .wpcf7-not-valid {
      border-color: red !important;
    }
    .wpcf7-not-valid-tip{
      text-align: center;
      cursor: pointer;
      padding-top: 16px;
    }
  }
}


@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba($c_orange, 0.4);
  }
  70% {
      box-shadow: 0 0 0 10px rgba($c_orange, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba($c_orange, 0);
  }
}


.transform-form{
  padding: $space_desktop 0;
  @media screen and (max-width: 991px) {
    padding: $space_tablet 0;
  }
  @media screen and (max-width: 767px) {
    padding: $space_mobile 0;
  }
  h2{
    font-size: 28px;
    text-align: center;
    margin-bottom: 20px;
  }
  .transform-form-description{
    text-align: center;
    margin-bottom: 20px;
    font-size: 14px;
  }
  form{
    max-width: 570px;
    margin: 0 auto;
    .saleforces-full{
      margin-bottom: 24px;
    }
    .saleforces-form-top{
      margin-bottom: 24px;
      h2{
        margin-bottom: 20px;
      }
      p{
        display: flex;
        align-items: flex-start;
        gap: 24px;
        .wpcf7-form-control-wrap{
          margin-bottom: 0;
        }
        @media screen and (max-width: 767px) {
          flex-direction: column;
        }
        span{
          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }
      }
    }
    .wpcf7-form-control-wrap[data-name="email-156"]{
      //margin-bottom: 24px;
      display: block;
    }
    input:not([type="submit"]):not([type="checkbox"]):not([type="file"]) {
      font-size: 14px;
      color: $c_text_100;
      background-color: #fff;
      padding: 18px 32px;
      border: 1px solid rgba(54, 54, 69, 0.1);
      border-radius: 12px;
      width: 100%;
      &::-webkit-input-placeholder { /* Edge */
        color: rgba($c_text_80, 0.5);
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: rgba($c_text_80, 0.5);
      }

      &::placeholder {
        color: rgba($c_text_80, 0.5);
      }

      &:focus {
        border-color: $c_orange;
      }
    }
    textarea {
      font-size: 14px;
      color: $c_text_100;
      background-color: #fff;
      padding: 18px 32px ;
      border: 1px solid rgba(54, 54, 69, 0.1);
      border-radius: 12px;
      height: 250px;
      width: 100%;
      resize: none;
      margin-bottom: 24px;
      display: block;
      &::-webkit-input-placeholder { /* Edge */
        color: rgba($c_text_80, 0.5);
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: rgba($c_text_80, 0.5);
      }

      &::placeholder {
        color: rgba($c_text_80, 0.5);
      }

      &:focus {
        border-color: $c_orange;
      }
    }
    input[type="submit"] {
      line-height: 24px;
      display: inline-block;
      border: none;
      outline: 0;
    }
    .attact-file {
      margin-bottom: 24px;
      position: relative;
      label {
        display: inline-flex;
        cursor: pointer;
        color: $c_text_100;
        font-size: 14px;
        align-items: center;
        &::before {
          content: url('../img/attach.svg');
          height: 24px;
          margin-right: 8px;
        }
      }
      .codedropz-upload-handler{
        display: none;
      }
      .dnd-upload-status{
        .dnd-upload-image{
          display: none;
        }
        .dnd-upload-details{
          padding-left: 0;
          width: 100%;
          .name{
            align-items: center;
            span{
              font-family: 'Mont';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 1;
              color: $c_text_100;
            }
            em{
              font-family: 'Mont';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 1;
              color: $c_orange;
            }
          }
          .remove-file{
            top: 8px;
            span{
              &:after{
                background-image: url('../img/close.png');
              }
            }
          }
          .dnd-progress-bar{
            span{
              background: $c_orange;
            }
          }
        }
      }
      input,
      br {
        display: none;
      }
    }
    .wpcf7-acceptance {
      input {
        display: none;

        &:checked {
          & + .wpcf7-list-item-label {
            &::before {
              animation: none;
            }
            &::after {
              opacity: 1;
            }
          }
        }
      }
      .wpcf7-list-item {
        margin: 0;
      }
      .wpcf7-list-item-label {
        max-width: 280px;
        display: block;
        position: relative;
        color: $c_text_100;
        padding-left: 30px;

        &::before {
          position: absolute;
          top: 14px;
          left: 0;
          content: '';
          display: block;
          width: 18px;
          height: 18px;
          border: 2px solid $c_orange;
          border-radius: 4px;
          box-shadow: 0 0 0 rgba($c_orange, 0.4);
          animation: pulse 2s infinite;
        }

        &::after {
          position: absolute;
          top: 10px;
          left: 4px;
          content: url('../img/acceptance-check.svg');
          opacity: 0;
        }
      }
      a {
        color: $c_orange;

        &:hover {
          color: $c_orange;
        }
      }
    }
    .bottom {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .accept {
        max-width: 260px;
        font-size: 14px;
        @media screen and (max-width: 479px) {
          max-width: 100%;
        }
        a {
          font-weight: 600;
          color: #ffb248;
          border-bottom: 1px solid #ffb248;
          transition: .3s all;

          &:hover {
            color: $c_yellow_medium;
            border-color: $c_yellow_medium;
          }
        }
      }

      br {
        display: none;
      }
      .submit-wrap{
        position: relative;
        @media screen and (max-width: 479px) {
          margin: 15px 0;
        }
        .wpcf7-spinner{
          position: absolute;
          top: calc(100% + 10px);
          left: calc(50% - 24px);
          @media screen and (max-width: 479px) {
            top: calc(50% - 12px);
            left: calc(100% + 12px);
          }
        }
      }
      .ajax-loader {
        display: none;
      }
    }
    .wpcf7-response-output {
      display: none !important;
    }

    .wpcf7-not-valid {
      border-color: red !important;
    }
    .wpcf7-not-valid-tip{
      text-align: center;
      cursor: pointer;
      padding-top: 16px;
    }
  }
}
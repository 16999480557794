.testimonials-custom-slides{
  margin-bottom: $space_desktop;
  padding: $space_desktop 0;
  background: #FBFBFB;
  @media (max-width: 991px) {
    margin-bottom: $space_tablet;
    padding: $space_tablet 0;
  }
  h2{
    text-align: center;
    margin-bottom: $space_desktop;
    @media screen and (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: $space_mobile;
    }
  }
  .testimonials-custom{
    .swiper-wrapper{
      align-items: stretch;
    }
    .swiper-slide{
      //max-width: 540px;
      width: 50%;
      transition: all 0.4s ease;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &:not(.swiper-slide-active){
        transform: scale(0.85);
      }
      .video-box{
        padding: 42px 42px 32px;
        background: #fff;
        border-radius: 20px;
        .video-banner-video{
          margin-bottom: 40px;
        }
        .testimonial-title{
          font-size: 20px;
          font-weight: 700;
          line-height: 140%;
          color: $c_text_100;
          margin-bottom: 6px;
          @media screen and (max-width: 991px) {
            font-size: 18px;
          }
        }
        .testimonial-subtitle{
          font-size: 16px;
          font-weight: 600;
          line-height: 140%;
          margin-bottom: 20px;
          color: $c_text_100;
          @media screen and (max-width: 991px) {
            font-size: 15px;
          }
        }
      }
      //.team-box{
      //  margin-top: 75px;
      //  border-radius: 15px;
      //  background: #fff;
      //  padding: 103px 42px 32px;
      //  position: relative;
      //  flex: 1;
      //  z-index: 0;
      //  img{
      //    width: 150px;
      //    height: 150px;
      //    border-radius: 50%;
      //    overflow: hidden;
      //    object-fit: cover;
      //    display: block;
      //    position: absolute;
      //    top: -75px;
      //    left: calc(50% - 75px);
      //  }
      //  .name{
      //    color: $c_text_100;
      //    font-size: 24px;
      //    font-style: normal;
      //    font-weight: 800;
      //    line-height: 140%;
      //    text-align: center;
      //    margin-bottom: 12px;
      //  }
      //  .info{
      //    color: #83838E;
      //    font-size: 16px;
      //    font-style: normal;
      //    font-weight: 600;
      //    line-height: 150%;
      //    text-align: center;
      //    margin-bottom: 32px;
      //  }
      //}
      .team-box{
        padding: 42px 42px 32px;
        background: #fff;
        border-radius: 20px;
        //flex: 1;
        //display: flex;
        //flex-direction: column;
        //justify-content: center;
        p{
          margin-bottom: 37px;
        }
        .client-info-wrap{
          display: flex;
          align-items: center;
          gap: 20px;
          justify-content: flex-end;
          .name{
            font-weight: 700;
            font-size: 20px;
            color: $c_text_100;
          }
          .info{
            font-weight: 300;
            font-size: 13px;
            color: $c_text_100;
          }
          img{
            width: 50px;
            height: 50px;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
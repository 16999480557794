.single-industry,.single-expertise {
  .o-banner {
    margin-bottom: 5.2vw;
    padding: 220px 0 210px;
    @media (max-width: 1199px) {
      margin-bottom: 0;
      padding: 150px 0;
    }
    @media (max-width: 767px) {
      padding: 30px 0 120px;
    }
    &__illustration {
      @media (min-width: 1200px) {
        width: 53.5%;
      }
    }
  }
  .banner-section {
    padding-top: 60px;
    margin-bottom: 60px;
    .alc{
      @media (max-width: 991px) {
        flex-direction: column;
      }
    }
    .banner-content-wrap{
      @media (max-width: 991px) {
        margin-bottom: 30px;
      }
    }
    .banner-content{
      padding-top: 130px;
      position: relative;
      @media (max-width: 991px) {
        padding-top: 60px;
      }
      .breadcrumbs{
        position: absolute;
        top: 0;
      }
    }
  }

  .o-services-s {
    margin-bottom: 52px;
    @media (max-width: 767px) {
      margin-bottom: 44px;
    }
    &__title {
      text-align: center;
      margin-bottom: $title_s_desktop;
    }

  }

  .o-cta {
    margin-bottom: $space_desktop;
    @media (max-width: 767px) {
      margin-bottom: $space_mobile;
    }
  }

  .o-projects {
    margin-bottom: $space_desktop;
    @media screen and (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    @media (max-width: 767px) {
      margin-bottom: $space_mobile;
    }
  }
}
//NEW
.single-expertise-tpl {
  padding-top: 90px;
  @media screen and (max-width: 991px) {
    padding-top: 70px;
  }
  .horisontal-tabs{
    .tabs .tabs-titles .title{
      max-width: 360px;
      font-size: 18px;
      @media screen and (max-width: 991px) {
        max-width: 100%;
      }
    }
    .tabs-swiper{
      box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.05);
      border-radius: 15px;
    }
    .swiper-slide{
      border: none!important;
      padding: 39px 49px!important;
    }
  }
}

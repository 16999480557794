.two-columns-and-icon-centred{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: $space_desktop;
  @media (max-width: 991px) {
    margin-bottom: $space_tablet;
  }
  .two-columns-item-centred{
    flex-basis: 43.3%;
    text-align: center;
    margin-bottom: $space_tablet;
    @media (max-width: 767px) {
      flex-basis: 100%;
      margin-bottom: $space_mobile;
    }
    .two-columns-item-centred-icon{
      margin-bottom: 16px;
    }
    h3{
      margin-bottom: 24px;
    }
  }
}
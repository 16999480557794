.page-template-testimonials-template{
  &.admin-bar{
    .o-testimonials{
      padding-top: 100px;
      @media screen and (max-width: 991px) {
        padding-top: 80px;
      }
    }
  }
}
.o-testimonials{
  padding-top: 140px;
  margin-bottom: 0!important;
  @media screen and (max-width: 991px) {
    padding-top: 120px;
  }
  h2{
    color: #363646;
    font-size: 46px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 22px;
    text-align: center;
    @media screen and (max-width: 991px) {
      font-size: 32px;
    }
    @media screen and (max-width: 767px) {
      font-size: 24px;
    }
  }
  h3{
    color: #363646;
    text-align: center;
    font-family: 'Mont';
    font-size: 34px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 50px;
    @media screen and (max-width: 991px) {
      font-size: 30px;
      margin-bottom: 40px;
    }
    @media screen and (max-width: 767px) {
      font-size: 22px;
      margin-bottom: 30px;
    }
  }
  .video-box{
    position: relative;
    border-radius: 24px;
    overflow: hidden;
    svg{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      @media screen and (max-width: 767px) {
        scale: 0.7;
      }
    }
  }
  .testimonials-hero{
    text-align: center;
    margin-bottom: $space_desktop;
    @media screen and (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    h1{
      font-size: 52px;
      margin-bottom: 24px;
      @media screen and (max-width: 991px) {
        font-size: 30px;
      }
      @media screen and (max-width: 767px) {
        font-size: 26px;
      }
    }
    p{
      max-width: 810px;
      margin: 0 auto;
      font-size: 16px;
    }
  }
  .testimonials-stats{
    padding: 30px 0;
    background: #FBFBFB;
    margin-bottom: $space_desktop;
    @media screen and (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    .alc{
      gap: 24px;
      align-items: stretch;
      @media screen and (max-width: 991px) {
        justify-content: center;
      }
    }
    .stat-block{
      border-radius: 16px;
      border: 1px solid #E5E5E5;
      background: #FFF;
      padding: 24px;
      flex-basis: calc(33.33% - 16px);
      text-align: center;
      @media screen and (max-width: 991px) {
        flex-basis: calc(50% - 12px);
      }
      @media screen and (max-width: 767px) {
        flex-basis: 100%;
      }
      .stat-icon{
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px;

        color: #363646;
        font-family: 'Mont';
        font-size: 56px;
        font-weight: 700;
        line-height: 48px;
      }
      .stat-text{
        color: #848490;
        font-size: 24px;
        font-weight: 600;
        line-height: 1;
        font-family: 'Mont';
      }
    }
  }
  .testimonials-gallery{
    margin-bottom: $space_desktop;
    @media screen and (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    .gallery-items{
      .gallery-item{
        width: calc(33.33% - 25px);
        margin-bottom: 25px;
        display: block;
        @media screen and (max-width: 1200px) {
          width: calc(50% - 12.5px);
        }
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        &.site-testimonial,
        &.upwork-testimonial,
        &.goodfirms-testimonial,
        &.clutch-testimonial{
          border-radius: 16px;
          border: 1px solid #DDD;
          background: #FFF;
          padding: 16px;
        }
        &.video-testimonial{
          min-height: 600px;
          @media screen and (max-width: 991px) {
            min-height: 480px;
          }
          @media screen and (max-width: 767px) {
            min-height: 380px;
          }
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        &.site-testimonial{
          .flex{
            justify-content: flex-start;
            gap: 10px;
          }
          .feedback{
            color: $c_text_100;
            font-size: 16px;
            font-weight: 400;
            line-height: 162.5%;
            display: block;
            margin-bottom: 12px;
          }
          .logo{
            max-width: 110px;
          }
        }
        &.upwork-testimonial{
          .testimonial-title{
            color: $c_text_100;
            font-size: 17px;
            font-weight: 700;
            line-height: 156%;
            margin-bottom: 15px;
          }
          .testimonial-date{
            color: #414154;
            font-size: 9px;
            font-weight: 400;
            line-height: 166%;
            margin-bottom: 14px;
          }
          .feedback{
            color: #414154;
            font-size: 15px;
            font-weight: 400;
            line-height: 156%;
          }
          .testimonials-stars{
            gap: 9px;
          }
          .testimonial-hours{
            color: #414154;
            font-size: 11px;
            font-weight: 400;
            line-height: 166%;
          }
          .testimonials-stars{
            color: #414154;
            font-size: 11px;
            font-style: normal;
            font-weight: 600;
            line-height: 166%;
          }
        }
        &.clutch-testimonial{
          .text{
            flex: 1;
            span{
              color: #3F3F54;
              font-family: 'Mont';
              font-size: 11px;
              font-style: normal;
              font-weight: 600;
              line-height: 156%;
              letter-spacing: 1px;
              margin-bottom: 2px;
            }
            .feedback-title{
              color: #3F3F54;
              font-size: 15px;
              font-style: normal;
              font-weight: 600;
              line-height: 140%;
              margin-bottom: 14px;
            }
            .feedback-text{
              color: #3F3F54;
              font-size: 13px;
              font-weight: 400;
              line-height: 156%;
            }
          }
        }
        &.goodfirms-testimonial{
          .flex{
            align-items: flex-start;
          }
          .rating{
            position: relative;
            padding-bottom: 30px;
            padding-right: 10px;
            &:before{
              content:'';
              position:absolute;
              top:0;
              right:0;
              bottom: 0;
              width: 1px;
              background: #CFDBE8;
            }
          }
          .text{
            flex: 1;
            padding-left: 14px;
            .feedback-title{
              font-size: 15px;
              font-weight: 700;
              margin-bottom: 8px;
              line-height: 140%;
              color: #3F3F54;
            }
            .feedback-text{
              font-size: 13px;
              font-weight: 400;
              line-height: 156%;
              color: #414154;
            }
          }
        }
        .testimonial-client-image{
          width: 48px;
          height: 48px;
          border-radius: 50%;
          overflow: hidden;
        }
        .testimonial-client-name{
          color: #333345;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 2px;
          &.strong{
            font-weight: 700;
          }
        }
        .testimonial-client-position{
          color: $c_text_100;
          font-size: 14px;
          font-weight: 400;
          line-height: 1;
        }
        .testimonial-box{
          border: 0.4px solid #CFDBE8;
          padding: 16px;
          margin-bottom: 10px;
        }
        .testimonial-top{
          margin-bottom: 12px;
        }
        .testimonial-bottom{
          display: flex;
          justify-content: space-between;
          align-items: center;
          .bottom-link{
            color: $c_orange;
            font-size: 16px;
            font-weight: 600;
            line-height: 150%;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .testimonials-videos{
    margin-bottom: $space_desktop;
    @media screen and (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    .container-1920{
      @media screen and (max-width: 991px) {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
    //.episodes{
    //  margin-left: calc((100% - 1170px)/2) !important;
    //  @media screen and (max-width: 991px) {
    //    margin-left: 0 !important;
    //  }
    //}
  }
  .companies-logos{
    margin-bottom: $space_desktop;
    @media screen and (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    .logos-items{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 36px;
      a{
        min-height: 60px;
        max-width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
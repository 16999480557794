.page-template-home-template{
  .banner-section{
    @media screen and (max-width: 991px) {
      padding-top: 90px;
    }
  }
  .banner-image-wrap{
    @media screen and (max-width: 991px) {
      display: none;
    }
  }
}
.home-services{
  .o-services__item-title{
    font-size: 24px;
    font-weight: 700;
    line-height: 1.4;
    color: $c_text_100;
    display: block;
    transition: 0.3s all;
    &:hover{
      color: $c_orange;
    }
  }
  .o-services__item-description{
    margin-bottom: 4px;
  }
}
.home-about-us{
  margin-bottom: $space_desktop;
  @media (max-width: 991px) {
    margin-bottom: 0;
  }
  h2{
    margin-bottom: $title_s_desktop;
  }
  p{
    font-size: 14px;
    margin-bottom: 55px;
    @media (max-width: 991px) {
      margin-bottom: 44px;
    }
  }
  .home-about-us-columns-wrapper{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .home-about-us-column{
    position: relative;
    flex-basis: 31%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 991px) {
      flex-basis: 49%;
      margin-bottom: $space_tablet;
    }
    @media (max-width: 767px) {
      flex-basis: 100%;
    }
  }
  .home-about-us-image{
    overflow: hidden;
    border-radius: 16px;
    &:after{
    content:'';
    position:absolute;
    top: 52%;
    bottom:0;
    left:0;
    right: 0;
    background: linear-gradient(180.04deg, rgba(255, 255, 255, 0) 7.49%, #FFFFFF 149.86%, #FFFFFF 205.2%);
    }
    img{
      width: 100%;
    }
  }
}
.home{
  .o-industries{
    .swiper-container{
      padding-bottom: 65px;
    }
    .swiper-pagination-bullet{
      width: 15px;
      height: 15px;
      margin: 0 7px;
      opacity: 1;
      background: #dde0e4;
    }
    .swiper-pagination-bullet-active{
      background: $c_orange;
    }
    .o-industries__divider{
      padding-top: 20px;
    }
  }
}
.home-video-testimonials{
  margin-bottom: $space_desktop;
  @media screen and (max-width: 991px) {
    margin-bottom: $space_tablet;
  }
  .gallery-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media screen and (max-width: 991px) {
      display: none;
    }
  }
  h2{
    margin-bottom: $title_s_desktop;
    text-align: center;
    @media screen and (max-width: 991px) {
      margin-bottom: 30px;
    }
  }

  .home-video-testimonials-main {
    flex: 1;
    margin-right: 45px;
    .swiper-slide{
      a{
        display: block;
        border-radius: 30px;
        overflow: hidden;
        margin-bottom: 27px;
        position: relative;
        z-index: 0;
        height: 437px;
        @media screen and (max-width: 1200px) {
          height: 330px;
        }
        svg{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 0;
          path{
            transition: all 0.4s ease;
          }
        }
        &:hover{
          svg{
            path{
              fill: $c_orange;
            }
          }
        }
      }
      .video-review-title{
        font-weight: 700;
        font-size: 32px;
        line-height: 140%;
        color: $c_text_100;
        margin-bottom: 10px;
      }
      .video-review-label{
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        color: $c_text_100;
        margin-bottom: 30px;
      }
    }
  }
  .home-video-testimonials-thumbs-wrapper{
    position: relative;
    top: -20px;
    bottom: -20px;
    height: 477px;
    @media screen and (max-width: 1200px) {
      height: 380px;
    }
    .swiper-button-prev,
    .swiper-button-next{
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      left: calc(50% - 10px);
      background-image: none;
      margin: 0;
    }
    .swiper-button-prev{
      top: 0;
    }
    .swiper-button-next{
      bottom: 0;
      top: auto;
    }
  }
  .home-video-testimonials-thumbs {
    width: 276px;
    height: 437px;
    margin: 20px 0 0;
    position: relative;
    @media screen and (max-width: 1200px) {
      height: 330px;
    }
    .swiper-slide {
      border-radius: 18px;
      overflow: hidden;
      border: 3px solid transparent;
      cursor: pointer;
      height: 154px;
      &-thumb-active {
        border-color: #FFB248;
      }
    }
  }
  .home-video-testimonials-wrapper-mobile{
    display: none;
    @media screen and (max-width: 991px) {
      display: block;
    }
    .home-video-testimonials-main-mobile{
      .video-review-link{
        display: inline-flex;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        svg{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 0;
          @media screen and (max-width: 767px) {
            width: 31px;
            height: 31px;
          }
          path{
            transition: all 0.4s ease;
          }
        }
      }
      margin-bottom: 14px;
    }
    .home-video-testimonials-thumbs-mobile{
      margin-bottom: 25px;
      .swiper-slide {
        border-radius: 10px;
        overflow: hidden;
        border: 3px solid transparent;
        cursor: pointer;
        &-thumb-active {
          border-color: #FFB248;
        }
      }
    }
    .home-video-testimonials-main-mobile-text{
      .video-review-title{
        font-weight: 800;
        font-size: 20px;
        line-height: 140%;
        color: $c_text_100;
        margin-bottom: 20px;
      }
      .video-review-label{
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
        color: $c_text_100;
        margin-bottom: 30px;
      }
      .swiper-pagination{
        display: none;
      }
    }
  }

}
.home{
  .home-results-numbers{
    background: #FBFBFB;
    padding: $space_desktop 0;
    margin-bottom: $space_desktop;
    @media screen and (max-width: 991px) {
      padding: $space_tablet 0;
      margin-bottom: $space_tablet;
    }
    @media screen and (max-width: 767px) {
      padding: $space_mobile 0;
      margin-bottom: $space_mobile;
    }
    .container{
      max-width: 930px!important;
    }
    .home-results-text{
      text-align: center;
    }
    .result-items{
      @extend .flex;
      margin-bottom: $title_s_desktop;
      @media screen and (max-width: 991px) {
        margin-bottom: $title_s_tablet;
      }
      .result-item{
        flex-basis: 25%;
        padding: 0 10px;
        text-align: center;
        @media screen and (max-width: 991px) {
          flex-basis: 50%;
        }
        @media screen and (max-width: 767px) {
          flex-basis: 100%;
        }
        .number{
          color: $c_orange;
          font-size: 40px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
          margin-bottom: 10px;
        }
        span{
          color: $c_text_100;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
          max-width: 200px;
          margin: 0 auto;
          display: block;
        }
      }
    }
  }
  .portfolio-list{
    margin-bottom: $space_desktop;
    @media screen and (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: $space_mobile;
    }
    h2{
      text-align: center;
      margin-bottom: $title_s_desktop;
      @media screen and (max-width: 991px) {
        margin-bottom: $title_s_tablet;
      }
    }
    .btn-wrap{
      text-align: center;
    }
    .portfolio-list-items{
      margin-bottom: $space_desktop;
      @extend .flex;
      row-gap: 30px;
      @media screen and (max-width: 767px) {
        margin-bottom: $space_tablet;
      }
      .portfolio-list-item{
        flex-basis: 49%;
        display: flex;
        gap: 20px;
        @media screen and (max-width: 767px) {
          flex-basis: 100%;
        }
        .item-img{
          width: 96px;
          height: 96px;
          border-radius: 15px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .item-content{
          flex: 1;
        }
        .item-title{
          font-size: 18px;
          font-weight: 700;
          color: $c_text_100;
          margin-bottom: 10px;
        }
        .item-description{
          margin-bottom: 10px;
        }
        .icon-link{
          display: flex;
          align-items: center;
          gap: 6px;

          color: $c_orange;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
        }
      }
    }
  }
  .clients-logo-slider{
    background: #fbfbfb;
    padding: $space_desktop 0;
    margin-bottom: $space_desktop;
    text-align: center;
    @media screen and (max-width: 991px) {
      padding: $space_tablet 0;
      margin-bottom: $space_tablet;
    }
    @media screen and (max-width: 767px) {
      padding: $space_mobile 0;
      margin-bottom: $space_mobile;
    }
    h2{
      margin-bottom: $title_s_desktop;
      @media screen and (max-width: 991px) {
        margin-bottom: $title_s_tablet;
      }
    }
    .logos-swiper{
      a.swiper-slide{
        opacity: 1;
        transition: all 0.4s ease;
        &:hover{
          opacity: 0.7;
        }
      }
      .swiper-slide{
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          height: 74px;
          max-width: 50%;
          object-fit: contain;
        }
        span{
          font-size: 15px;
          color: $c_text_100;
          font-weight: 400;
        }
      }
    }
  }
  .our-clients-videos{
    padding: $space_desktop 0;
    background: #FBFBFB;
    @media screen and (max-width: 991px) {
      padding: $space_tablet 0;
    }
    @media screen and (max-width: 767px) {
      padding: $space_mobile 0;
    }
    h2{
      text-align: center;
      margin-bottom: $title_s_desktop;
      @media screen and (max-width: 991px) {
        margin-bottom: $title_s_tablet;
      }
    }
    .clients-videos{
      @extend .flex;
      gap: 60px;
      @media screen and (max-width: 991px) {
        gap: 30px;
      }
      .clients-video-item{
        flex-basis: calc(50% - 30px);
        @media screen and (max-width: 991px) {
          flex-basis: calc(50% - 15px);
        }
        @media screen and (max-width: 767px) {
          flex-basis: 100%;
        }
        .video-banner-video{
          overflow: hidden;
          border-radius: 20px;
          position: relative;
          margin-bottom: 40px;
          @media screen and (max-width: 991px) {
            margin-bottom: $title_s_tablet;
          }
          img{
            width: 100%;
          }
          svg{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            z-index: 0;
            path{
              transition: all 0.4s ease;
            }
            @media screen and (max-width: 479px) {
              width: 60px;
              height: 60px;
            }
          }
          &:hover{
            svg{
              path{
                fill: $c_orange;
              }
            }
          }
        }
        .video-subtitle{
          color: $c_text_100;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 162.5%;
          display: block;
          margin-bottom: 16px;
        }
      }
    }
  }
}
.banner-awards-list{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  padding-top: 40px;
  a{
    display: block;
    text-align: center;
    position: relative;
    img{
      height: 100px;
      max-width: 100px;
      display: inline-block;
      object-fit: contain;
      transition: all 0.4s ease;
      &.active{
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    //&:hover{
    //  img{
    //    opacity: 0;
    //    &.active{
    //      opacity: 1;
    //    }
    //  }
    //}
  }
}

.o-single-career {
  padding-top: 105px;
  padding-bottom: 120px;

  &__back-btn {
    display: inline-flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    color: $c_orange;
    margin-bottom: 32px;

    &:hover {
      color: $c_orange;

      svg {
        transform: translateX(-4px);
      }
    }

    svg {
      margin-right: 8px;
      transition: 0.3s all;
    }
  }
  &__socials {
    position: relative;

    @media (min-width: 1366px) {
      position: sticky;
      top: 148px;
    }

    @media (max-width: 1365px) {
      margin-bottom: 16px;
    }
  }
  &__socials-inner {
    display: flex;
    align-items: center;

    @media (min-width: 1366px) {
      flex-direction: column;
      position: absolute;
      top: -54px;
      left: -40px;
      transform: translateX(-100%);
    }
  }
  &__socials-title {
    font-weight: 600;

    @media (min-width: 1366px) {
      margin-bottom: 12px;
    }

    @media (max-width: 1365px) {
      margin-right: 24px;
    }
  }
  &__title {
    margin-bottom: 16px;
  }
  &__location {
    margin-bottom: 32px;
  }
  &__content {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 16px;
    }

    p,
    ul,
    ol {
      margin-bottom: 32px;
    }

    ol,
    ul {
      padding-left: 18px;

      li {
        position: relative;

        &:not(:last-of-type) {
          margin-bottom: 16px;
        }

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 7px;
          left: -18px;
          width: 10px;
          height: 10px;
          background-color: $c_orange;
          border-radius: 50%;
        }
      }
    }
  }
  &__contact {
    @media (min-width: 992px) {
      position: sticky;
      top: 102px;
      padding-left: 30px;
    }
    @media (min-width: 1200px) {
      padding-left: 70px;
    }
    >.o-btn{
      width: 100%;
    }
  }
  &__contact-form {
    display: none;

    .wpcf7-form {
      input:not([type="submit"]):not([type="checkbox"]):not([type="file"]) {
        font-size: 16px;
        color: $c_text_100;
        background-color: #F8F8F8;
        padding: 18px 32px;
        border: 1px solid rgba(54, 54, 69, 0.1);
        border-radius: 12px;
        width: 100%;
        margin-bottom: 16px;

        &::-webkit-input-placeholder { /* Edge */
          color: rgba($c_text_80, 0.5);
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: rgba($c_text_80, 0.5);
        }

        &::placeholder {
          color: rgba($c_text_80, 0.5);
        }

        &:focus {
          border-color: $c_orange;
        }
      }
      .wpcf7-not-valid-tip{
        text-align: center;
      }
      textarea {
        font-size: 16px;
        color: $c_text_100;
        background-color: #F8F8F8;
        padding: 20px 32px;
        border: 1px solid rgba(54, 54, 69, 0.1);
        border-radius: 12px;
        height: 140px;
        width: 100%;
        resize: none;
        margin-bottom: 16px;

        &::-webkit-input-placeholder { /* Edge */
          color: rgba($c_text_80, 0.5);
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: rgba($c_text_80, 0.5);
        }

        &::placeholder {
          color: rgba($c_text_80, 0.5);
        }

        &:focus {
          border-color: $c_orange;
        }
      }
      input[type="submit"] {
        width: 100%;
        font-size: 24px;
        font-weight: 700;
        color: $c_white;
        background-color: $c_orange;
        border-radius: 12px;
        padding: 10px 24px;
        border: 0;
        outline: none;
        transition: 0.3s all;

        &:hover {
          background-color: $c_yellow_medium;
          color: $c_white;
        }
      }
      .wpcf7-submit:disabled {
        opacity: 0.5;
      }
      .two-in-row {
        display: flex;
        margin: 0 -12px;

        & > div {
          padding: 0 12px;
        }
      }
      .attact-file {
        margin-bottom: 24px;
        position: relative;
        label {
          display: flex;
          cursor: pointer;
          color: $c_text_100;
          flex-wrap: wrap;
          &::before {
            content: url('../img/attach.svg');
            height: 24px;
            margin-right: 8px;
          }
        }
        .wpcf7-not-valid-tip{
          padding-top: 16px;
        }
        .codedropz-upload-handler{
          display: none;
        }
        .dnd-upload-status{
          .dnd-upload-image{
            display: none;
          }
          .dnd-upload-details{
            padding-left: 0;
            width: 100%;
            .name{
              align-items: center;
              span{
                font-family: 'Mont';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 1;
                color: $c_text_100;
              }
              em{
                font-family: 'Mont';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 1;
                color: $c_orange;
              }
            }
            .remove-file{
              top: 8px;
              span{
                &:after{
                  background-image: url('../img/close.png');
                }
              }
            }
            .dnd-progress-bar{
              span{
                background: $c_orange;
              }
            }
          }
        }
        input,
        br {
          display: none;
        }
      }
      .wpcf7-spinner{
        display: block;
        margin: 10px  auto;
      }
      .wpcf7-acceptance {
        input {
          display: none;

          &:checked {
            & + .wpcf7-list-item-label {
              &::after {
                opacity: 1;
              }
            }
          }
        }
        .wpcf7-list-item {
          margin: 0;
        }
        .wpcf7-list-item-label {
          max-width: 280px;
          display: block;
          position: relative;
          color: $c_text_100;
          padding-left: 30px;

          &::before {
            position: absolute;
            top: 14px;
            left: 0;
            content: '';
            display: block;
            width: 18px;
            height: 18px;
            border: 2px solid $c_orange;
            border-radius: 4px;
          }

          &::after {
            position: absolute;
            top: 10px;
            left: 4px;
            content: url('../img/acceptance-check.svg');
            opacity: 0;
          }
        }
        a {
          color: $c_orange;

          &:hover {
            color: $c_orange;
          }
        }
      }
      .ajax-loader {
        display: none!important;
      }
      &.invalid {
        .wpcf7-email.wpcf7-not-valid {
          border-color: red !important;
        }
      }
    }
  }
  &__contact-apply {
    width: 100%;
    font-size: 24px;
    font-weight: 700;
    color: $c_white;
    background-color: $c_orange;
    border-radius: 12px;
    padding: 10px 24px;
    border: 0;
    outline: none;
    transition: 0.3s all;

    &:hover {
      background-color: $c_yellow_medium;
      color: $c_white;
    }
  }
  &__ty {
    display: none;
    margin-top: 12px;
  }
  &__persona {
    display: flex;
    align-items: center;
  }
  &__persona-label {
    color: $c_text_100;
    margin-bottom: 32px;
    padding-top: 32px;
  }
  &__persona-photo {
    width: 95px;
    height: 95px;
    border-radius: 50%;
    margin-right: 30px;
    overflow: hidden;
  }
  &__persona-info {}
  &__persona-name {
    margin-bottom: 4px;
  }
  &__persona-position {
    color: rgba($c_text_100, 0.5);
  }
  &__persona-contacts {
    display: flex;
    align-items: center;
    margin: 16px -8px 0;

    li {
      padding: 0 8px;
    }
  }
}
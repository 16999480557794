.o-article {
  &.gf-badge{
    .o-article__image{
      background-image: url('../img/AI_UX_UI_Trends_and_Tips__cover.webp');
      transition: all 0.4s ease;
      &:hover{
        background-image: url('../img/AI_UX_UI_Trends_and_Tips__cover.webp');
      }
    }
  }
  &__image {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    margin-bottom: 30px;
    padding-bottom: 59.5%;
    border: 1px solid #f0f0f0;
    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      //background-color: rgba($c_text_100, 0.05);
    }

    a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  &__title {
    margin-bottom: 24px;
    min-height: 100px;
    @media (max-width: 767px) {
      min-height: auto;
    }

    a {
      font-weight: 700;
      color: $c_text_100;

      &:hover {
        color: $c_text_100;
      }
    }
  }
  &__excerpt {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 24px;
    font-size: 14px;
    min-height: 63px;
    @media (max-width: 767px) {
      min-height: auto;
    }
  }
}
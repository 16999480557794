body {
  -webkit-font-smoothing: antialiased;
  //font-family: 'Mont';
  font-family: 'Noto Sans', sans-serif;
  font-weight: normal;
  font-size: 15px;
  line-height: 1.5;
  color: $c_text_80;
}
header *,
footer *,
.o-btn-dark,
.o-btn {
  font-family: 'Mont' !important;
}
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-weight: bold;
  line-height: 1.4;
  color: $c_text_100;
}

h1,
.h1 {
  font-size: 32px;
  font-family: 'Mont';
  @media (max-width: 767px) {
    font-size: 28px;
  }
}

h2,
.h2 {
  font-size: 28px;
  font-family: 'Mont';
  @media (max-width: 767px) {
    font-size: 20px;
  }
}

h3,
.h3 {
  font-size: 20px;
  font-family: 'Mont';
  @media (max-width: 767px) {
    font-size: 18px;
  }
}

a {
  font-weight: 600;
}
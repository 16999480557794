.breadcrumbs {
.breadcrumbs-separator {
    width: 6px;
    height: 6px;
    display: block;
    background: #DDE0E4;
    border-radius: 10px;
    margin: 0 16px;
}
& > span {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
& > span > span,
& > span > span > span:not(.breadcrumbs-separator) {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .breadcrumb_last {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 240px;
    white-space: nowrap;
  }
}
a,
span {
    font-weight: 600;
}
a {
    color: $c_orange;

&:hover {
     color: $c_orange;
 }
}

span {
    color: rgba($c_text_100, 0.4);
}
}
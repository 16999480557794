.diagram-section{
  background: #fbfbfb;
  padding: $space_desktop 0;
  margin-bottom: $space_desktop;
  @media (max-width: 991px) {
    padding: $space_tablet 0;
    margin-bottom: $space_tablet;
  }
  h2{
    text-align: center;
    margin-bottom: $space_desktop;
    @media screen and (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: $space_mobile;
    }
  }
  //.container{
  //  max-width: 1500px !important;
  //}
  .diagram-box{
    --diagram-gap: 30px;
    @media screen and (max-width: 1199px) {
      --diagram-gap: 20px;
    }
    @media screen and (max-width: 767px) {
      gap: 14px;
    }
    gap: var(--diagram-gap);
    align-items: stretch;
    &.blocks-5{
      .diagram-image{
        .vertical-line{
          margin-top: calc(-2 * var(--diagram-gap));
          transform: translate(-50%, 0%);
        }
        .bottom-right-line,
        .bottom-left-line{
          display: none;
        }
      }
    }
    &.blocks-6{
      .diagram-image{
        .vertical-line{
          display: none;
        }
      }
      .diagram-card{
        &:nth-of-type(-n+2),
        &:nth-of-type(n+6){
          flex-basis: calc(50% - var(--diagram-gap) / 2);
        }
      }
    }
    &.blocks-7{
      .diagram-image{
        .vertical-line{
          margin-top: calc(-2 * var(--diagram-gap));
          transform: translate(-50%, 0%);
        }
      }
      .diagram-card{
        &:nth-of-type(n+7){
          flex-basis: calc(50% - var(--diagram-gap) / 2);
        }
      }
    }
    .diagram-image{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-basis: calc(33.33% - 20px);
      position: relative;
      z-index: 0;
      @media screen and (max-width: 1024px) {
        flex-basis: calc(33.33% - 13.33px);
      }
      @media screen and (max-width: 767px) {
        display: none;
      }
      .vertical-line{
        position: absolute;
        z-index: -1;
        background: #D6D6D6;
        width: 1px;
        height: calc(100% + (2 * var(--diagram-gap)));
        bottom: 50%;
        left: 50%;
        transform: translate(-50%, 50%);
      }
      .horisontal-line{
        position: absolute;
        z-index: -1;
        background: #D6D6D6;
        height: 1px;
        width: calc(100% + (2 * var(--diagram-gap)));
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .top-left-line{
        position: absolute;
        z-index: -1;
        height: calc(50% + var(--diagram-gap));
        width: calc(50% + var(--diagram-gap) * 2);
        bottom: 50%;
        right: 50%;
        &:before{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: var(--diagram-gap);
          height: calc(var(--diagram-gap) / 2);
          border-bottom-left-radius: 20px;
          border-bottom: 1px solid #D6D6D6;
          border-left: 1px solid #D6D6D6;
        }
        &:after{
          content: '';
          position: absolute;
          top: calc(var(--diagram-gap) / 2 - 1px);
          bottom: 0;
          right: calc(var(--diagram-gap) / 2);
          width: calc(var(--diagram-gap) / 2);
          border-top-right-radius: 20px;
          border-top: 1px solid #D6D6D6;
          border-right: 1px solid #D6D6D6;
        }
      }
      .top-right-line{
        position: absolute;
        z-index: -1;
        height: calc(50% + var(--diagram-gap));
        width: calc(50% + var(--diagram-gap) * 2);
        bottom: 50%;
        left: 50%;
        &:before{
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          left: var(--diagram-gap);
          height: calc(var(--diagram-gap) / 2);
          border-bottom-right-radius: 20px;
          border-bottom: 1px solid #D6D6D6;
          border-right: 1px solid #D6D6D6;
        }
        &:after{
          content: '';
          position: absolute;
          top: calc(var(--diagram-gap) / 2 - 1px);
          bottom: 0;
          left: calc(var(--diagram-gap) / 2);
          width: calc(var(--diagram-gap) / 2);
          border-top-left-radius: 20px;
          border-top: 1px solid #D6D6D6;
          border-left: 1px solid #D6D6D6;
        }
      }
      .bottom-left-line{
        position: absolute;
        z-index: -1;
        height: calc(50% + var(--diagram-gap));
        width: calc(50% + var(--diagram-gap) * 2);
        top: 50%;
        right: 50%;
        &:before{
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: var(--diagram-gap);
          height: calc(var(--diagram-gap) / 2);
          border-top-left-radius: 20px;
          border-top: 1px solid #D6D6D6;
          border-left: 1px solid #D6D6D6;
        }
        &:after{
          content: '';
          position: absolute;
          bottom: calc(var(--diagram-gap) / 2 - 1px);
          top: 0;
          right: calc(var(--diagram-gap) / 2);
          width: calc(var(--diagram-gap) / 2);
          border-bottom-right-radius: 20px;
          border-bottom: 1px solid #D6D6D6;
          border-right: 1px solid #D6D6D6;
        }
      }
      .bottom-right-line{
        position: absolute;
        z-index: -1;
        height: calc(50% + var(--diagram-gap));
        width: calc(50% + var(--diagram-gap) * 2);
        top: 50%;
        left: 50%;
        &:before{
          content: '';
          position: absolute;
          bottom: 0;
          right: 0;
          left: var(--diagram-gap);
          height: calc(var(--diagram-gap) / 2);
          border-top-right-radius: 20px;
          border-top: 1px solid #D6D6D6;
          border-right: 1px solid #D6D6D6;
        }
        &:after{
          content: '';
          position: absolute;
          bottom: calc(var(--diagram-gap) / 2 - 1px);
          top: 0;
          left: calc(var(--diagram-gap) / 2);
          width: calc(var(--diagram-gap) / 2);
          border-bottom-left-radius: 20px;
          border-bottom: 1px solid #D6D6D6;
          border-left: 1px solid #D6D6D6;
        }
      }
    }
    .diagram-card{
      background-color: #ffffff;
      box-shadow: 5px 10px 20px 5px rgba(0, 0, 0, 0.03);
      //padding: 37px 44px 46px;
      padding: 25px 25px 25px;
      border-radius: 20px;
      flex-basis: calc(33.33% - 20px);
      position: relative;
      z-index: 1;
      @media screen and (max-width: 1199px) {
        //padding: 22px 30px 22px;
        padding: 22px 22px 22px;
        flex-basis: calc(33.33% - 13.33px);
      }
      @media screen and (max-width: 767px) {
        flex-basis: 100%!important;
      }
      &.active{
        .card-toggle-button{
          background-image: url("data:image/svg+xml,%3Csvg width='19' height='12' viewBox='0 0 19 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.17188 11L9.17187 2.17241L17.1719 11' stroke='%23FFD36A' stroke-width='3' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        }
      }
      .card-title{
        //font-size: 20px;
        font-size: 18px;
        font-weight: 700;
        line-height: 1.5;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
        margin-bottom: 0;
        color: $c_text_100;
        @media screen and (max-width: 1199px) {
          font-size: 18px;
        }
      }
      .card-toggle-button{
        cursor: pointer;
        display: none;
        width: 16px;
        min-width: 16px;
        height: 9px;
        background-image: url("data:image/svg+xml,%3Csvg width='19' height='12' viewBox='0 0 19 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.17188 1.17188L9.17187 9.99946L17.1719 1.17188' stroke='%23FFD36A' stroke-width='3' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-size: auto;
        background-position: center;
        background-repeat: no-repeat;
        transition: all 0.4s ease;
        @media screen and (max-width: 767px) {
          display: block;
        }
      }
      .card-text{
        //margin-top: 21px;
        margin-top: 15px;
        @media screen and (max-width: 767px) {
          display: none;
          border-top: 1px solid #ECECEC;
          //padding-top: 21px;
          padding-top: 15px;
        }
      }
      li{
        margin-bottom: 5px;
        line-height: 1.5;
        position: relative;
        padding-left: 12px;
        font-size: 14px;
        &:before{
          content: '';
          position: absolute;
          top: 9px;
          left: 0;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: #000;
        }
      }
    }
  }
}
.o-banner {
  position: relative;
  padding: 170px 0 210px;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    padding: 0 0 120px;
  }

  &__illustration {
    position: absolute;
    top: 0;
    right: 0;
    width: 48%;

    @media (max-width: 767px) {
      position: relative;
      margin-left: auto;
      width: 92%;
    }
  }

  &__title {
    margin-bottom: 30px;

    @media (max-width: 767px) {
      margin-bottom: 16px;
    }
  }

  &__description {
    margin-bottom: 30px;
  }
  &__awards {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 30px 0 -24px;
    gap: 24px;
    @media (max-width: 991px) {
      margin: 80px 0 0 0;
      gap: 15px;
    }
    @media (max-width: 767px) {
      margin: 64px 0 0 0;
    }
  }

  &__awards-item {
    a {
      position: relative;
    }

    img {
      height: 100px;
      width: auto;
      max-width: 100px;
      object-fit: contain;
    }
  }

  .mod-desktop {
    @media (max-width: 767px) {
      display: none;
    }
  }

  .mod-mobile {
    @media (min-width: 768px) {
      display: none;
    }
  }
}
.banner-section{
  @media (max-width: 1200px) {
    margin-bottom: 60px;
  }
  >.container{
    @media (min-width: 991px) {
      max-width: 1920px!important;
    }
  }
  .alc{
    @media (max-width: 991px) {
      flex-direction: column-reverse;
    }
  }
  .post-video-wrap,
  .banner-content-wrap,
  .banner-image-wrap{
    flex-basis: 50%;
    @media (max-width: 991px) {
      flex-basis: 100%;
    }
  }
  .post-video-wrap{
    display: flex;
    justify-content: center;
    .post-video-link{
      position: relative;
      display: block;
      max-width: 600px;
      margin: 0 30px;
      @media (max-width: 991px) {
        margin: 0;
      }
      svg{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 0;
        @media screen and (max-width: 767px) {
          width: 56px;
          height: 56px;
        }
        path{
          transition: all 0.4s ease;
        }
      }
      &:hover{
        svg{
          path{
            fill: $c_orange;
          }
        }
      }
    }
    .o-single__featured-image{
      border-radius: 12px;
      overflow: hidden;
      border: 1px solid #f0f0f0;
    }
  }
  .banner-content{
    margin-left: auto;
    max-width: 620px;
    .banner-awards-list{
      padding-top: 0;
      margin-bottom: 40px;
    }
    .o-banner__awards{
      @media (max-width: 767px) {
        flex-wrap: wrap;
        .o-banner__awards-item{
          flex-basis: 50%;
          margin-bottom: 20px;
          text-align: center;
        }
      }
    }
  }
}
.video-banner{
  padding: 150px 0 80px;
  @media screen and (max-width: 991px) {
    padding: 90px 0 $space_tablet;
  }
  @media screen and (max-width: 767px) {
    padding: 70px 0 $space_mobile;
  }
  .video-banner-content{
    flex: 1;
    max-width: 510px;
    margin-right: 60px;
    @media screen and (max-width: 991px) {
      margin-right: 0;
      margin-bottom: 40px;
      max-width: 100%;
    }
    h1{
      font-size: 36px;
      margin-bottom: 30px;
    }
    .video-banner-description{
      margin-bottom: 40px;
      display: block;
    }
  }
  .video-banner-video{
    flex-basis: 49%;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    @media screen and (max-width: 991px) {
      flex-basis: 100%;
    }
    img{
      width: 100%;
    }
    svg{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      z-index: 0;
      path{
        transition: all 0.4s ease;
      }
    }
    &:hover{
      svg{
        path{
          fill: $c_orange;
        }
      }
    }
  }
}
.numbers-list{
  padding: $space_desktop 0;
  background: #FBFBFB;
  margin-bottom: $space_desktop;
  @media screen and (max-width: 991px) {
    padding: $space_tablet 0;
    margin-bottom: $space_tablet;
  }
  @media screen and (max-width: 767px) {
    padding: $space_mobile 0;
    margin-bottom: $space_mobile;
  }
  &:has(> .container:empty){
    display: none;
  }
  h2{
    text-align: center;
    margin-bottom: $title_s_desktop;
    @media screen and (max-width: 767px) {
      margin-bottom: $title_s_tablet;
    }
  }
  .container>p{
    max-width: 745px;
    margin: 0 auto $space_desktop;
    text-align: center;
    font-size: 14px;
    @media screen and (max-width: 991px) {
      margin: 0 auto $space_tablet;
    }
  }
  .numbers-items{
    gap: 40px 30px;
    justify-content: flex-start;
    @media screen and (max-width: 991px) {
      gap: 30px;
    }
    .numbers-item{
      flex-basis: calc(30% - 20px);
      display: flex;
      gap: 20px;
      @media screen and (max-width: 991px) {
        flex-basis: calc(50% - 15px);
      }
      @media screen and (max-width: 767px) {
        flex-basis: 100%;
      }
      mark{
        background: transparent;
      }
      .number{
        min-width: 54px;
        text-align: center;
        color: $c_yellow_medium;
        font-size: 44px;
        font-weight: 700;
        line-height: 140%;
        @media screen and (max-width: 991px) {
          font-size: 32px;
        }
      }
      .title{
        color: $c_text_100;
        font-size: 20px;
        font-weight: 700;
        line-height: 150%;
        margin-bottom: 10px;
        @media screen and (max-width: 991px) {
          font-size: 18px;
        }
      }
      p{
        font-size: 14px;
      }
    }
  }
  &.two-columns{
    .numbers-item{
      flex-basis: calc(50% - 20px);
      @media screen and (max-width: 991px) {
        flex-basis: calc(50% - 15px);
      }
      @media screen and (max-width: 767px) {
        flex-basis: 100%;
      }
    }
  }
}
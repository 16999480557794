.two-columns-and-title{
  margin-bottom: $space_desktop;
  @media (max-width: 991px) {
    margin-bottom: $space_tablet;
  }
  @media (max-width: 767px) {
    margin-bottom: $space_mobile;
  }
  mark{
    background: transparent;
    color: $c_orange;
  }
  h2{
    text-align: center;
    margin-bottom: 44px;
  }
  .two-columns-description{
    padding: 0 40px;
    margin-bottom: 44px;
    a{
      color: $c_orange;
    }
  }
  .columns-wrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .column-item{
      flex-basis: 47.5%;
      margin-bottom: 24px;
      @media (max-width: 767px) {
        flex-basis: 100%;
      }
    }
  }
}
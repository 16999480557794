.post-type-archive-portfolio {
  .alc{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  .flex{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .tac{
    text-align: center;
  }
  .breadcrumbs{
    margin-top: 90px;
    margin-bottom: $space_mobile;
  }
  .banner-section{
    margin-bottom: $space_desktop;
    @media screen and (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: $space_mobile;
    }
  }
  .o-projects__item {
    margin-bottom: 40px;
    gap: 40px;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 20px;
    @media screen and (max-width: 767px) {
      margin-bottom: $space_mobile;
      flex-direction: column;
      gap: 20px;
    }
    &:last-of-type,
    &.last {
      border-bottom: none;
    }

    &-title {
      cursor: default;
    }
  }

  .col-text {
    flex: 1;
    h3{
      margin-bottom: 20px;
    }
    p.o-projects__item-excerpt{
      margin-bottom: $title_s_desktop;
      @media screen and (max-width: 767px) {
        margin-bottom: $title_s_tablet;
      }
    }
  }

  .col-img {
    flex-basis: 42%;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      height: 400px;
      object-fit: contain;
    }
  }
}

.a-projects {
  &__top {
    padding: 190px 0 74px;
    text-align: center;
    @media (max-width: 767px) {
      padding: 100px 0 40px;
    }
  }
  &__title {
    margin-bottom: 20px;
  }

  &__filter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 50px;

    li {
      padding: 0 10px;
      margin-bottom: 20px;
    }
  }

  &__filter-button {
    position: relative;
    font-size: 20px;
    font-weight: 700;
    color: rgba($c_text_100, 0.4);
    padding: 0;
    border: 0;
    outline: none !important;
    background: transparent;
    transition: 0.3s all;

    @media (max-width: 767px) {
      font-size: 18px;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: -8px;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: $c_orange;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s all;
    }

    &.is-checked,
    &:hover {
      color: $c_text_100!important;
    }

    &.is-checked {
      &::before {
        opacity: 1;
        visibility: visible;
      }
    }
    &.yellow{
      color: $c_orange;
    }
  }

  &__feedback {
    display: flex;
    align-items: center;
    //margin-top: 100px;
    margin-top: 20px;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 40px;
    }
  }

  &__feedback-info {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-right: 58px;

    @media (max-width: 767px) {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }

  &__feedback-client-photo {
    width: 72px;
    height: 72px;
    margin-right: 24px;
    border-radius: 50%;
  }

  &__feedback-client-info {
    max-width: 200px;
  }
  &__feedback-text {
    position: relative;
    padding-left: 50px;

    svg {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__divider {
    padding-top: $space_desktop;
    @media screen and (max-width: 991px) {
      padding-top: $space_tablet;
    }
    @media (max-width: 767px) {
      padding-top: $space_mobile;
    }
  }
}

.tabs-block .tab-content{
  display: none;
  &.active{
    display: inherit;
  }
}
.portfolio-swiper .swiper-pagination{
  display: none;
}
.o-thank-you{
  padding: 105px 0 75px;
  @media (max-width: 767px) {
    padding: 75px 0;
  }
  .flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .text{
      flex-basis: 46%;
      a{
        color: $c_orange;
      }
      @media (max-width: 767px) {
        flex-basis: 100%;
        margin-bottom: 20px;
      }
      ul{
        margin-top: 10px;
        li{
          position: relative;
          padding-left: 15px;
          margin-bottom: 10px;
          &:before{
            content: '';
            position: absolute;
            width: 6px;
            height: 6px;
            background: $c_yellow_medium;
            border-radius: 50%;
            top: 9px;
            left: 0;
          }
        }
      }
    }
    .image{
      flex-basis: 46%;
      @media (max-width: 767px) {
        flex-basis: 100%;
      }
    }
  }
}
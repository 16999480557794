.o-projects {
  margin-bottom: $space_desktop;

  @media (max-width: 991px) {
    margin-bottom: $space_tablet;
  }

  &__title {
    text-align: center;
    margin-bottom: $title_s_desktop;

    @media (max-width: 767px) {
      text-align: left;
    }
  }

  &__description {
    text-align: center;
    max-width: 670px;
    margin: 0 auto;
    margin-bottom: $title_s_desktop;
    @media (max-width: 767px) {
      text-align: left;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__item {
    align-items: center;

    @media (max-width: 767px) {
      flex-direction: column;

      .col-img {
        order: 1;
      }
      .col-text {
        order: 2;
      }
      .col-feedback {
        order: 3;
      }
      .home-feedback {
        margin-top: 20px;
      }
    }

    img {
      padding-left: 50px;

      @media (max-width: 767px) {
        padding-left: 0;
      }
    }

    &.odd {
      flex-direction: row !important;

      img {
        padding-left: 50px !important;
        padding-right: 0 !important;
      }
    }

    &:nth-of-type(2n) {
      @media (min-width: 768px) {
        flex-direction: row-reverse;
      }

      img {
        padding-right: 50px;
      }
    }

    &.even {
      @media (min-width: 768px) {
        flex-direction: row-reverse !important;
      }
    }

    &:not(:last-of-type) {
      margin-bottom: $space_desktop;
      @media screen and (max-width: 991px) {
        margin-bottom: $space_tablet;
      }
    }
  }

  &__item-title {
    transition: all 0.4s ease;
    cursor: default;
    a {
      font-weight: bold;
      line-height: 1.4;
      color: $c_text_100;
      transition: all 0.4s ease;


      &:hover {
        color: $c_orange;
      }
    }
  }

  &__item-tags {
    margin: 0 -12px 20px;
  }

  &__item-tag {
    display: inline-block;
    font-weight: 600;
    color: $c_orange;
    padding: 0 12px;

    @media (max-width: 991px) {
      margin-bottom: 16px;
    }

    &:hover {
      color: $c_orange;
    }
  }
  &__item-excerpt {
    margin-bottom: 24px;
  }
  .col-mobile-url{
    @media (max-width: 767px) {
      text-align: center;
    }
    a{
      @media (max-width: 767px) {
        margin-bottom: 24px;
      }
    }
  }
  &__item-permalink {}

  &__link {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 700;
    color: $c_text_100;
    margin: $space_desktop auto 0;

    @media (max-width: 991px) {
      margin-top: $space_tablet;
    }

    &:hover {
      color: $c_text_100;

      svg {
        left: 16px;
      }
    }

    svg {
      margin-left: 16px;
      transition: all 0.4s ease;
      position: relative;
      left: 0;
    }
  }

  &__divider {
    padding-top: $space_desktop;
    @media (max-width: 991px) {
      padding-top: $space_tablet;
    }
  }
}
.o-whitepaper{
  padding-top: 104px;
  margin-bottom: $space_desktop;
  @media screen and (max-width: 991px) {
    margin-bottom: $space_tablet;
  }
  .breadcrumbs{
    margin-bottom: 44px;
  }
  .o-single__title{
    margin-bottom: 44px;
  }
  .o-single__content ul{
    margin-left: 20px;
    margin-bottom: 10px;
    @media screen and (max-width: 768px) {
      margin-bottom: 15px;
    }
  }
  .o-single__content ul li{
    margin-bottom: 0;
  }
  .resources{
    margin-top: $space_tablet;
    h2,h3{
      margin-bottom: 30px;
    }
    .whitepaper-resources{
      display: flex;
      flex-wrap: wrap;
      //gap: 30px;
      margin-bottom: $space_desktop;
      @media screen and (max-width: 991px) {
        margin-bottom: $space_tablet;
      }
      .resource-item{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;
        flex-basis: calc(20% - 30px);
        @media screen and (max-width: 767px) {
          flex-basis: calc(50% - 15px);
        }
        img{
          max-height: 100%;
          object-fit: contain;
        }
      }
      a.resource-item{
        transition: all 0.4s ease;
        opacity: 1;
        &:hover{
          opacity: 0.7;
        }
      }
    }
  }
  .related-materials{
    h2{
      margin-bottom: 30px;
    }
    .related-materials-wrap{
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      .related-materials-item{
        border-radius: 20px;
        border: 1px solid rgba(0, 0, 0, 0.10);
        padding: 30px;
        flex-basis: 31.6%;
        @media screen and (max-width: 1200px) {
          flex-basis: calc(50% - 15px);
        }
        @media screen and (max-width: 767px) {
          flex-basis: 100%;
        }
        >a{
          font-size: 20px;
          font-weight: 700;
          line-height: 140%;
          margin-bottom: 12px;
          color: $c_text_100;
        }
        .o-single__info{
          margin-bottom: 0;
          .o-single__info-date{
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .mockup-image{
    -webkit-box-shadow: 4px 6px 26px -7px rgba(0,0,0,0.6);
    -moz-box-shadow: 4px 6px 26px -7px rgba(0,0,0,0.6);
    box-shadow: 4px 6px 26px -7px rgba(0,0,0,0.6);
    margin-bottom: $space_desktop;
    @media screen and (max-width: 991px) {
      text-align: center;
    }
    @media screen and (max-width: 767px) {
      margin-top: $space_tablet;
      margin-bottom: 0;
    }
    img{
      width: 100%;
    }
  }
  .whitepaper-form{
    border-radius: 15px;
    background: #F8F8F8;
    padding: 30px 30px 40px 30px;
    text-align: center;
    @media screen and (max-width: 992px) {
      margin-bottom: 40px;
    }
    .download-whitepaper-title{
      font-size: 26px;
      font-weight: 700;
      line-height: 140%;
      margin-bottom: 24px;
      display: block;
      color: $c_text_100;
    }
    .wpcf7-form-control-wrap{
      margin-bottom: 24px;
      display: block;
      input{
        border-radius: 12px;
        border: 1px solid rgba(0, 0, 0, 0.10);
        background: #fff;
        padding: 17px 32px 16px 32px;

        color: $c_text_80;
        font-size: 16px;
        font-weight: 600;
        line-height: 150%;
      }
    }
    .wpcf7-submit{
      border-radius: 8px;
      background: $c_orange;
      display: inline-block;
      text-align: center;
      padding: 12px 24px 10px 24px;
      border: none;
      outline: none;
      margin: 0 auto;

      color: #FFF;
      font-size: 16px;
      font-weight: 700;
      transition: all 0.4s ease;
      opacity: 1;
      &:hover{
        opacity: 0.7;
      }
    }
    .wpcf7-spinner{
      display: none!important;
    }
  }
  .js-career-ty{
    padding: 24px 10px;
    text-align: center;
    display: none;
  }
  .whitepaper-form-file{
    opacity: 0;
    visibility: hidden;
  }
}
.hero-small-image{
  margin-bottom: $space_desktop;
  @media screen and (max-width: 991px) {
    margin-bottom: $space_tablet;
  }
  .flex{
    gap: 76px;
  }
  .hero-text{
    flex: 1;
    max-width: 860px;
    h1{
      color: $c_text_100;
      font-size: 32px;
      font-weight: 700;
      line-height: 140%;
      margin-bottom: 30px;
      @media screen and (max-width: 991px) {
        font-size: 28px;
        margin-bottom: 10px;
      }
    }
    p{
      font-size: 14px;
      margin-bottom: 30px;
    }
    .banner-awards-list{
      padding-top: 0;
      margin-bottom: 30px;
    }
  }
  .hero-illustration{
    border-radius:18px;
    overflow: hidden;
    max-width: 250px;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}
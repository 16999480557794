.o-posts {
  &__title {
    text-align: center;
    margin-bottom: 40px;
  }

  &__description {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;
  }

  &__link {
    font-weight: 600;
    color: $c_orange;
    border-bottom: 1px solid $c_orange;
    transition: 0.3s all;

    &:hover {
      color: $c_yellow_medium;
      border-color: $c_yellow_medium;
    }
  }

  &__list {
    display: flex;
    justify-content: center;
  }

  &__item {
    position: relative;
    width: calc(100% / 3);
    padding-bottom: 21.5%;

    @media (max-width: 991px) {
      width: 100%;
      padding-bottom: 73.5%;
    }

    @media (max-width: 767px) {
      padding-bottom: 65%;
    }

    @media (max-width: 575px) {
      padding-bottom: 100%;
    }

    @media (min-width: 992px) {
      &:hover {
        img {
          width: 72px;
          height: 72px;
        }

        .o-posts__item-info-right {
          padding-left: 24px;
        }

        .o-posts__item-info {
          background-color: rgba($c_white, 0.9);
        }

        .o-posts__item-title {
          font-size: 24px;
          font-weight: 700;
          color: $c_text_100;
        }

        .o-posts__item-author-name {
          color: $c_text_100;
        }

        .o-posts__item-date {
          color: $c_text_80;
        }
      }
    }

    img {
      width: 0;
      height: 0;
      border-radius: 50%;
      transition: 0.3s ease-out;
    }
  }

  @media (max-width: 991px) {
    .swiper-slide-active {
      .o-posts__item {
        img {
          width: 72px;
          height: 72px;

          @media (max-width: 767px) {
            width: 48px;
            height: 48px;
          }
        }

        .o-posts__item-info-right {
          padding-left: 24px;
        }

        .o-posts__item-info {
          background-color: rgba($c_white, 0.9);
        }

        .o-posts__item-title {
          font-size: 24px;
          font-weight: 700;
          color: $c_text_100;

          @media (max-width: 767px) {
            font-size: 16px;
          }
        }

        .o-posts__item-author-name {
          color: $c_text_100;
        }

        .o-posts__item-date {
          color: $c_text_80;
        }
      }
    }
  }

  &__item-info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 24px;
    background-color: rgba(54, 54, 69, 0.8);
    backdrop-filter: blur(14px);
    transition: 0.3s ease-out;
  }

  &__item-title {
    font-size: 16px;
    font-weight: 600;
    color: $c_white;
    margin-bottom: 16px;
    transition: 0.3s ease-out;
  }

  &__item-info-bottom {
    display: flex;
    align-items: center;
  }

  &__item-info-right {
    padding-left: 0;
    transition: 0.3s ease-out;
  }

  &__item-author-name {
    line-height: 1;
    color: $c_white;
    transition: 0.3s ease-out;
  }

  &__item-date {
    line-height: 1;
    color: rgba($c_white, 0.5);
    transition: 0.3s ease-out;
  }

  &__item-permalink {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .mod-desktop {
    @media (max-width: 991px) {
      display: none;
    }
  }

  .mod-mobile {
    @media (min-width: 992px) {
      display: none;
    }
  }
}
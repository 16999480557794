.o-vacancies {
  margin-bottom: $space_desktop;

  @media (max-width: 1199px) {
    margin-bottom: $space_tablet;
  }
  @media (max-width: 767px) {
    margin-bottom: $space_mobile;
  }

  &__title {
    text-align: center;
    margin-bottom: $title_s_desktop;
  }
  &__description {
    margin-bottom: $title_s_desktop;
  }
  &__item {
    margin-bottom: 30px;
  }
  &__item-inner {
    height: 100%;
    padding: 32px;
    border: 1px solid rgba($c_black, 0.1);
    border-radius: 12px;
  }
  &__item-title {
    margin-bottom: 24px;
  }
  &__item-tags {
    padding-left: 6px;
    margin-bottom: 24px;

    li {
      position: relative;
      padding-left: 10px;
      &::before {
        position: absolute;
        top: 6px;
        left: -6px;
        background-color: $c_orange;
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
      }
    }
  }
  &__item-link {
    text-decoration: underline;
    color: $c_yellow_medium;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    &:hover {
      text-decoration: underline;
      color: $c_yellow_medium;
    }
  }
}
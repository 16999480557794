.o-contact-us {
  .breadcrumbs{
    margin-top: 90px;
  }
  &__form {
    padding: $space_desktop 0;

    @media (max-width: 991px) {
      padding: $space_tablet 0;
    }
    @media (max-width: 767px) {
      padding: $space_mobile 0;
    }
  }
  &__form-title {
    margin-bottom: 30px;
  }
  &__form-description {
    @media (max-width: 991px) {
      margin-bottom: 40px;
    }
  }
  &__form-item {
    position: relative;
  }
  &__form-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    min-height: 100%;
    background-color: $c_white;
    transform: translateY(-200%);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s all;
    z-index: 1;

    &.is-visible {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
    }

    .close-popup {
      padding: 0;
      border: 0;
      outline: none;
      background: transparent;
      position: absolute;
      top: 2px;
      right: 0;
    }

    img {
      max-width: 200px;
      margin-bottom: 32px;
    }
  }
  &__form-popup-msg {
    font-size: 24px;
    font-weight: 700;
    color: $c_text_100;
    max-width: 330px;
    margin-bottom: 32px;

    div p {
      display: inline;
    }

    .potential-client-name {
      color: $c_orange;
    }
  }
  &__awards {
    margin-bottom: $space_desktop;

    @media (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    @media (max-width: 767px) {
      margin-bottom: $space_mobile ;
    }

    .o-banner__awards {
      margin-top: 0;
      @media (min-width: 992px) {
        justify-content: center;
      }
    }
  }
  &__cta {
    margin-bottom: $space_desktop;
    @media (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    @media (max-width: 767px) {
      margin-bottom: $space_mobile ;
    }
  }
  &__cta-item {
    position: relative;
    padding: 56px 80px;
    border-radius: 30px;
    overflow: hidden;
    color: $c_white;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, rgba(9, 72, 253, 0.6) 0.94%, rgba(9, 253, 33, 0) 100%);
      z-index: 0;
    }
  }
  &__cta-title {
    position: relative;
    color: $c_white;
    margin-bottom: 8px;
  }
  &__cta-description {
    position: relative;
    max-width: 540px;
    margin-bottom: 24px;
  }
  &__cta-button {
    border-radius: 30px;
  }
  &__contacts {
    margin-bottom: $space_desktop;

    @media (max-width: 991px) {
      margin-bottom: $space_tablet;
    }

    @media (max-width: 767px) {
      margin-bottom: $space_mobile;
    }
  }
  &__contacts-item {
    margin-bottom: $space_desktop;

    @media (max-width: 991px) {
      margin-bottom: $space_tablet;
    }

    @media (max-width: 767px) {
      margin-bottom: $space_mobile;
    }
  }
  &__contacts-item-title {
    margin-bottom: 16px;
    line-height: 1;
  }
  &__contacts-item-email {
    font-size: 24px;
    font-weight: bold;
    text-decoration: underline;
    line-height: 1;
    color: $c_orange;
    margin-bottom: 16px;
    display: block;
    cursor: pointer;
    position: relative;
    &:hover {
      color: $c_orange;
      text-decoration: underline;
    }
    .tooltip-text {
      width: 120px;
      background-color: $c_orange;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      position: absolute;
      z-index: 1;
      bottom: 150%;
      left: 0;
      margin-left: 60px;
      font-size: 14px;
      font-weight: 400;
      pointer-events: none;
      cursor: default;
      &:after{
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: $c_orange transparent transparent transparent;
      }
    }
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
  &__contacts-item-description {
    max-width: 320px;

    a {
      text-decoration: underline;
      color: $c_orange;

      &:hover {
        color: $c_orange;
        text-decoration: underline;
      }
    }
  }
  &__contacts-divider {}
  &__location {
    margin-bottom: 20px;
  }
  &__location-title {
    text-align: center;
    margin-bottom: 40px;

    @media (max-width: 991px) {
      margin-bottom: 24px;
    }
  }
  &__location-description {
    text-align: center;
    max-width: 670px;
    margin: 0 auto;

    p:not(:last-of-type) {
      margin-bottom: 20px;

      @media (max-width: 991px) {
        margin-bottom: 12px;
      }
    }
  }
  &__location-image {
    margin-top: -110px;

    @media (max-width: 991px) {
      margin-top: -50px;
    }
  }
  &__persons {
    margin-bottom: $space_desktop;

    @media (max-width: 991px) {
      margin-bottom: $space_tablet;
    }

    @media (max-width: 767px) {
      margin-bottom: $space_mobile;
    }
  }
  &__persons-item {
    @media (max-width: 767px) {
      margin-bottom: $space_mobile;
    }
  }
  &__persons-item-flag {
    max-width: 64px;
    margin-bottom: 24px;

    @media (max-width: 767px) {
      max-width: 44px;
      margin-bottom: 16px;
    }
  }
  &__persons-item-location {
    margin-bottom: 24px;

    @media (max-width: 767px) {
      margin-bottom: 16px;
    }
  }
  &__persons-item-person {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }
  &__persons-item-person-email{
    cursor: pointer;
    position: relative;
    &:hover {
      color: $c_orange;
      text-decoration: underline;
    }
    .tooltip-text {
      width: 120px;
      background-color: $c_orange;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      position: absolute;
      z-index: 1;
      bottom: 150%;
      left: 0;
      margin-left: 0;
      font-size: 14px;
      font-weight: 400;
      pointer-events: none;
      cursor: default;
      &:after{
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: $c_orange transparent transparent transparent;
      }
    }
  }
  &__persons-item-person-photo {
    width: 72px;
    height: 72px;
    border: 1px solid #dfdfdf;
    border-radius: 100px;
    overflow: hidden;
    margin-right: 24px;

    @media (max-width: 767px) {
      width: 62px;
      height: 62px;
      margin-right: 16px;
    }
  }
  &__persons-item-person-info {}
  &__persons-item-person-name {
    color: $c_text_100;
    margin-bottom: 4px;

    @media (max-width: 767px) {
      margin-bottom: 0;
    }
  }
  &__persons-item-person-position {}
  &__persons-item-button {
    color: $c_text_100;
    background-color: $c_white;
    border: 2px solid $c_orange;
    padding: 8px 32px 8px 16px;
    border-radius: 12px;
    transition: 0.3s all;

    &:hover {
      background-color: $c_orange;
      color: $c_white;

      path {
        fill: $c_white;
      }
    }

    svg {
      margin-right: 8px;
    }

    path {
      color: $c_text_100;
    }
  }
}
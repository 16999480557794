.footer-with-articles{
  .footer-recent-posts{
    margin-bottom: 20px;
  }
  .o-footer{
    padding: 0 0 32px;
    .o-footer__title{
      display: none;
    }
    .o-footer__description{
      max-width: 100%;
      color: #000;
      font-weight: 700;
      font-size: 20px;
    }
    .o-footer__form-info p{
      color: #000;
    }
    input:not([type="submit"]) {
      box-shadow: 0px 4px 40px rgba(202, 205, 210, 0.4), 4px 4px 12px rgba(208, 218, 226, 0.2), 0px 4px 8px rgba(193, 196, 213, 0.4);
    }
    .o-footer__divider--top{
      display: none;
    }
  }
  #subscribe{
    padding: 80px 0 78px;
    background: #fff;
  }
}

.o-footer {
  padding: 40px 0;
  background-color: #363646;
  overflow: hidden;

  .mailpoetsignup-671 {
    display: none!important;
  }


  a {
    font-weight: 400;
  }

  &__title {
    text-align: center;
    color: $c_white;
    margin-bottom: 20px;
  }

  &__description {
    text-align: center;
    color: $c_white;
    margin: 0 auto 40px;

    @media (max-width: 767px) {
      margin-bottom: 32px;
    }
  }

  &__form {
    margin: 0 auto 26px;
    max-width: 570px;
    br {
      display: none;
    }
    .tnp{
      position: relative;
      max-width: 100%;
    }
    .tnp label{
      color: #fff;
      display: none;
    }
    .tnp-email,
    input:not([type="submit"]) {
      width: 100%;
      height: 55px;
      color: $c_text_100;
      background-color: $c_white!important;
      border-radius: 12px;
      border: 2px solid transparent!important;
      outline: none;
      padding: 19px 172px 16px 32px !important;

      @media (max-width: 767px) {
        padding-right: 60px;
      }

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $c_text_80;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $c_text_80;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        color: $c_text_80;
      }
    }
    .tnp-subscription .tnp-field .tnp-submit,
    button {
      @extend .o-btn;
      @extend .o-btn--primary;
      position: absolute;
      top: 0;
      right: 0;
      height: 55px;
      //border: 0;
      //outline: none;
      //font-weight: 700;
      //color: $c_white;
      //background-color: $c_orange;
      //border-radius: 12px;
      //padding: 0 29px;
      //transition: 0.3s all;
      //width: auto!important;

      //&:hover {
      //  background-color: $c_yellow_medium;
      //}

      //span {
      //  @media (max-width: 767px) {
      //    display: none;
      //  }
      //}

      //@media (max-width: 767px) {
      //  display: flex;
      //  justify-content: center;
      //  align-items: center;
      //  width: 24px;
      //  height: 24px;
      //  padding: 0;
      //  top: 15px;
      //  right: 18px;
      //
      //  &::before {
      //    content: url('../img/bi_arrow-left.svg');
      //    position: absolute;
      //    top: 4px;
      //  }
      //}
    }
    .tnp-subscription .tnp-field .tnp-submit{
      bottom: 0;
      top: auto;
    }

    .ajax-loader {
      position: absolute;
      right: -40px;
      top: 15px;
      margin: 0;
      @media (max-width: 767px) {
        right: 60px;
      }
    }

    .wpcf7-response-output {
      margin: 0 !important;
      padding: 0 !important;
      border: 0 !important;
      color: #fff !important;
      padding-top: 10px !important;
      text-align: center;
    }

    form {
      position: relative;
      &.invalid {
        input:not([type="submit"]) {
          border-color: red;
        }
      }

      p {
        position: relative;
      }
    }
  }

  &__form-info {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -25px;
    justify-content: center;
    p {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0 25px;
      color: $c_white;

      @media (max-width: 767px) {
        margin-bottom: 24px;
      }

      svg {
        margin-right: 14px;
      }
    }
  }

  &__divider {
    border-bottom: 1px solid #676875;
    &--top{
      margin-top: 40px;
    }
    &--bottom {
      margin: 42px 0 32px;

      @media (max-width: 991px) {
        margin-top: 80px;
      }
    }
  }
  .footer-bottom-section{
    padding-top: 60px;
    @media (max-width: 991px) {
      padding-top: 80px;
    }
  }
  &__logo {
    margin-bottom: 40px;

    @media (max-width: 767px) {
      display: none;
    }

    img {
      max-height: 56px;
    }
  }

  &__info-wrapper {
    @media (max-width: 991px) {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px 80px;
    }
  }

  &__info-mobile {
    @media (max-width: 767px) {
      margin-bottom: 48px;
    }
  }

  &__info {
    position: relative;
    padding-bottom: 10px;
    margin-right: -3px;

    //&::before {
    //  content: '';
    //  position: absolute;
    //  left: 0;
    //  right: 0;
    //  bottom: -2px;
    //  height: 2px;
    //  background-color: rgba($c_white, 0.35);
    //
    //  @media (max-width: 991px) {
    //    left: 15px;
    //    right: 15px;
    //  }
    //}

    @media (max-width: 991px) {
      width: 50%;
      margin-right: 0;
      padding: 0 15px 10px;
    }

    &:not(:last-of-type) {
      margin-bottom: 24px;

      @media (max-width: 991px) {
        margin-bottom: 32px;
      }
    }
  }

  &__info-label {
    margin-bottom: 10px;
  }

  &__info-link {
    // font-weight: 700;
    color: $c_white;
    transition: 0.3s all;

    &:hover {
      color: $c_white;
      opacity: 0.5;
    }
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    padding-left: 50px;

    @media (max-width: 991px) {
      padding-left: 0;
    }

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__nav-item {
    .hide_on_mobile {
      @media (max-width: 767px) {
        display: none;
      }
    }
    a {
      &.highlighted {
        color: $c_orange;
        transition: 0.3s all;

        &:hover {
          color: $c_yellow_medium;
        }
      }
    }

    &:not(:last-of-type) {
      @media (max-width: 767px) {
        margin-bottom: 48px;
      }
    }
  }

  &__nav-item-name {
    color: $c_white;
    margin-bottom: 16px;

    @media (max-width: 767px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0;


      svg {
        transition: 0.3s all;
      }

      &.active {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__nav-item-menu {
    @media (max-width: 767px) {
      max-height: 0;
      overflow: hidden;
      padding-top: 0;
      transition: 0.3s all;

      &.opened {
        padding-top: 24px;
        max-height: max-content;
      }
    }

    li {
      &:not(:last-of-type) {
        margin-bottom: 16px;
      }
    }

    a:not(.highlighted) {
      // font-weight: 700;
      color: #848484;
      transition: 0.3s all;

      &:hover {
        color: $c_white;
      }
    }
  }

  &__cta {
    font-weight: 700;
    color: $c_orange;
    margin-top: 48px;

    @media (min-width: 768px) {
      display: none;
    }
  }

  .o-banner__awards {
    @media (max-width: 991px) {
      margin-top: 80px;
    }
  }

  &__awards {
    display: flex;
    align-items: center;
    margin: 24px -24px 0;

    @media (max-width: 991px) {
      margin-top: 80px;
    }

    @media (max-width: 767px) {
      // max-width: 100vw;
      // overflow: auto;
      // margin-left: 0;
      // margin-right: 0;
    }
  }

  &__awards-item {
    padding: 0 24px;

    &:hover {
      .active {
        opacity: 1;
        visibility: visible;
      }
    }

    @media (max-width: 767px) {
      .active {
        opacity: 1;
        visibility: visible;
      }
    }

    a {
      position: relative;
    }

    .active {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s all;

      @media (max-width: 991px) {
        opacity: 1;
        visibility: visible;
      }
    }

    img {
      max-height: 48px;
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 991px) {
      flex-direction: column;
    }
  }

  &__copyright {
    font-weight: 400;
    color: rgba($c_white, 0.5);
  }

  &__additional-nav {
    display: flex;
    align-items: center;
    margin-right: auto;
    margin-left: 12px;

    @media (max-width: 991px) {
      margin: 24px auto;
    }

    li {
      padding: 0 12px;
    }

    a {
      color: $c_orange;
      transition: 0.3s all;

      &:hover {
        color: $c_yellow_medium;
      }
    }
  }

  &__socials {
    display: flex;
    align-items: center;
    margin: 0 -16px;

    li {
      padding: 0 16px;
    }

    a {
      transition: 0.3s all;

      &:hover {
        opacity: 0.8;
      }
    }

    img {
      max-height: 24px;
    }
  }

  .mod-desktop {
    @media (max-width: 767px) {
      display: none;
    }
  }

  .mod-mobile {
    @media (min-width: 768px) {
      display: none;
    }
  }
  .o-banner__awards {
    margin-top: 24px;

    @media (max-width: 991px) {
      margin-top: 80px;
    }
  }

  .o-banner__awards-item {
    img.active {
      @media (max-width: 991px) {
        display: none;
      }
    }
  }
}
.page-template-lp-real-estate{
  p{
    font-size: 16px!important;
  }
  .lp-estate-hero{
    h1{
      font-size: 36px;
    }
  }
  .slider-projects-list{
    .container{
      >p{
        margin-bottom: $title_s_desktop;
        text-align: center;
        @media screen and (max-width: 767px) {
          margin-bottom: $title-s_tablet;
        }
      }
    }
    .swiper-slide{
      justify-content: center;
      .slide-content{
        max-width: 400px;
        text-align: center;
        h3{
          margin-bottom: 20px;
        }
        p{
          text-align: justify;
        }
      }
    }
  }
  .management-team{
    padding: $space_desktop 0;
    background: #363646;
    margin-bottom: $space_desktop;
    *{
      color: #fff;
    }
    .management-team-content{
      h2{
        margin-bottom: $title_s_desktop;
      }
      p{
        margin-bottom: $title_s_desktop;
        font-size: 20px;
      }
    }
    .team-item{
      span{
        font-size: 16px;
        margin-bottom: 20px;
        display: block;
      }
      p{
        text-align: justify;
      }
    }
  }
  .numbers-list{
    margin-bottom: 0;
  }
  form{
    max-width: 570px;
    margin: 0 auto;
    .saleforces-full{
      margin-bottom: 24px;
    }
    .saleforces-form-top{
      margin-bottom: 24px;
      h2{
        margin-bottom: 20px;
      }
      p{
        display: flex;
        align-items: center;
        gap: 24px;
        @media screen and (max-width: 767px) {
          flex-direction: column;
        }
        span{
          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }
      }
    }
    br{
      display: none;
    }
    .wpcf7-form-control-wrap[data-name="text-968"],
    .wpcf7-form-control-wrap[data-name="text-969"],
    .wpcf7-form-control-wrap[data-name="company"],
    .wpcf7-form-control-wrap[data-name="email-156"]{
      margin-bottom: 24px;
      display: block;
    }
    input:not([type="submit"]):not([type="checkbox"]):not([type="file"]) {
      font-size: 14px;
      color: $c_text_100;
      background-color: #fff;
      padding: 18px 32px;
      border: 1px solid rgba(54, 54, 69, 0.1);
      border-radius: 12px;
      width: 100%;
      &::-webkit-input-placeholder { /* Edge */
        color: rgba($c_text_80, 0.5);
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: rgba($c_text_80, 0.5);
      }

      &::placeholder {
        color: rgba($c_text_80, 0.5);
      }

      &:focus {
        border-color: $c_orange;
      }
    }
    textarea {
      font-size: 14px;
      color: $c_text_100;
      background-color: #fff;
      padding: 18px 32px ;
      border: 1px solid rgba(54, 54, 69, 0.1);
      border-radius: 12px;
      height: 150px;
      width: 100%;
      resize: none;
      margin-bottom: 24px;
      display: block;
      &::-webkit-input-placeholder { /* Edge */
        color: rgba($c_text_80, 0.5);
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: rgba($c_text_80, 0.5);
      }

      &::placeholder {
        color: rgba($c_text_80, 0.5);
      }

      &:focus {
        border-color: $c_orange;
      }
    }
    input[type="submit"] {
      line-height: 24px;
      display: inline-block;
      border: none;
      outline: 0;
    }
    .attact-file {
      margin-bottom: 24px;
      position: relative;
      label {
        display: inline-flex;
        cursor: pointer;
        color: $c_text_100;
        font-size: 14px;
        align-items: center;
        &::before {
          content: url('../img/attach.svg');
          height: 24px;
          margin-right: 8px;
        }
      }
      .codedropz-upload-handler{
        display: none;
      }
      .dnd-upload-status{
        .dnd-upload-image{
          display: none;
        }
        .dnd-upload-details{
          padding-left: 0;
          width: 100%;
          .name{
            align-items: center;
            span{
              font-family: 'Mont';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 1;
              color: $c_text_100;
            }
            em{
              font-family: 'Mont';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 1;
              color: $c_orange;
            }
          }
          .remove-file{
            top: 8px;
            span{
              &:after{
                background-image: url('../img/close.png');
              }
            }
          }
          .dnd-progress-bar{
            span{
              background: $c_orange;
            }
          }
        }
      }
      input,
      br {
        display: none;
      }
    }
    .wpcf7-acceptance {
      input {
        display: none;

        &:checked {
          & + .wpcf7-list-item-label {
            &::before {
              animation: none;
            }
            &::after {
              opacity: 1;
            }
          }
        }
      }
      .wpcf7-list-item {
        margin: 0;
      }
      .wpcf7-list-item-label {
        max-width: 280px;
        display: block;
        position: relative;
        color: $c_text_100;
        padding-left: 30px;

        &::before {
          position: absolute;
          top: 14px;
          left: 0;
          content: '';
          display: block;
          width: 18px;
          height: 18px;
          border: 2px solid $c_orange;
          border-radius: 4px;
          box-shadow: 0 0 0 rgba($c_orange, 0.4);
          animation: pulse 2s infinite;
        }

        &::after {
          position: absolute;
          top: 10px;
          left: 4px;
          content: url('../img/acceptance-check.svg');
          opacity: 0;
        }
      }
      a {
        color: $c_orange;

        &:hover {
          color: $c_orange;
        }
      }
    }
    .submit-wrap{
      position: relative;
      @media screen and (max-width: 479px) {
        margin: 15px 0;
      }
      p{
        display: flex;
        justify-content: center;
      }
      .wpcf7-spinner{
        position: absolute;
        top: calc(100% + 10px);
        left: calc(50% - 12px);
        margin: 0;
        @media screen and (max-width: 479px) {
          top: calc(50% - 12px);
          left: calc(100% - 36px);
        }
      }
    }
    .bottom {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .accept {
        max-width: 260px;
        font-size: 14px;
        @media screen and (max-width: 479px) {
          max-width: 100%;
        }
        a {
          font-weight: 600;
          color: #ffb248;
          border-bottom: 1px solid #ffb248;
          transition: .3s all;

          &:hover {
            color: $c_yellow_medium;
            border-color: $c_yellow_medium;
          }
        }
      }

      br {
        display: none;
      }
      .ajax-loader {
        display: none;
      }
    }
    .wpcf7-response-output {
      display: none !important;
    }

    .wpcf7-not-valid {
      border-color: red !important;
    }
    .wpcf7-not-valid-tip{
      text-align: center;
      cursor: pointer;
      padding-top: 16px;
    }
  }
}
.lp-estate-hero{
  background-image: url('../img/lp-real-estate/hero-image.webp');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 140px 0 $space_desktop;
  margin-bottom: 30px;
  @media screen and (max-width: 991px) {
    padding: 140px 0 $space_tablet;
  }
  .container{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 30px;
    @media screen and (max-width: 991px) {
      gap: 40px;
      justify-content: center;
    }
  }
  h1{
    margin-bottom: 20px;
  }
  p{
    font-size: 16px;
  }
  h1, p{
    color: #fff;
  }
  .hero-content{
    flex-basis: calc(50% - 15px);
    @media screen and (max-width: 991px) {
      flex-basis: 100%;
    }
  }
  .hero-form{
    border-radius: 15px;
    background: #fff;
    padding: 40px;
    flex-basis: calc(42% - 15px);
    max-width: 500px;
    @media screen and (max-width: 991px) {
      flex-basis: 100%;
    }
    h3{
      text-align: center;
      margin-bottom: 20px;
    }
  }
}
.lp-technology{
  margin-bottom: 30px;
  .container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
  }
  .logos{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-basis: 100%;
    gap: 15px;
    flex-wrap: wrap;
    .logos-item{
      width: calc(16.66% - 12.5px);
      text-align: center;
      @media screen and (max-width: 991px) {
        width: calc(33.33% - 10px);
      }
      @media screen and (max-width: 479px) {
        width: calc(50% - 15px);
      }
    }
    img{
      height: 50px;
      object-fit: contain;
      max-width: 55%;
      @media screen and (max-width: 767px) {
        max-width: 90%;
      }
    }
  }
}
.lp-technology-content{
  background: #eee;
  padding: $space_desktop 0;
  text-align: center;
  @media screen and (max-width: 991px) {
    margin-bottom: $space_tablet;
  }
  h2{
    margin-bottom: $title_s_desktop;
  }
}
.lp-cta{
  background: #363646;
  text-align: center;
  padding: 35px 0;
  margin-bottom: $space_desktop;
  @media screen and (max-width: 991px) {
    margin-bottom: $space_tablet;
  }
  h2{
    color: #fff;
    margin-bottom: 20px;
  }
}
.horisontal-tabs{
  margin-bottom: $space_desktop;
  @media screen and (max-width: 991px) {
    margin-bottom: $space_desktop;
  }
  h2{
    text-align: center;
    margin-bottom: $title-s_desktop;
    @media screen and (max-width: 767px) {
      margin-bottom: $title-s_tablet;
    }
  }
  .tabs{
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    .tabs-titles{
      width: 40%;
      border-left: 3px solid #F0F0F0;
      @media screen and (max-width: 991px) {
        width: 100%;
        margin-bottom: 30px;
      }
      .title{
        position: relative;
        padding: 8px 20px;
        transition: all 0.4s ease;
        cursor: pointer;

        color: #83838E;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        opacity: 0.4;

        &:before{
          content: '';
          opacity: 0;
          position: absolute;
          left: -3px;
          top: 0;
          bottom: 0;
          width: 3px;
          background: $c_yellow_medium;
        }
        &.active{
          color: #363645;
          opacity: 1;
          &:before{
            opacity: 1;
          }
        }
      }
    }
    .tabs-content{
      width: 60%;
      @media screen and (max-width: 991px) {
        width: 100%;
      }
      .tabs-swiper{
        height: 100%;
      }
      .swiper-slide{
        border-radius: 15px;
        border: 1px solid rgba(0, 0, 0, 0.10);
        padding: 40px 60px;
        font-size: 16px;
        .content-wrap{
          display: flex;
          align-items: center;
          text-align: justify;
        }

      }
      .swiper-pagination{
        display: none;
      }
    }
  }
}
.lp-our-work{
  margin-bottom: $space_desktop;
  @media (min-width: 1500px) {
    .container{
      max-width: 1500px!important;
    }
  }
  h2{
    margin-bottom: $title_s_desktop;
    text-align: center;
  }
  .projects{
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    align-items: stretch;
    .projects-item{
      flex-basis: calc(25% - 22.5px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      text-align: center;
    }
  }
}
.lp-bottom-form{
  padding: $space_desktop 0;
  background: #eee;
  h2{
    margin-bottom: $title_s_desktop;
    text-align: center;
    @media screen and (max-width: 991px) {
      margin-bottom: $title_s_tablet;
    }
  }
}
.oril-popup{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 12;
  background: rgba(0, 0, 0, 0.4);
  justify-content: center;
  align-items: center;
  padding: 15px;
  display: none;
  &.active{
    display: flex;
  }
  .popup-block{
    border-radius: 15px;
    background: #fff;
    padding: 40px;
    max-width: 450px;
    position: relative;
    text-align: center;
    h3{
      margin-bottom: 15px;
    }
    p{
      margin-bottom: 15px;
    }
    .close{
      position: absolute;
      top: 9px;
      right: 9px;
      width: 24px;
      height: 24px;
      cursor: pointer;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAnUlEQVR4nO1UMRKDMAxjpM8h8pDNQ8zRNkB/wP+f0fOxdOhRB2KuA5oySLITO2qaC3+Hrkt3QDIzt1aNckNIYwgy/CQD8gTSQiSzpYhylKsa1Zq6IeqnVZBeMcZbDW6xkPeaWwz4qPmWEdcy/zZEIpk/zyWbVnKTpUrnpxVgzydyHbLrmrLnR2PvqAAku4YdUf/YE9eAZNVaNRfOwxu+6Hp0IBVQaAAAAABJRU5ErkJggg==");
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}
.o-services {
  margin-bottom: $space_desktop;

  @media (max-width: 991px) {
    margin-bottom: $space_tablet;
  }

  &__title {
    text-align: center;
    margin-bottom: $title_s_desktop;
  }

  &__row {
    @media (min-width: 1200px) {
      padding: 0 100px;
    }
  }

  &__item {
    text-align: center;
    margin-bottom: $space_desktop;

    @media (max-width: 991px) {
      margin-bottom: $space_tablet;
    }

    @media (max-width: 767px) {
      margin-bottom: $space_mobile;
    }
  }

  &__item-illustration {
    max-width: 200px;
    margin-bottom: 24px;
  }

  &__item-title {
    margin-bottom: 24px;
  }

  &__item-description {
    padding: 0 40px;

    @media (max-width: 991px) {
      padding: 0;
    }
  }

  &__item-link {
    font-weight: 600;
    color: $c_orange;
    border-bottom: 1px solid $c_orange;
    transition: 0.3s all;

    &:hover {
      color: $c_yellow_medium;
      border-color: $c_yellow_medium;
    }
  }

  &__divider {
    padding-top: $space_desktop;

    @media (max-width: 991px) {
      padding-top: $space_tablet;
    }
    @media (max-width: 767px) {
      padding-top: $space_mobile;
    }
  }
}
.o-industries {
  margin-bottom: $space_desktop;

  @media (max-width: 991px) {
    margin-bottom: $space_tablet;
  }
  @media (max-width: 767px) {
    margin-bottom: $space_mobile;
  }
  &__title {
    text-align: center;
    margin-bottom: $title_s_desktop;
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 -24px 40px;
  }

  &__nav-item {
    position: relative;
    font-size: 24px;
    font-weight: 700;
    padding: 0 24px 12px;
    color: $c_text_100;
    opacity: 0.4;
    transition: 0.3s all;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 24px;
      right: 24px;
      height: 3px;
      background-color: $c_orange;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s all;
    }

    &.active,
    &:hover {
      color: $c_text_100;
      opacity: 1;
    }

    &.active {
      &::before {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__item {
    @media (min-width: 992px) {
      display: none;

      &:first-of-type {
        display: block;
      }
    }

    @media (max-width: 991px) {
      text-align: center;
    }

    @media (max-width: 767px) {
      padding: 0 15px;
    }
  }

  &__item-illustration {
    @media (max-width: 991px) {
      margin-bottom: 40px;
    }
  }

  &__item-title {
    margin-bottom: 24px;

    @media (min-width: 992px) {
      display: none;
    }
  }

  &__item-description {
    margin-bottom: 40px;
  }

  &__divider {
    padding-top: 100px;

    @media (max-width: 991px) {
      padding-top: 60px;
    }
  }

  .mod-desktop {
    @media (min-width: 1200px) {
      padding-left: 100px;
      padding-right: 100px;
    }

    @media (max-width: 991px) {
      display: none;
    }
  }

  .mod-mobile {
    @media (min-width: 992px) {
      display: none;
    }
  }
}
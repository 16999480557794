.single-service {
  .banner-section {
    margin-bottom: $space_desktop;
    @media screen and (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    .alc{
      @media (max-width: 991px) {
        flex-direction: column;
      }
    }
    .banner-content-wrap{
      @media (max-width: 991px) {
        margin-bottom: 30px;
      }
    }
    .banner-content{
      position: relative;
      @media (max-width: 991px) {
        padding-top: 60px;
      }
      .breadcrumbs{
        position: absolute;
        top: 0;
      }
    }
  }

  .o-about {
    text-align: center;
    margin-bottom: 0;
    //@media (max-width: 767px) {
    //  margin-bottom: 100px;
    //}
    &__description{
      margin-bottom: $title_s_desktop;
    }
    &__item{
      flex-direction: column;
      .o-about__item-icon{
        margin-right: 0;

      }
    }
  }

  .o-process {
    &__title {
      @media (max-width: 767px) {
        margin-bottom: 16px;
      }
    }

    &__description {
      @media (max-width: 767px) {
        margin-bottom: $title_s_desktop;
      }
    }
  }
}

.single-service-v2{
  .banner-section {
    padding-top: 60px;
    margin-bottom: 60px;
    .alc{
      @media (max-width: 991px) {
        flex-direction: column;
      }
    }
    .banner-content-wrap{
      @media (max-width: 991px) {
        margin-bottom: 30px;
      }
    }
    .banner-content{
      padding-top: 130px;
      position: relative;
      @media (max-width: 991px) {
        padding-top: 60px;
      }
      .breadcrumbs{
        position: absolute;
        top: 0;
      }
    }
  }
  .o-banner{
    margin-bottom: 0;
  }
  .o-about{
    margin-bottom: $space_desktop;
    @media screen and (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    h2{
      text-align: center;
    }
    .o-about-description{
      margin-bottom: $title_s_desktop;
    }
  }
  .sp-roadmap{
    .o-divider {
      padding-top: $space_desktop;
      @media (max-width: 991px) {
        padding-top: $space_tablet;
      }
    }
  }
  .two-columns-and-title{
    h2{
      margin-bottom: $title_s_desktop;
    }
    .two-columns-description{
      margin-bottom: $title_s_desktop;
    }
    .column-item{
      margin-bottom: 44px;
    }
    h3{
      margin-bottom: 16px;
    }
  }
  .technologies-block{
    .technology-icon{
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      box-sizing: border-box;
      padding: 10px;
    }
  }
}
.service-tpl{
  padding-top: 90px;
  @media screen and (max-width: 991px) {
    padding-top: 80px;
  }
  .breadcrumbs{
    margin-bottom: 30px;
  }
}

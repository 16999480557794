.sp {
  p{
    a{
      color: $c_orange;
    }
  }
  .breadcrumbs{
    margin-top: 90px;
  }
  .banner-section{
    padding: $space_desktop 0;
    @media (max-width: 768px) {
      padding: $space_mobile 0;
    }
    .alc{
      @media (max-width: 991px) {
        flex-direction: column;
      }
    }
    .banner-content-wrap{
      @media (max-width: 991px) {
        margin-bottom: 30px;
      }
    }
    &__illustration {
      display: none;
      top: 90px;
      max-width: 800px;

      @media (max-width: 991px) {
        display: block;
        top: 0;
      }
    }
    .o-banner__tags {
      font-weight: 600;
      color: $c_orange;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 0 -12px;

      p {
        white-space: nowrap;
        padding: 0 12px;
        margin-bottom: 16px;
      }
    }
    .o-banner__description {
      p {
        &:not(:last-of-type) {
          margin-bottom: 30px;
        }
      }
      a{
        transition: all 0.4s ease;
        opacity: 1;
        &:hover{
          opacity: 0.7;
        }
      }
    }
  }

  .download-link{
    padding-top: 36px;
  }

  .portfolio-link{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: $c_text_80;
    margin-bottom: 110px;
    position: relative;
    padding-left: 20px;
    transition: all 0.4s ease;
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      transform: rotate(-45deg);
      border-top: 3px solid $c_text_80;
      border-left: 3px solid $c_text_80;
      border-radius: 2px;
      width: 18px;
      height: 18px;
      transition: all 0.4s ease;
    }
    &:hover{
      color: $c_orange;
      &:before{
        border-color: $c_orange;
      }
    }
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
  }

  &-list-inside {
    ul,
    ol {
      li {
        position: relative;
        padding-left: 18px;

        &:not(:last-of-type) {
          margin-bottom: 16px;
        }

        &::before {
          content: '';
          position: absolute;
          top: 6px;
          left: 0;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: $c_orange;
        }
      }
    }
  }

  &-title {
    text-align: center;
    margin-bottom: $title_s_desktop;
  }

  &-info {
    margin-bottom: $space_desktop;
    @media (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    @media (max-width: 767px) {
      margin-bottom: $space_mobile;
    }

    &__item {
      margin-bottom: 48px;
      max-width: 460px;

      @media (max-width: 767px) {
        margin-bottom: 34px;
      }

      &-label {
        position: relative;
        color: rgba(103, 104, 117, 0.5);
        padding-left: 22px;
        margin-bottom: 6px;

        &::before {
          content: '';
          position: absolute;
          top: 6px;
          left: 0;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: $c_orange;
        }
      }

      &-title {
        font-size: 20px;
        font-weight: 600;
        color: #676875;
      }
    }
  }

  &-challenge {
    margin-bottom: $space_desktop;

    @media (max-width: 1199px) {
      margin-bottom: $space_tablet;
    }

    @media (max-width: 767px) {
      margin-bottom: $space_mobile;
    }

    &__description {
      padding: 0 30px;
      p{
        text-align: center;
        @media (max-width: 767px) {
          text-align: left;
        }
      }
      p,
      ul {
        margin-bottom: 24px;
      }
    }
  }

  &-roadmap {
    margin-bottom: $space_desktop;

    @media (max-width: 1199px) {
      margin-bottom: $space_tablet;
    }

    @media (max-width: 767px) {
      margin-bottom: $space_mobile;
    }

    &__graph {
      position: relative;
      display: flex;
      justify-content: center;
      @media (max-width: 991px) {
        display: none;
      }
      @media (max-width: 767px) {
        margin-bottom: 36px;
      }

      .line {
        display: block;
        position: absolute;
        top: 41px;
        left: auto;
        right: auto;
        height: 2px;
        background-color: $c_orange;
      }

      &-item {
        position: relative;
        text-align: center;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 10px;
          height: 10px;
          background-color: $c_orange;
          transform: translate(-50%, -50%);
        }

        span {
          font-size: 60px;
          line-height: 1.4;
          font-weight: 700;
          color: rgba($c_text_100, 0.06);
          margin-bottom: 4px;
        }
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      padding: 0 15px;
      @media (max-width: 991px) {
        width: 100% !important;
        margin-bottom: 30px;
      }

      h3 {
        text-align: center;
        margin-bottom: 18px;
        min-height: 68px;
        position: relative;

        @media (max-width: 1199px) {
          min-height: 100px;
          font-size: 20px;

        }
        @media (max-width: 991px) {
          font-size: 18px;
          min-height: unset;
        }
        span.number{
          font-size: 60px;
          line-height: 1.4;
          font-weight: 700;
          color: rgba($c_text_100, 0.06);
          position: absolute;
          top: -28px!important;
          left: 0;
          display: none;
          @media (max-width: 991px) {
            display: block;
          }
        }
      }
    }
  }

  &-ux-process {
    margin-bottom: $space_desktop;

    @media (max-width: 1199px) {
      margin-bottom: $space_tablet;
    }

    @media (max-width: 767px) {
      margin-bottom: $space_mobile;
    }

    &__description {
      margin-bottom: 24px;
    }

    &__image {
      margin-top: $space_desktop;
      @media (max-width: 991px) {
        margin-top: $space_tablet;
      }
      @media (max-width: 767px) {
        margin-top: $space_mobile;
      }
    }
  }

  &-ui-process {
    display: flex;
    flex-direction: column;
    margin-bottom: $space_desktop;

    @media (max-width: 1199px) {
      margin-bottom: $space_tablet;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: $space_mobile;
    }

    &__description {
      margin-bottom: $title_s_desktop;
    }

    &__image {
      margin-bottom: 50px;
    }

    &__image-2 {
      margin: 0 auto;

      @media (min-width: 1200px) {
        padding: 0 150px;
      }
    }
  }
  &-mobile-friendly{
    display: flex;
    flex-direction: column;
    margin-bottom: $space_desktop;
    position: relative;

    @media (max-width: 1199px) {
      margin-bottom: $space_tablet;
    }
    .container {
      @media (min-width: 1200px) {
        max-width: 1335px !important;
      }
    }
  }

  &-features {
    margin-bottom: $space_desktop;

    @media (max-width: 1199px) {
      margin-bottom: $space_tablet;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: $space_mobile;
    }
    &__item {
      margin-bottom: $space_desktop;

      @media (max-width: 767px) {
        margin-bottom: $space_tablet;
      }

      &-title {
        position: relative;
        padding-left: 52px;
        margin-bottom: 28px;
        mark{
          background: transparent;
          color: $c_orange;
        }

        span {
          position: absolute;
          top: -30px;
          left: 0;
          display: block;
          font-size: 60px;
          font-weight: 700;
          color: rgba($c_text_100, 0.06);
        }
      }

      &-description {}
    }
    &__image{
      display: block;
      margin: 60px auto 0;
    }
  }

  &-results {
    margin-bottom: $space_desktop;

    @media (max-width: 1199px) {
      margin-bottom: $space_tablet;
    }

    @media (max-width: 767px) {
      margin-bottom: $space_mobile;
    }

    &__description {
      margin-bottom: $title_s_desktop;
      p{
        text-align: center;
        @media (max-width: 767px) {
          text-align: left;
        }
      }
      &-additional {
        text-align: left;
        margin-bottom: 0;
      }
    }

    &__list {
      justify-content: center;
    }

    &__item {
      max-width: 260px;
      text-align: center;
      margin: 0 auto 48px;

      &-title {
        font-size: 80px;
        line-height: 1;
        font-weight: 700;
        color: $c_text_100;
      }

      &-description {}
    }
  }

  &-technologies {
    margin-bottom: $space_desktop;

    @media (max-width: 1199px) {
      margin-bottom: $space_tablet;
    }

    @media (max-width: 767px) {
      margin-bottom: $space_mobile;
    }

    &__list {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0 -22px;
      @media screen and (max-width: 767px) {
        margin: 0;
      }
      &-item {
        text-align: center;
        padding: 0 23px 44px;

        &-icon {
          width: 120px;
          height: 120px;
          border-radius: 50%;
          background-color: $c_white;
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 12px;
          overflow: hidden;
          @media (max-width: 767px) {
            width: 100px;
            height: 100px;
          }

          img {
            max-height: 58px;
            width: auto;

            @media (max-width: 767px) {
              height: 40px;
            }
          }
        }

        &-name {
          color: $c_text_100;
        }
      }
    }
    .integrations{
      text-align: center;
      .integrations-text{
        display: inline;
        position: relative;
        padding-left: 18px;
        &:before{
          content: '';
          position: absolute;
          top: 3px;
          left: 0;
          width: 10px;
          height: 10px;
          background: $c_orange;
          border-radius: 50%;
        }
      }
    }
  }

  &-feedback {
    margin-bottom: $space_desktop;

    @media (max-width: 1199px) {
      margin-bottom: $space_tablet;
    }

    @media (max-width: 767px) {
      margin-bottom: $space_mobile;
    }

    &__description {
      text-align: center;
      margin-bottom: $title_s_desktop;
    }
  }
}

.postid-2746 .sp-roadmap{
  position: relative;
  z-index: 0;
  &:before{
  content: '';
  position:absolute;
  top:-170px;
  right:-188px;
  width: 533px;
  height: 566px;
  background-image: url("../img/coco-roadmap-before.png");
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
  }
  &:after{
  content:'';
  position:absolute;
  bottom:-170px;
  left:0;
  width: 375px;
  height: 265px;
  background-image: url("../img/coco-roadmap-after.png");
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
  }
}
.postid-2726 .sp-ui-process__image{
  margin-bottom: 140px;
}
.postid-2114 .sp-mobile-friendly{
  z-index: 0;
  &:before{
    content: '';
    position: absolute;
    left: 0;
    top: 205px;
    width: 26vw;
    height: 14.7vw;
    background-image: url("../img/mobile-friendly-before.png");
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1;
  }
}
.postid-1852 .sp-mobile-friendly{
  z-index: 0;
  &:before {
    content: '';
    position: absolute;
    right: 0;
    top: -195px;
    width: 27.8vw;
    height: 39.6vw;
    background-image: url("../img/laced-before.png");
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1;
  }
  &:after{
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 14.3vw;
    height: 23.8vw;
    background-image: url("../img/laced-after.png");
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1;
  }
}
.postid-1854 .o-banner{
  background-size: 42% auto;
}

.single-portfolio{
  .banner-section .banner-image-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 550px;
    }
  }
  .paragraph-section{
    margin-bottom: 0;
    background: #fff;
    &.gray{
      background: #fbfbfb;
    }
    &.section-bottom-space{
      margin-bottom: $space_desktop;
      @media screen and (max-width: 991px) {
        margin-bottom: $space_tablet;
      }
      @media screen and (max-width: 767px) {
        margin-bottom: $space_mobile;
      }
    }
  }
  .image-section{
    margin-bottom: $space_desktop;
    @media screen and (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: $space_mobile;
    }
    .container{
      &.small{
        height: 400px;
        img{
          max-height: 100%;
        }
      }
    }
    img{
      margin: 0 auto;
      display: block;
    }
  }
}
.single-video-section{
  margin-bottom: $space_desktop;
  @media screen and (max-width: 767px) {
    margin-bottom: $space_tablet;
  }
  h2{
    text-align: center;
    margin-bottom: $title_s_desktop;
    @media screen and (max-width: 767px) {
      margin-bottom: $title_s_tablet;
    }
  }
}
.video-banner-video{
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  display: block;
  margin: 0 auto;
  max-width: 780px;
  svg{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    @media screen and (max-width: 767px) {
      width: 56px;
      height: 56px;
    }
  }
  img{
    width: 100%;
  }
}
.page-template-lp-vsl{
  &.admin-bar{
    .o-lp-vsl{
      padding-top: 100px;
      @media screen and (max-width: 991px) {
        padding-top: 80px;
      }
    }
  }
}
.o-lp-vsl{
  padding-top: 140px;
  @media screen and (max-width: 991px) {
    padding-top: 120px;
  }
  .video-box{
    position: relative;
    border-radius: 24px;
    overflow: hidden;
    svg{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      @media screen and (max-width: 767px) {
        scale: 0.7;
      }
    }
  }
  p{
    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
  .vsl-hero{
    text-align: center;
    margin-bottom: $space_desktop;
    @media screen and (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    h1{
      font-size: 60px;
      margin-bottom: 12px;
      @media screen and (max-width: 991px) {
        font-size: 28px;
      }
    }
    p{
      margin-bottom: 40px;
    }
    .video-box{
      margin-bottom: 30px;
    }
    .link-wrap{
      text-align: center;
    }
  }
  .free-consultation{
    margin-bottom: 20px;
    //@media screen and (max-width: 991px) {
    //  margin-bottom: $space_tablet;
    //}
    h2{
      font-size: 46px;
      text-align: center;
      margin-bottom: 24px;
      @media screen and (max-width: 991px) {
        font-size: 28px;
      }
    }
    #calendly-embed{
      min-width:320px;
      height:700px;
      @media screen and (max-width: 991px) {
        height:855px;
      }
      @media screen and (max-width: 767px) {
        height:855px;
      }
    }

  }
  .lp-awards .awards-description{
    h3{
      @media screen and (max-width: 991px) {
        font-size: 28px;
      }
    }
    ul{
      margin-top: 10px;
      li{
        position: relative;
        padding-left: 15px;
        margin-bottom: 10px;
        &:before{
          content: '';
          position: absolute;
          width: 6px;
          height: 6px;
          background: $c_yellow_medium;
          border-radius: 50%;
          top: 9px;
          left: 0;
        }
      }
    }
  }
  .lp-video-testimonials{
    padding: $space_desktop 0 0;
    margin-bottom: $space_desktop;
    @media screen and (max-width: 991px) {
      margin-bottom: $space_tablet;
      padding: $space_tablet 0 0;
    }
    .container-1920{
      padding-left: 60px;
      @media screen and (max-width: 991px) {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
    h2{
      text-align: center;
      font-size: 46px;
      margin-bottom: 6px;
      @media screen and (max-width: 991px) {
        font-size: 28px;
      }
    }
    p{
      text-align: center;
      display: block;
      max-width: 600px;
      margin: 0 auto 40px;
    }
    .episodes{
      .swiper-slide{
        width: auto;
        height: 320px;
        display: flex;
        justify-content: center;
        align-items: center;
        a{
          max-width: 580px;
          overflow: hidden;
        }
      }
    }
  }
  .cta-dark .cta-box .cta-title{
    @media screen and (max-width: 991px) {
      font-size: 18px;
    }
  }
}
.o-header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(7px);
  border-bottom: 1px solid #F5F5F5;
  z-index: 11;
  @media (max-width: 991px) {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 71px;
      background-color: $c_white;
      z-index: 1;
      transition: 0.3s all;
      opacity: 0;
      visibility: hidden;
    }

    &.opened {
      &::before {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  @media (max-width: 991px) {
    &::before {
      height: 58px;
    }
  }
  .top-header{
    padding: 12px 0;
  }
  .container {
    @media (max-width: 991px) {
      max-width: unset;
    }
  }

  &__click-area {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 55px;
    z-index: 1111;

    @media (min-width: 992px) {
      display: none;
    }

    input {
      display: none;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    z-index: 1;

    img {
      max-height: 46px;

      @media (max-width: 991px) {
        max-height: 32px;
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
  }

  &__mobile-cta {
    color: $c_text_100;
    font-weight: 700;
    margin-right: 24px;

    &:hover {
      color: $c_text_100;
    }

    @media (min-width: 991px) {
      display: none;
    }
  }

  &__burger {
    width: 24px;
    height: 22px;
    position: relative;
    margin: 0;
    margin-left: auto;
    padding: 0;
    border: 0;
    outline: none !important;
    background-color: transparent;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    z-index: 1;

    &.active {
      span {
        &:nth-child(1) {
          top: 9px;
          transform: rotate(135deg);
        }

        &:nth-child(2) {
          opacity: 0;
          left: -30px;
        }

        &:nth-child(3) {
          top: 9px;
          transform: rotate(-135deg);
        }
      }
    }

    @media (min-width: 992px) {
      display: none;
    }

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: $c_orange;
      border-radius: 10px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;

      &:nth-child(1) {
        top: 0px;
      }

      &:nth-child(2) {
        width: 16px;
        top: 9px;
      }

      &:nth-child(3) {
        top: 18px;
      }
    }
  }

  &__nav {
    display: flex;
    align-items: center;

    &.opened {
      @media (max-width: 991px) {
        transform: translateX(0);
      }
    }

    @media (max-width: 991px) {
      position: fixed;
      top: 0;
      right: 0;
      display: block;
      width: 100%;
      height: 100vh;
      padding: 0;
      overflow: auto;
      background-color: $c_white;
      transform: translateX(100%);
      transition: 0.3s all;
    }

    @media (max-width: 991px) {
      padding: 0 145px;
    }

    @media (max-width: 767px) {
      padding: 0;
    }

    .menu-header-navigation-container {
      @media (max-width: 991px) {
        max-width: 960px;
        margin: 0 auto;
        padding: 160px 15px 0;
      }

      @media (max-width: 767px) {
        padding-top: 108px;
      }
    }

    .menu {
      display: flex;
      align-items: center;
      margin: 0 -24px;

      @media (max-width: 991px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      & > .menu-item {
        padding: 0 24px;
        width: 100%;
        &:not(:last-of-type) {
          @media (max-width: 991px) {
            margin-bottom: 48px;
          }

          @media (max-width: 767px) {
            margin-bottom: 32px;
          }
        }
        &.current-menu-item,
        &.current-menu-parent {
          >a{
            position: relative;
            &::before {
              content: '';
              position: absolute;
              bottom: -24px;
              left: 0;
              right: 0;
              height: 2px;
              background-color: $c_orange;
              @media (max-width: 991px) {
                display: none;
              }
            }
          }
        }
        &.mega-menu-item{
          position: static;
          @media screen and (max-width: 991px) {
            position: relative;
          }
          >.sub-menu-wrap{
            //left: 0;
            //width: 100%;
            right: 0;
            bottom: 12px;
            >.sub-menu{
              display: grid;
              grid-template-columns: 2fr auto;
              grid-template-rows: auto auto auto 1fr;
              grid-column-gap: 70px;
              grid-row-gap: 0px;
              padding: 24px 43px;
              @media screen and (max-width: 991px) {
                display: block;
              }
              li:nth-child(1) { grid-area: 1 / 1 / 5 / 2; }
              li:nth-child(2) { grid-area: 1 / 2 / 2 / 3; }
              li:nth-child(3) { grid-area: 2 / 2 / 3 / 3; }
              li:nth-child(4) { grid-area: 3 / 2 / 4 / 3; }
              li:nth-child(5) { grid-area: 4 / 2 / 5 / 3; }
            }
            li{
              &:before{
                display: none;
              }
            }
            .mega-menu-parent{
              //display: grid;
              //grid-template-columns: 1fr 2fr;
              //grid-template-rows: 1fr;
              //grid-column-gap: 70px;
              //grid-row-gap: 0px;
              border-right: 1px solid #ececec;
              padding-right: 70px;
              @media screen and (max-width: 991px) {
                display: block;
                border-right: none;
              }
              .mob-submenu-toggler{
                display: none;
              }
              .menu-description{
                margin-top: 24px;
                display: block;
                font-weight: 400;
                font-size: 14px;
                line-height: 193%;
                color: $c_text_80;
                pointer-events: none;
                @media screen and (max-width: 1024px) {
                  margin-bottom: 24px;
                }
              }
              .sub-menu-wrap{
                position: static;
                padding-top: 24px;
                transform: translateY(0);
                .sub-menu{
                  min-width: auto;
                  background-color: #fff;
                  border: none;
                  border-radius: 0;
                  padding: 0;
                  backdrop-filter: none;
                  @media screen and (max-width: 991px) {
                    padding: 0 20px 24px;
                  }
                  a{
                    font-weight: 400;
                  }
                }
              }
            }
          }
        }
        & > a {
          @media (max-width: 991px) {
            font-weight: 700;
            font-size: 24px;
            opacity: 1;
            color: $c_text_100;
          }

          @media (max-width: 767px) {
            font-size: 16px;
          }
        }
      }

      a {
        font-weight: 700;
        color: $c_text_100;
        opacity: 0.4;
        transition: 0.3s all;

        @media (max-width: 991px) {
          color: $c_text_80;
          font-weight: normal;
          opacity: 1;
        }
      }
    }

    .menu-item {
      position: relative;

      &:hover,
      &.current-menu-item,
      &.current-menu-parent {
        .mega-menu-parent .item-description-wrap > a,
        .mega-menu-parent ~ li a,
        & > a {
          opacity: 1;
        }
      }

      &:hover {
        .sub-menu-wrap {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    .mob-submenu-toggler {
      position: absolute;
      top: 5px;
      right: 0;
      border: 0;
      outline: none;
      background-color: transparent;
      transition: 0.3s all;

      &.active {
        transform: rotate(180deg);
      }

      @media (max-width: 767px) {
        right: 15px;
      }
    }

    .sub-menu-wrap {
      position: absolute;
      bottom: 0;
      padding-top: 31px;
      transform: translateY(100%);
      transition: 0.3s all;
      opacity: 0;
      visibility: hidden;
      z-index: 100;

      @media (max-width: 991px) {
        position: relative;
        opacity: 1;
        visibility: visible;
        transform: none;
        max-height: 0;
        overflow: hidden;
        padding-top: 0;

        &.opened {
          padding-top: 32px;
          max-height: max-content;
        }
      }
    }

    .sub-menu {
      min-width: 260px;
      background-color: $c_white;
      border: 1px solid rgba(31, 29, 28, 0.06);
      border-radius: 8px;
      padding: 24px 16px;
      backdrop-filter: blur(28px);

      @media (max-width: 991px) {
        padding: 0 20px;
        border: none;
        backdrop-filter: none;
      }

      .menu-item {
        position: relative;

        &.current-menu-item {
          &::before {
            opacity: 1;
            visibility: visible;
          }
        }

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: -17px;
          width: 1px;
          height: 18px;
          background-color: $c_orange;
          transform: translateY(-50%);
          transition: 0.3s all;
          opacity: 0;
          visibility: hidden;
        }

        &:not(:last-of-type) {
          margin-bottom: 24px;
        }
      }
    }
  }

  &__cta-wrapper {
    display: flex;

    @media (max-width: 991px) {
      max-width: 960px;
      margin: 0 auto;
      padding: 0 15px;
    }
  }

  &__cta {
    margin-left: 60px;

    @media (max-width: 991px) {
      background: none;
      padding: 0;
      color: $c_orange;
      font-size: 36px;
      margin-left: 0;
      margin-top: 48px;
      margin-bottom: 110px;
    }

    @media (max-width: 767px) {
      font-size: 24px;
      margin-top: 32px;
      margin-bottom: 50px;
    }
  }
  .help-ua{
    position: relative;
    text-align: center;
    z-index: 10;
    display: none;
    @media (max-width: 768px) {
      padding: 15px 0;
    }
    &.active{
      display: block;
    }
    a{
      color: $c_orange;
      text-decoration: underline;
    }
    p{
      font-family: 'Mont';
      font-style: normal;
      font-size: 20px;
      line-height: 1.3;
      color: $c_text_100;
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
    img{
      margin-right: 15px;
      @media (max-width: 768px) {
        display: none;
      }
    }
    >.container{
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 1400px) {
        padding: 0 50px;
      }
      @media (max-width: 768px) {
        flex-direction: column-reverse;
      }
    }
    .close-btn{
      position: absolute;
      width: 22px;
      height: 22px;
      transition: 0.3s all;
      top: 50%;
      transform: translate(0,-50%);
      right: 60px;
      z-index: 11;
      cursor: pointer;
      background-image: url('../img/close.png');
      @media (max-width: 1200px) {
        right: 20px;
      }
      @media (max-width: 768px) {
        right: 15px;
        top: 15px;
        transform: translate(0,0);
      }
      &:hover{
        opacity: 0.7;
      }
    }
  }
}
.single-post .current_page_parent{
  @extend .current-menu-item;
}
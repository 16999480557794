#usm-admin-notification-widget{
  display: none!important;
  visibility: hidden;
  opacity: 0;
}
.o-single {
  padding: 90px 0 $space_desktop;
  @media (max-width: 991px) {
    padding: 90px 0 $space_tablet;
  }
  @media (max-width: 767px) {
    padding: 90px 0 $space_mobile;
  }

  .breadcrumbs {
    margin-bottom: 44px;
  }
  .post-categories{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
    margin-bottom: 30px;
    @media (max-width: 480px) {
      column-gap: 15px;
      row-gap: 15px;
    }
    .post-category{
      background: $c_yellow_medium;
      border: 1px solid $c_yellow_medium;
      border-radius: 20px;
      padding: 0 20px;
      transition: all 0.4s ease;

      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 40px;
      text-align: center;
      color: #FFFFFF;
      white-space: nowrap;
      @media (max-width: 480px) {
        font-size: 14px;
        line-height: 32px;
      }
      &:hover{
        background: #fff;
        color: $c_yellow_medium;
      }
    }
  }
  &__title {
    margin-bottom: 24px;
  }
  .top-box{
    position: relative;
  }
  .share-box{
    flex-basis: 100%;
    text-align: center;
    >span{
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      color: #676875;
    }
    .share-wrap{
      width: 50px;
      position: absolute;
      top: 15px;
      left: -70px;
    }
    .sfsi_widget{
      float: none!important;
    }
    .norm_row{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50px!important;
    }
    .shuffeldiv{
      width: 30px!important;
      height: 30px!important;
      margin: 0!important;
      padding: 0!important;
    }
    .sficn{
      width: 30px!important;
      height: 30px!important;
    }
  }
  .sidebar-single-info{
    margin-bottom: 50px;
    img {
      min-width: 50px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;
      border: 1px solid #DFDFDF;
      margin-bottom: 12px;
      display: block;
    }
    .user-position{
      display: block;
      margin-bottom: 4px;
    }
    .user-linkedin{
      text-decoration: underline;
      color: $c_yellow_medium;
    }
    .co-author-block{
      margin-bottom: 20px;
      a{
        color: $c_yellow_medium;
        display: block;
      }
    }
  }
  &__info {
    display: flex;
    align-items: center;
    margin-bottom: 36px;
    img {
      min-width: 72px;
      width: 72px;
      height: 72px;
      border-radius: 50%;
      overflow: hidden;
      border: 1px solid #DFDFDF;
      margin-right: 24px;
      object-fit: cover;
    }
  }
  &__info-author-name {
    color: $c_text_100;
    line-height: 1;
    margin-bottom: 4px;
    font-weight: 700;
    display: block;
    a{
      color: $c_text_100;
      line-height: 1;
      font-weight: 700;
      transition: all 0.4s ease;
      &:hover{
        color: $c_yellow_medium;
      }
    }
  }
  &__info-date {
    line-height: 1;
    display: block;
    margin-bottom: 48px;
    font-size: 14px;
  }
  .post-video-link{
    position: relative;
    display: block;
    max-width: 600px;
    margin: 0 auto;
    svg{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 0;
      @media screen and (max-width: 767px) {
        width: 56px;
        height: 56px;
      }
      path{
        transition: all 0.4s ease;
      }
    }
    &:hover{
      svg{
        path{
          fill: $c_yellow_medium;
        }
      }
    }
  }
  &__featured-image{
    border-radius: 12px;
    margin-bottom: 27px;
    overflow: hidden;
    border: 1px solid #f0f0f0;
  }
  &__main {
    margin-bottom: 140px;

    @media (max-width: 991px) {
      margin-bottom: 80px;
    }
  }
  &__content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &:not(:first-of-type) {
        margin-top: 48px;
      }
      margin-bottom: 24px;
    }

    p {
      margin-bottom: 24px;
    }

    a {
      color: $c_yellow_medium;
      text-decoration: underline;

      &:hover {
        color: $c_orange;
        text-decoration: underline;
      }
    }

    img {
      height: auto;
    }
    img.aligncenter{
      display: block;
      margin: 0 auto;
    }
    img.alignleft{
      float: left;
      margin: 0 10px 10px 0;
    }
    img.alignright{
      float: right;
      margin: 0 0 10px 10px;
    }
    table.table{
      border: 1px solid #f0f0f0;
      border-collapse: collapse;
      @media (max-width: 767px) {
        font-size: 14px;
      }
      th{
        border: 1px solid #f0f0f0;
        padding: 0.3rem;
      }
      td{
        border: 1px solid #f0f0f0;
        padding: 0.3rem;
      }
    }
    figure {
      margin-top: 48px;
      margin-bottom: 48px;
    }
    iframe:not(.facebook){
      margin: 0 auto;
      display: block;
      max-width: 100%;
    }
    iframe.facebook{
      @media screen and (max-width: 480px) {
        width: 320px;
        height: 320px;
      }
    }
    .wp-block-quote {
      font-style: italic;
      padding-left: 24px;
      border-left: 3px solid $c_yellow_medium;
    }
    ul{
      li{
        position: relative;
        padding-left: 15px;
        margin-bottom: 10px;
        &:before{
          content: '';
          position: absolute;
          width: 6px;
          height: 6px;
          background: $c_yellow_medium;
          border-radius: 50%;
          top: 9px;
          left: 0;
        }
      }
    }
    ol{
      padding-left: 20px;
    }

    .wp-block-code {
      padding: 24px 32px;
      color: #676875;
      background-color: #F8F8F8;
      border: 1px solid rgba(54, 54, 69, 0.1);
      border-radius: 12px;
      margin-top: 48px;
      margin-bottom: 48px;
    }
  }
  &__tags {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .emoji-reaction-rating {
    font-family: 'Mont', sans-serif;
  }

  .emoji-reaction-rating-value {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .emoji-reaction-rating-average {
    .active {
      color: $c_yellow_medium;
    }
  }

  .emoji-reaction-rating-text {
    display: none;
  }

  .emoji-reaction-rating-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 10px;
    padding-right: 220px;
    @media (max-width: 768px) {
      padding-right: 0;
    }
    li {
      height: 60px;
      width: 130px;
      background: #FFF6D9 !important;
      border: 1px solid #FFE22A;
      border-radius: 40px;
      margin: 0;

      &.active {
        background: #FFE22A !important;
      }

      img {
        margin-right: 0;
      }

      .title {
        display: none;
      }
    }

    [title="hidden"] {
      display: none;
    }
  }
  .o-single__share-bottom{
    position: relative;
    .share-wrapper{
      display: flex;
      align-items: center;
      column-gap: 30px;
      @media (max-width: 768px) {
        left: 0;
        right: auto;
        bottom: -175px;
      }
      a{
        margin: 0 20px 0 0!important;
        &:last-child{
          margin: 0!important;
        }
      }
    }
  }
  .post-sidebar-wrapper{
    position: sticky;
    top: 100px;
    margin-bottom: 30px;
    @media screen and (max-width: 991px) {
      position: static;
    }
  }
  &__nav {
    margin-bottom: 30px;
    ul {
      position: relative;
      padding-left: 24px;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 100%;
        background-color: #F0F0F0;
      }
    }

    li {
      &:not(:last-of-type) {
        margin-bottom: 24px;
      }
    }

    a {
      position: relative;
      font-weight: 400;
      color: rgba($c_text_80, 0.4);
      transition: all 0.4s ease;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -24px;
        width: 1px;
        height: 100%;
        background-color: $c_yellow_medium;
        opacity: 0;
        visibility: hidden;
      }

      &:hover,
      &.current {
        color: $c_text_80;
      }

      &.current {
        &::before {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    .highlighted {
      padding-left: 24px;
      margin-top: 24px;
      color: $c_yellow_medium;
      font-weight: 700;
      &:hover {
        color: $c_yellow_medium;
      }
    }
  }
  &__nav-title {
    margin-bottom: 24px;
  }
  &__related {
    margin-bottom: 24px;
  }
  &__share-bottom{
    padding-left: 24px;
    margin-bottom: 30px;
  }
}

.post-template-single-post-video{
  .o-single__info-date{
    margin-bottom: 15px;
  }
  .related-materials{
    h2{
      margin-bottom: 30px;
    }
    .related-materials-wrap{
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      .related-materials-item{
        border-radius: 20px;
        border: 1px solid rgba(0, 0, 0, 0.10);
        padding: 30px;
        flex-basis: 31.6%;
        @media screen and (max-width: 1200px) {
          flex-basis: calc(50% - 15px);
        }
        @media screen and (max-width: 767px) {
          flex-basis: 100%;
        }
        >a{
          font-size: 20px;
          font-weight: 700;
          line-height: 140%;
          margin-bottom: 12px;
          color: $c_text_100;
        }
        .o-single__info{
          margin-bottom: 0;
          .o-single__info-date{
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
.logged-in.admin-bar{
  .o-single{
    padding: $space_desktop 0;
    @media (max-width: 991px) {
      padding: $space_tablet 0;
    }
    @media (max-width: 767px) {
      padding: $space_mobile 0;
    }
  }
}
.content-block {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:not(:first-of-type) {
      margin-top: 48px;
    }
    margin-bottom: 24px;
  }

  p {
    margin-bottom: 24px;
    &:last-child{
      margin-bottom: 0;
    }
  }

  a {
    color: $c_yellow_medium;
    text-decoration: underline;

    &:hover {
      color: $c_orange;
      text-decoration: underline;
    }
  }

  img {
    height: auto;
  }
  img.aligncenter{
    display: block;
    margin: 0 auto;
  }
  img.alignleft{
    float: left;
    margin: 0 10px 10px 0;
  }
  img.alignright{
    float: right;
    margin: 0 0 10px 10px;
  }
  table.table{
    border: 1px solid #f0f0f0;
    border-collapse: collapse;
    @media (max-width: 767px) {
      font-size: 14px;
    }
    th{
      border: 1px solid #f0f0f0;
      padding: 0.3rem;
    }
    td{
      border: 1px solid #f0f0f0;
      padding: 0.3rem;
    }
  }
  figure {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  iframe:not(.facebook){
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
  iframe.facebook{
    @media screen and (max-width: 480px) {
      width: 320px;
      height: 320px;
    }
  }
  .wp-block-quote {
    font-style: italic;
    padding-left: 24px;
    border-left: 3px solid $c_yellow_medium;
  }
  ul{
    li{
      position: relative;
      padding-left: 15px;
      margin-bottom: 10px;
      &:before{
        content: '';
        position: absolute;
        width: 6px;
        height: 6px;
        background: $c_yellow_medium;
        border-radius: 50%;
        top: 9px;
        left: 0;
      }
    }
  }
  ol{
    padding-left: 20px;
  }

  .wp-block-code {
    padding: 24px 32px;
    color: #676875;
    background-color: #F8F8F8;
    border: 1px solid rgba(54, 54, 69, 0.1);
    border-radius: 12px;
    margin-top: 48px;
    margin-bottom: 48px;
  }
}
body {
  overflow-x: hidden;
}
html {
  scroll-behavior: smooth;
}
body.no-scroll{
  overflow: hidden;
}
img,
input,
textarea {
  max-width: 100%;
  height: auto;
}

a {
  display: inline-block;
  text-decoration: none;
}

.container {
  @media (min-width: 1200px) {
    max-width: 1200px !important;
  }
}
.container-1920 {
  width: 1920px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}
.container-1280 {
  width: 1280px;
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin: 0 auto;
  position: relative;
}

.o-preloader {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  z-index: 999;
}

.o-divider {
  border-bottom: 1px solid #F0F0F0;
}

.o-mark-inside {
  mark {
    background-color: transparent;
    color: $c_orange;
  }
}
.gray{
  background: #fbfbfb;
  padding: $space_desktop 0;
  margin: 0!important;
  @media screen and (max-width: 991px) {
    padding: $space_tablet 0;
  }
  @media screen and (max-width: 767px) {
    padding: $space_mobile 0;
  }
}
.section-bottom-space{
  margin-bottom: $space_desktop!important;
  @media screen and (max-width: 991px) {
    margin-bottom: $space_tablet!important;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: $space_mobile!important;
  }
}

#cookie-notice {
  font-family: 'Noto Sans' !important;
  max-width: 1280px;
  min-width: unset !important;
  width: 100%;
  left: 50% !important;
  backdrop-filter: blur(2px);
  border: 1px solid #F5F5F5;
  border-radius: 8px 8px 0 0;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;

  &.cookie-notice-hidden {
    display: none;
  }

  .cookie-notice-container {
    padding: 20px 45px;
    display: flex !important;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .cn-text-container {
    position: relative;
    max-width: 880px;
    text-align: left;
    padding-left: 110px;
    margin-bottom: 0;

    @media (max-width: 767px) {
      padding-left: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 55%;
      left: 0;
      width: 100px;
      height: 100px;
      background-image: url('../img/cookie-img.png');
      background-size: cover;
      background-position: center;
      transform: translateY(-50%);

      @media (max-width: 767px) {
        display: none;
      }
    }

    h3 {
      margin-bottom: 12px;
    }

    p {
      font-size: 14px !important;
    }

    a {
      font-weight: 600;
      color: $c_orange;
      border-bottom: 1px solid $c_orange;
      transition: 0.3s all;

      &:hover {
        color: $c_yellow_medium;
        border-color: $c_yellow_medium;
      }
    }
  }

  .cn-close-icon {
    display: none;
  }

  .o-btn--primary {
    font-size: 16px !important;
    font-weight: 700 !important;
    color: $c_white !important;
    background-color: $c_yellow_medium !important;
    border-radius: 12px !important;
    padding: 10px 24px !important;
    margin: 0 !important;
    transition: 0.3s all !important;
    white-space: nowrap;
    &:hover {
      color: $c_white !important;
      background-color: $c_orange !important;
    }

    @media (max-width: 767px) {
      margin-top: 10px !important;
    }
  }
}
.subscribe-popup{
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 12;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background-color: rgba(0,0,0,0.5);
  padding: 30px 0;
  &.active{
    display: flex;
  }
  .popup-close-button{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    svg{
      width: 100%;
      height: 100%;
    }
  }
  .popup-content{
    &.white{
      border-radius: 25px;
      overflow: hidden;
      background: #fff;
      padding: 35px 20px;
      text-align: center;
      max-width: 500px;
      margin: 0 auto;
      h3{
        margin-bottom: 20px;
      }
      p{
        margin-bottom: 10px;
      }
    }
  }
}
//Template Name: LP : Links List
.page-template-lp-links{
  &.admin-bar{
    .o-lp-links{
      padding-top: 100px;
      @media screen and (max-width: 991px) {
        padding-top: 80px;
      }
    }
  }
}
.o-lp-links{
  padding-top: 140px;
  margin-bottom: $space_desktop;
  @media screen and (max-width: 991px) {
    padding-top: 120px;
    margin-bottom: 60px;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: $space_mobile;
  }
  h1{
    font-size: 52px;
    line-height: 140%;
    margin-bottom: 84px;
    text-align: center;
    @media screen and (max-width: 991px) {
      font-size: 42px;
      margin-bottom: 30px;
    }
    @media screen and (max-width: 767px) {
      font-size: 36px;
    }
  }
  .links{
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;
    @media screen and (max-width: 991px) {
      gap: 30px;
    }
    @media screen and (max-width: 767px) {
      gap: 20px;
    }
  }
}
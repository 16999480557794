.blog-breadcrumbs{
  .breadcrumbs{
    margin-top: 90px;
  }
}
.o-archive {
  padding: $space_desktop 0;
  @media screen and (max-width: 991px) {
    padding: $space_tablet 0;
  }
  @media (max-width: 767px) {
    padding: $space_mobile;
  }
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $space_desktop;
    max-width: 100%;
    @media (max-width: 991px) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: $space_tablet;
    }
    @media (max-width: 767px) {
      margin-bottom: $space_mobile;
    }
    .oril-search-wrap{
      flex-basis: 48.3%;
      min-height: 60px;
      position: relative;
      @media (max-width: 991px) {
        width: 100%;
      }
      //@media (max-width: 479px) {
      //  margin: 0 15px;
      //}
      .oril-search{
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 10;
        background: #FFFFFF;
        box-shadow: 0px 4px 40px rgba(202, 205, 210, 0.4), 4px 4px 12px rgba(208, 218, 226, 0.2), 0px 4px 8px rgba(193, 196, 213, 0.4);
        border: none;
        border-radius: 12px;
        overflow: hidden;
        button{
          position: absolute;
          top: 0;
          left: 0;
          height: 60px;
          width: 60px;
          background-image: url(../img/search-icon-orange.svg);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: auto;
          background-color: transparent;
          border: none;
          cursor: auto;
        }
        input{
          border: none;
          height: 60px;
          padding: 0 28px 0 70px;
          width: 100%;
          box-sizing: border-box;
        }
        .search-list{
          padding: 8px 0;
          position: relative;
          &:before{
            content: '';
            position: absolute;
            left: 26px;
            right: 26px;
            top: 0;
            height: 1px;
            background: #F0F0F0;
          }
          li{
            padding: 9px 26px;
            display: flex;
            align-items: center;
            a{
              font-family: 'Mont';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 1;
              color: #000000;
              transition: all 0.4s ease;
              &:hover{
                color: $c_orange;
              }
            }
            &:before{
              content: url(../img/search-icon.svg);
              margin-right: 20px;
              width: 21px;
              height: 21px;
            }
          }
        }
      }
    }
  }
  &__action {
    display: flex;
    align-items: center;
    padding: 0 15px;

    @media (max-width: 991px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__description {
    max-width: 280px;
    @media (max-width: 991px) {
      margin-bottom: 24px;
    }
  }
  &__button {
    margin-left: 24px;

    &::after {
      transform: translateX(16px) rotate(90deg);
    }

    &:hover {
      &::after {
        transform: translateX(32px) rotate(90deg);
      }
    }

    @media (max-width: 991px) {
      margin-left: 0;
    }
  }
  &__main {
    margin-bottom: $space_desktop;
  }
  &__main-image {
    position: relative;
    padding-bottom: 59.7%;
    background-size: cover;
    background-position: center;
    border-radius: 12px;
    overflow: hidden;

    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      //background-color: rgba($c_text_100, 0.05);
    }

    @media (max-width: 991px) {
      margin-bottom: 48px;
    }

    a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  &__main-title {
    margin-bottom: 24px;

    a {
      color: $c_text_100;
      font-weight: 700;

      &:hover {
        color: $c_text_100;
      }
    }
  }
  &__main-excerpt {
    margin-bottom: 24px;
  }
  &__main-divider {
    margin-top: $space_desktop;

    @media (max-width: 991px) {
      margin-top: $space_tablet;
    }

    @media (max-width: 767px) {
      margin-top: $space_mobile;
    }
  }
  &__posts-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 -15px 24px;
  }
  &__posts-title {
    padding: 0 15px;
  }
  &__posts-link {
    padding: 0 15px;
    color: $c_orange;
    text-decoration: underline;
    transition: 0.3s all;

    &:hover {
      color: $c_yellow_medium;
      text-decoration: underline;
    }
  }
  &__posts-divider {
    margin-top: 44px;
    margin-bottom: 80px;
  }
  &__subscribe {
    position: relative;
    padding: 38px;
    background-color: #F8F8F8;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: $space_desktop;
    @media screen and (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    @media (max-width: 767px) {
      margin-bottom: $space_mobile;
      padding: 24px;
    }

    & > img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      max-width: 210px;

      @media (max-width: 991px) {
        max-width: 140px;
      }

      @media (max-width: 767px) {
        display: none;
      }
    }
  }
  &__subscribe-inner {
    padding: 0 50px;

    @media (max-width: 767px) {
      padding: 0;
    }
  }
  &__subscribe-title {
    text-align: center;
    margin: 0 auto;
    margin-bottom: 24px;
    max-width: 530px;
  }
  &__subscribe-form {
    .tnp label{
      color: $c_text_80!important;
    }
    .wpcf7-response-output{
      color: $c_text_80!important;
    }
    .mailpoetsignup-671{
      display: none;
    }
  }
  &__subscribe-info {
    p {
      color: $c_text_80;
    }
  }
  &__posts-row {
    &:last-of-type {
      .o-archive__posts-divider {
        display: none;
      }
    }
  }

  .categories-buttons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $space_desktop;
    @media screen and (max-width: 768px) {
      margin-bottom: $space_mobile;
    }
    .main-categories{
      display: flex;
      column-gap: 20px;
      flex-wrap: wrap;
      row-gap: 20px;
      @media (max-width: 768px) {
        column-gap: 20px;

      }
      span{
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 1;
        color: #363645;
        opacity: 0.4;
        cursor: pointer;
        transition: all 0.4s ease;
        padding-bottom: 12px;
        border-bottom: 3px solid transparent;
        &:hover,
        &.active{
          color: #363645;
          opacity: 1;
          border-color: $c_orange;
        }
        @media (max-width: 480px) {
          font-size: 20px;
        }
      }
    }
    .sub-categories{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 20px;
      row-gap: 20px;
      transition: all 0.4s ease;
      margin-bottom: 0;
      &.active{
        margin-bottom: 50px;
      }
      .sub-category{
        display: none;
        background: #fff;
        border: 1px solid $c_orange;
        border-radius: 20px;
        padding: 0 20px;
        transition: all 0.4s ease;
        cursor: pointer;

        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 40px;
        text-align: center;
        color: $c_orange;
        white-space: nowrap;
        @media (max-width: 480px) {
          font-size: 14px;
          line-height: 32px;
        }
        &.active,
        &:hover{
          background: $c_orange;
          color: #FFFFFF;
        }
        &.visible{
          display: block;
        }
      }
    }
    .selectric-wrapper{
      .selectric{
        border: 1px solid rgba(54,54,69,.1);
        border-radius: 12px;
        min-width: 200px;
        background: #fff;
        .label{
          font-size: 18px;
          color: #363645;
          font-weight: 600;
        }
        .button{
          background-color: #fff;
          &:after{
            border-top-color: $c_orange;
          }
        }
      }
      .selectric-items{
        background: #fff;
        li{
          color: $c_text_100;
          font-size: 14px;
          &:hover,
          &.highlighted{
            background: #fbfbfb;
          }
        }
      }
    }
  }
  .category-item{
    display: none;
    &.active{
      display: block;
    }
    &.loading{
      .loader{
        display: block;
      }
    }
    .blog-posts-button-wrap{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
    }
    .loader{
      display: none;
      position: absolute;
      bottom: -40px;
      left: calc(50% - 30px);
    }
    @keyframes blink { 50% { fill: transparent }}
    .dot {
      animation: 1s blink infinite;
      fill: #FFB248;
    }
    .dot:nth-child(2) { animation-delay: 250ms }
    .dot:nth-child(3) { animation-delay: 500ms }
    .dot:nth-child(4) { animation-delay: 750ms }
    .dot:nth-child(5) { animation-delay: 1000ms }

    .loader {
      background-color: transparent;
      color: grey;
    }
    .post-item.hidden{
      display: none;
    }
  }
  .all-articles-title{
    margin-bottom: 44px;
    @media (max-width: 767px) {
      margin-bottom: 30px;
      font-size: 24px;
    }
  }
  .top-articles{
    margin-bottom: $space_desktop;
    @media (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    @media (max-width: 767px) {
      margin-bottom:$space_mobile;
    }
    h2{
      margin-bottom: $title_s_desktop;
      @media (max-width: 767px) {
        font-size: 24px;
      }
    }
    .top-articles-swiper-wrap{
      position: relative;
      &:after{
        content:'';
        position:absolute;
        top:0;
        right:-1px;
        bottom: 0;
        width: 150px;
        background: linear-gradient(268.57deg, #FFFFFF 1.43%, rgba(255, 255, 255, 0) 101.28%);
        z-index: 1;
      }
      .top-article-image{
        border-radius: 16px;
        overflow: hidden;
        margin-bottom: 12px;
        border: 1px solid #f0f0f0;
        height: 285px;
        width: 100%;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        @media screen and (max-width: 991px) {
          height: 200px;
        }
      }
      .top-article-title{
        font-weight: 700;
        font-size: 20px;
        line-height: 140%;
        color: $c_text_100;
        font-family: 'Mont';
        @media screen and (max-width: 767px) {
          font-size: 18px;
        }
      }
      .swiper-button-next,
      .swiper-button-prev{
        background-image: none!important;
        &.swiper-button-disabled{
          display: none;
        }
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
      .swiper-button-next{
        right: -32.5px;
      }
      .swiper-button-prev{
        left: -32.5px;
        svg{
          transform: rotate(180deg);
        }
      }
    }
  }
}
.o-process {
  margin-bottom: $space_desktop;

  @media (max-width: 991px) {
    margin-bottom: $space_tablet;
  }

  @media (max-width: 767px) {
    margin-bottom: $space_mobile;
  }

  &__title {
    text-align: center;
    margin-bottom: $title_s_desktop;
  }
  &__description {
    margin-bottom: $title_s_desktop;
  }
  &__list {
    position: relative;
  }
  &__item {
    text-align: center;
    max-width: 670px;
    margin: 0 auto;

    @media (max-width: 991px) {
      max-width: 530px;
      text-align: left;
    }

    @media (max-width: 767px) {
      padding: 0 40px;
    }
  }

  &__item-title {
    margin-bottom: 24px;

    @media (min-width: 992px) {
      display: none;
    }

    mark {
      background-color: transparent;
      color: $c_orange;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: unset;
    height: unset;
    margin-top: unset;
    padding: 0 12px;
    transform: translateY(-50%);
    background-image: none;

    @media (max-width: 767px) {
      padding: 0;
    }

    &::after {
      display: none;
    }

    &:not(.swiper-button-disabled) {
      svg {
        fill: $c_orange;
      }

      path {
        fill: $c_white;
      }
    }

    &.swiper-button-disabled {
      opacity: 1;
    }

    svg {
      @media (max-width: 991px) {
        width: 40px;
      }

      @media (max-width: 767px) {
        width: 32px;
      }
    }
  }

  .swiper-button-next {
    @media (max-width: 767px) {
      right: 0;
    }

    svg {
      transform: rotate(180deg);
    }
  }

  .swiper-button-prev {
    @media (max-width: 767px) {
      left: 0;
    }
  }

  .swiper-pagination {
    position: relative;
    bottom: auto;
    display: flex;
    justify-content: space-between;
    padding-top: 114px;
    margin-bottom: 60px;

    @media (max-width: 991px) {
      display: none;
    }

    &::before {
      content: '';
      position: absolute;
      top: 78px;
      left: 96px;
      right: 120px;
      height: 2px;
      background-color: $c_yellow_light;
    }

    &::after {
      content: '';
      position: absolute;
      top: 78px;
      right: calc(100% - 91px);
      height: 2px;
      width: 5000px;
      background-color: $c_white;
    }
  }

  .swiper-pagination-bullet {
    position: relative;
    font-size: 24px;
    font-weight: 700;
    color: $c_text_100;
    width: auto;
    height: auto;
    max-width: 250px;
    background: transparent;
    border-radius: unset;
    opacity: 1;
    transition: 0.3s all;

    &::before {
      content: attr(data-index);
      position: absolute;
      top: -80px;
      left: 50%;
      font-size: 60px;
      font-weight: 700;
      color: $c_text_100;
      opacity: 0.06;
      transform: translateX(-50%);
    }

    &::after {
      content: '';
      position: absolute;
      top: -40px;
      left: 50%;
      width: 10px;
      height: 10px;
      background-color: $c_orange;
      transform: translateX(-50%);
      z-index: 2;
    }

    mark {
      background-color: transparent;
      color: $c_orange;
    }

    .process-progress-bar {
      display: none;
    }

    &.swiper-pagination-bullet-active {
      color: transparent;

      & > mark {
        color: transparent;
      }

      .fake-bullet {
        opacity: 1;
        visibility: visible;
      }

      .process-progress-bar {
        display: block;
      }
    }
  }

  .fake-bullet {
    position: absolute;
    top: -76px;
    left: 0;
    font-size: 24px;
    font-weight: 700;
    color: $c_text_100;
    width: auto;
    height: auto;
    max-width: 250px;
    background: transparent;
    border-radius: unset;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-100%);
    transition: 0.3s all;
    z-index: 1;

    &::before {
      content: attr(data-index);
      position: absolute;
      bottom: -86px;
      left: 50%;
      font-size: 60px;
      font-weight: 700;
      color: $c_yellow_light;
      opacity: 1;
      transform: translateX(-50%);
    }

    mark {
      background-color: transparent;
      color: $c_orange;
    }
  }

  .process-progress-bar {
    position: absolute;
    right: 0;
    top: -36px;
    width: 100%;
    height: 2px;
    transform: translateX(-50%);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 4000px;
      background-color: $c_orange;
    }
  }
}
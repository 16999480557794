.o-a-careers {
  .breadcrumbs{
    margin-top: 90px;
  }
  .o-banner {
    margin-bottom: 5.2vw;
    @media (max-width: 1199px) {
      margin-bottom: 0;
    }
  }
  .banner-section {
    padding: $space_desktop 0;
    margin-bottom: 0;
    @media screen and (max-width: 991px) {
      padding: $space_tablet 0;
    }
    @media screen and (max-width: 767px) {
      padding: $space_mobile 0;
    }
    .alc{
      @media (max-width: 991px) {
        flex-direction: column;
      }
    }
    .banner-content{
      max-width: 570px;
    }
    .banner-image-wrap{
      @media (max-width: 991px) {
        margin-bottom: 20px;
      }
    }
  }

  &__about {
    margin-bottom: $space_desktop;

    @media (max-width: 1199px) {
      margin-bottom: $space_tablet;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: $space_mobile;
    }
  }
  &__about-title {
    text-align: center;
    margin-bottom: $title_s_desktop;
    @media (max-width: 767px) {
      text-align: left;
    }
  }
  &__about-description {
    text-align: center;
    margin-bottom: $space_desktop;

    @media (max-width: 767px) {
      text-align: left;
      margin-bottom: $space_mobile;
    }
  }
  &__about-item {
    align-items: center;
    margin-bottom: 30px;

    @media (max-width: 767px) {
      margin-bottom: 60px;
    }

    &:nth-of-type(2n-1) {
      .o-a-careers__about-item-image {
        @media (min-width: 1366px) {
          transform: scale(1.05) translateX(10%);
        }
      }
    }

    &:nth-of-type(2n) {
      flex-direction: row-reverse;

      .o-a-careers__about-item-image {
        @media (min-width: 1366px) {
          transform: scale(1.05) translateX(-10%);
        }
      }
    }
  }
  &__about-item-title {
    margin-bottom: 24px;
  }
  &__about-item-description {
    @media (max-width: 767px) {
      margin-bottom: 40px;
    }
  }
  &__benefits-title {
    text-align: center;
    margin-bottom: $title_s_desktop;
  }
  &__benefits-item {
    text-align: center;
    margin-bottom: $space_desktop;

    @media (max-width: 1199px) {
      margin-bottom: $space_tablet;
    }
  }
  &__benefits-item-image {
    max-width: 200px;
    margin-bottom: 24px;
  }
  &__benefits-item-title {
    margin-bottom: 16px;
  }
  &__benefits-item-description {}

  .o-banner {
    @media (max-width: 1199px) {
      padding-bottom: $space_desktop;
    }

    &__illustration {
      @media (max-width: 991px) {
        margin-bottom: $space_tablet;
      }
      @media screen and (max-width: 767px) {
        margin-bottom: $space_mobile;
      }
    }
  }
  .it-cluster-section{
    margin-bottom: $space_desktop;
    @media screen and (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: $space_mobile;
    }
    .it-cluster-box{
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 30px 40px;
      @media screen and (max-width: 479px) {
        padding: 25px;
      }
      .cluster-text{
        font-size: 20px;
        line-height: 140%;
        color: $c_text_100;
        flex-basis: 66%;
        @media screen and (max-width: 767px) {
          flex-basis: 60%;
          font-size: 20px;
        }
        a{
          color: $c_orange;
        }
      }
      .cluster-icon-link{
        filter: grayscale(100%);
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        transition: all 0.4s ease;
        max-width: 100px;
        &:hover{
          filter: grayscale(0%);
          -webkit-filter: grayscale(0%);
          -moz-filter: grayscale(0%);
        }
      }
    }
  }
  //NEW
  .background-left-item{
    &:last-child{
      flex-basis: 100%;
    }
  }
  .transform-form{
    form .saleforces-form-top p{
      flex-wrap: wrap;
      .wpcf7-form-control-wrap{
        flex-basis: calc(50% - 12px);
      }
    }
    .wpcf7-form-control-wrap[data-name=email-156]{
      margin-bottom: 0;
    }
    .wpcf7-form-control-wrap[data-name="text-962"]{
      margin-bottom: 24px;
      display: block;
    }
  }
  .socials-section{
    margin-bottom: $space_desktop;
    >.alc{
      gap: 60px;
    }
    .socials-content{
      flex: 1;
      h2{
        margin-bottom: 10px;
      }
      p{
        margin-bottom: 40px;
      }
      .networks-list{
        gap: 20px;
        justify-content: flex-start;
        .link-item{
          border-radius: 8px;
          border: 1px solid #ECECEC;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          padding: 8px 24px 8px 20px;

          color: $c_text_100;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 1;
          transition: all 0.4s ease;
          &:hover{
            border-color: $c_orange;
          }
        }
      }
    }
    .socials-image{
      border-radius: 15px;
      overflow: hidden;
      width: 170px;
      height: 173px;
      @media screen and (max-width: 991px) {
        display: none;
      }
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .team-gallery-section{
    margin-bottom: $space_desktop;
    @media screen and (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: $space_mobile;
      padding: 0 15px;
    }
    h2{
      text-align: center;
      margin-bottom: $title_s_desktop;
      @media screen and (max-width: 991px) {
        margin-bottom: $title_s_tablet;
      }
    }
    .team-gallery{
      .swiper-slide{
        height: 300px;
        border-radius: 12px;
        overflow: hidden;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: relative;
        }
      }
    }
  }
}
.team-feedback{
  background: #FBFBFB;
  padding: $space_desktop 0;
  @media screen and (max-width: 991px) {
    padding: $space_tablet 0;
  }
  @media screen and (max-width: 767px) {
    padding: $space_mobile 0;
  }
  h2{
    text-align: center;
    margin-bottom: $title_s_desktop;
    @media screen and (max-width: 991px) {
      margin-bottom: $title_s_tablet;
    }
  }
  .swiper-wrap.single-slide{
    padding: 0;
    .swiper-wrapper{
      justify-content: center;
    }
  }
  .swiper-wrapper{
    align-items: stretch;
  }
  .swiper-slide{
    display: flex;
    flex-direction: column;
    .team-box{
      margin-top: 75px;
      border-radius: 15px;
      background: #FFF;
      padding: 103px 35px 35px;
      position: relative;
      flex: 1;
      z-index: 0;
    }
    img{
      width: 150px;
      height: 150px;
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover;
      display: block;
      position: absolute;
      top: -75px;
      left: calc(50% - 75px);
    }
    svg{
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -1;
    }
    .name{
      color: $c_text_100;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      text-align: center;
      margin-bottom: 12px;
    }
    .info{
      color: #83838E;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      text-align: center;
      margin-bottom: 20px;
    }
  }
}
.team-feedback-centred{
  padding: $space_desktop 0;
  @media screen and (max-width: 991px) {
    padding: $space_tablet 0;
  }
  @media screen and (max-width: 767px) {
    padding: $space_mobile 0;
  }
  .swiper-wrap.single-slide{
    padding: 0;
    .swiper-wrapper{
      justify-content: center;
    }
  }
  .container{
    max-width: 1920px!important;
    padding: 0!important;
    @media screen and (max-width: 767px) {
      padding: 0 15px!important;
    }
  }
  h2{
    text-align: center;
    margin-bottom: $title_s_desktop;
    @media screen and (max-width: 991px) {
      margin-bottom: $title_s_tablet;
    }
  }
  .swiper-wrap.single-slide{
    .swiper-wrapper{
      justify-content: center;
    }
  }
  .swiper-wrapper{
    align-items: stretch;
  }
  .swiper-slide{
    display: flex;
    flex-direction: column;
    max-width: 830px;
    transition: all 0.4s ease;
    &:not(.swiper-slide-active){
      transform: scale(0.85);
    }
    .team-box{
      margin-top: 75px;
      border-radius: 15px;
      border: 1px solid #E5E5E5;
      background: #FBFBFB;
      padding: 103px 100px 60px;
      position: relative;
      flex: 1;
      z-index: 0;
    }
    img{
      width: 150px;
      height: 150px;
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover;
      display: block;
      position: absolute;
      top: -75px;
      left: calc(50% - 75px);
    }
    .name{
      color: $c_text_100;
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: 140%;
      text-align: center;
      margin-bottom: 12px;
    }
    .info{
      color: #83838E;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      text-align: center;
      margin-bottom: 32px;
    }
  }
  .swiper-wrap{
    padding: 0 0 80px;
  }
  .swiper-button-prev{
    top: auto;
    bottom: 0;
    left: calc(50% - 75px);
  }
  .swiper-button-next{
    top: auto;
    bottom: 0;
    right: calc(50% - 75px);
  }
}
.projects-swiper-nav{
  margin-top: 60px;
}

.career-subscribe{
  margin-bottom: $space_desktop;
  @media (max-width: 1199px) {
    margin-bottom: $space_tablet;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: $space_mobile;
  }
    h4{
      font-size: 20px;
      margin-bottom: 24px;
      text-align: center;
    }
  input{
    background: #FFFFFF;
    box-shadow: 0px 4px 40px rgba(202, 205, 210, 0.4), 4px 4px 12px rgba(208, 218, 226, 0.2), 0px 4px 8px rgba(193, 196, 213, 0.4);
    border-radius: 12px;
  }
}
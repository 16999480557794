.o-testimonials {
  margin-bottom: $space_desktop;

  @media (max-width: 991px) {
    margin-bottom: $space_tablet;
  }

  @media (max-width: 767px) {
    margin-bottom: $space_mobile;
  }

  &__title {
    text-align: center;
    margin-bottom: $title_s_desktop;
  }

  &__description {
    text-align: center;
    margin-bottom: $title_s_desktop;

  }

  &__item {
    padding: 0;
  }

  &__item-feedback {
    position: relative;
    font-size: 14px;
    color: $c_text_100;
    padding: 0 54px;
    margin-bottom: $title_s_desktop;

    @media (max-width: 767px) {
      font-size: 16px;
      color: $c_text_80;
      padding: 0;
      padding-left: 54px;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__item-client {
    display: flex;
    align-items: center;
  }

  &__item-client-photo {
    flex-basis: 90px;
    flex-shrink: 0;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-right: 24px;
    overflow: hidden;
  }

  &__item-client-info {}

  &__item-client-name {
    font-weight: 700;
    color: $c_text_100;
  }

  &__item-client-position {}

  &__control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 44px;

    @media (max-width: 991px) {
      justify-content: center;
      margin-top: 45px;
    }

    @media (max-width: 767px) {
      margin-top: 24px;
    }
  }

  &__control-pag {
    .swiper-pagination {
      position: relative;
      text-align: left;
      margin: 0 -7px;
      transform: none;
    }

    .swiper-pagination-bullet {
      width: 15px;
      height: 15px;
      margin: 0 7px;
      opacity: 1;
      background: #DDE0E4;
    }

    .swiper-pagination-bullet-active {
      background: $c_orange;
    }
  }

  &__control-nav {
    display: flex;
    flex-direction: row-reverse;
    margin: 0 -12px;

    @media (max-width: 991px) {
      display: none;
    }

    .swiper-button-next,
    .swiper-button-prev {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      width: unset;
      height: unset;
      margin-top: unset;
      padding: 0 12px;
      background-image: none;

      &::after {
        display: none;
      }

      svg,
      path {
        transition: 0.3s all;
      }

      &:hover {
        svg {
          fill: $c_orange;
        }

        path {
          fill: $c_white;
        }
      }

      &.swiper-button-disabled {
        opacity: 1;
      }
    }

    .swiper-button-next {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &__divider {
    padding-top: $space_desktop;
    @media (max-width: 991px) {
      padding-top: $space_tablet;
    }
  }
}
.technologies-block{
  padding: $space_desktop 0 6px;
  background: #fbfbfb;
  @media (max-width: 991px) {
    padding: $space_tablet 0 6px;
  }
  @media (max-width: 767px) {
    padding: $space_mobile 0 6px;
  }
  h2{
    margin-bottom: $title_s_desktop;
  }
  .technologies-category{
    flex-basis: 44%;
    margin-bottom: 20px;
    @media (max-width: 991px) {
      flex-basis: 100%;
    }
    h3{
      margin-bottom: 30px;
    }
    >.flex{
      justify-content: flex-start;
    }
  }
  .technology-item{
    flex-basis: 20%;
    text-align: center;
    margin-bottom: 24px;
    box-sizing: border-box;
    //padding: 0 9px;
    @media (max-width: 576px) {
      flex-basis: 33.33%;
    }
    .technology-icon{
      width: 50px;
      height: 50px;
      //border-radius: 50%;
      //background-color: $c_white;
      //box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 12px;
      img {
        max-height: 58px;
        width: auto;
      }
    }
    .technology-name{
      color: $c_text_100;
    }
  }
}
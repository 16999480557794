.o-info {
  margin-bottom: $space_desktop;
  @media (max-width: 991px) {
    margin-bottom: $space_tablet;
  }
  @media (max-width: 767px) {
    margin-bottom: $space_mobile;
  }

  &__title {
    text-align: center;
    margin-bottom: $title_s_desktop;

    @media (max-width: 767px) {
      text-align: left;
    }
  }

  &__description {
    text-align: center;
    padding: 0 40px;
    margin-bottom: $title_s_desktop;
    font-size: 14px;
    @media (max-width: 767px) {
      text-align: left;
      padding: 0;
    }
  }

  &__item{
    margin-bottom: 48px;
    .o-info__item-title{
      font-size: 20px;
    }
    &.dir-horizontal {
      display: flex;
      align-items: center;

      @media (max-width: 767px) {
        flex-direction: column;
      }
    }
    &:last-child{
      margin-bottom: $space_desktop;
      @media screen and (max-width: 767px) {
        margin-bottom: $space_tablet;
      }
    }
  }

  &__item-icon {
    max-width: 200px;

    &.pos-top {
      margin-bottom: 24px;
    }

    &.pos-left {
      margin-right: 24px;
    }
  }

  &__item-title {
    margin-bottom: 16px;
  }

  &__item-description {
    font-size: 14px;
  }
}
.o-about {
  margin-bottom: $space_desktop;
  &__title {
    margin-bottom: $title_s_desktop;
  }
  &__description {
    margin-bottom: $title_s_desktop;
  }
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: $space_desktop;
    @media screen and (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
  }
  &__item-icon {
    max-width: 200px;
    margin-right: 24px;

    @media (max-width: 575px) {
      margin-right: 0;
      margin-bottom: 24px;
    }
  }
  &__item-title {
    margin-bottom: 16px;
  }
  &__item-description {}
}